import React, { useState } from "react";
import Navbar from "../Components/Common/Navbar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import mail from "../assets/Icons/mail.svg";
import location from "../assets//Locations 1.jpg";
import phone from "../assets/Icons/phone 1.svg";
import locationIcon from "../assets/Icons/location.svg";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "../Utils/Mutations";
import { useSnackbar } from "notistack";
import Loader from "../Components/Common/Loader";
import newImage from "../assets/new.png";

const Contact = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [sendContact, { loading }] = useMutation(CONTACT_US);

  const handleSendContact = () => {
    sendContact({
      variables: {
        fullName,
        email,
        message,
      },
    })
      .then((res) => {
        setFullName("");
        setEmail("");
        setMessage("");
        handleAlert(`Your message has been sent successfully`, "success");
      })
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {loading && <Loader />}
      <Navbar />

      {isMobile && (
        <>
          <Box
            sx={{
              height: "200px",
              background: `url('${newImage}')`,
              backgroundPosition: "left",
              backgroundSize: "cover",
              mt: 1.5,
            }}
          />
        </>
      )}
      <Box
        sx={{
          margin: "0 auto",
          width: { lg: "85%", md: "85%", sm: "95%", xs: "95%" },
          mt: { lg: 17, md: 17, sm: 5, xs: 5 },
          backgroundImage: `url('${location}')`,
          mb: 5,
        }}
      >
        <Grid
          container
          sx={{ p: { lg: 3, md: 3, sm: 2, xs: 0 } }}
          columnSpacing={5}
        >
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "#fff",
                backgroundSize: "cover",
                backgroundPosition: "center",
                p: 2,
                borderRadius: 0.5,
              }}
            >
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontWeight: 500,
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                }}
              >
                Full Name
              </Typography>
              <TextField
                fullWidth
                sx={{ mb: 2 }}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                InputProps={{
                  style:{
                        borderRadius:'8px',
                    fontWeight:500,
                    fontSize:'13px'
                  }
                }}
              />
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontWeight: 500,
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                }}
              >
                Email Address
              </Typography>
              <TextField
                fullWidth
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                  style:{
                       borderRadius:'8px',
                    fontWeight:500,
                    fontSize:'13px'
                  }
                }}
              />
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontWeight: 500,
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                }}
              >
                Message
              </Typography>
              <TextField
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                placeholder="your message"
                sx={{ mb: 2 }}
                    InputProps={{
                  style:{
                    borderRadius:'8px',
                    fontWeight:500,
                    fontSize:'13px'
                  }
                }}
              />

              <Button
                disabled={!fullName || !email || !message}
                onClick={handleSendContact}
                variant="contained"
                sx={{ mt: 3, borderRadius: "50px", py:2 }}
                fullWidth
              >
                Send Message
              </Button>
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "36px",
                  color: "#F8F7F7",
                  fontWeight: 500,
                  mt: { lg: 0, md: 0, sm: 5, xs: 3 },
                  p:2
                }}
              >
                Contact Us
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  p: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={mail} alt="email_icon" />
                <Box>
                  <Typography sx={{ color: "#fff",        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" }, }}>Email Support</Typography>
                  <Typography sx={{ color: "#fff",        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" }, }}>
                    care@mamaket.com
                  </Typography>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  px: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={phone} alt="email_icon" />
                <Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        sm: "12px",
                        xs: "12px",
                      },
                    }}
                  >
                    Phone Support
                  </Typography>
                  <Typography sx={{ color: "#fff",        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },}}>
                    Call line: (786) 998-1929
                  </Typography>
                  <Typography sx={{ color: "#fff",        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },}}>
                    Fax: (786) 998-1931
                  </Typography>
                </Box>
              </Box> */}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  p: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={locationIcon} alt="email_icon" />
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#fff" ,        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },}}
                  >
                    Office Address
                  </Typography>
                  <Typography sx={{ color: "#fff",        fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" }, }}>
                    66 W Flagler Street, <br />
                   Suite 900  Miami, 
                    <br /> FL 33130 USA
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
};

export default Contact;
