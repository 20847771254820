export const NavData = [
    {
        title: 'Profile',
        link: '/account'
    },
    {
        title: 'Mamapurse',
        link: '/account/mamapurse'
    },
    {
        title: 'My Orders',
        link: '/account/orders'
    },
    {
        title: 'Address',
        link: '/account/address'
    },
    {
        title: 'Security',
        link: '/account/security'
    },
    {
        title: 'Notification',
        link: '/account/notification'
    },
    {
        title: 'Help Center',
        link: '/account/help-center'
    },
]