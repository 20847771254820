import {
  Box,
  Divider,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import React from "react";
import googleButton from "../../assets/Link.svg";
import appleButton from "../../assets/Icons/apple.svg";
import facebook from "../../assets/Icons/facebook 1.svg";
import instagram from "../../assets/Icons/instagram 1.svg";
import linkedin from "../../assets/Icons/googles.svg";
import youtube from "../../assets/Icons/youtube.svg";
import tiktok from "../../assets/Icons/tiktok.svg";
import thread from "../../assets/Icons/email.svg";
import twitter from "../../assets/Icons/twitter 1.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  // const classes = useStyles();
  return (
    <>
      <Box sx={{ bgcolor: "#2E1834", py: 4 }}>
        <Grid
          container
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "95%", xs: "95%" },
          }}
        >
          <Grid item lg={7.5} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={2} xs={6}>
                <Box>
                  <Typography
                    sx={{
          
                      fontWeight: 600,
                      color: "#e6e6e6",
                      fontSize: "14px",
                      mb: 2,
                    }}
                  >
                    MAMAKET
                  </Typography>
                  <Link to="/about-us">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      About Us
                    </Typography>
                  </Link>
                  <Link to="/blog">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                    News Update
                    </Typography>
                  </Link>
                  {/* <Typography
                    sx={{
          
                      fontWeight: 400,
                      color: "#e6e6e6",
                      fontSize: "14px",
                    }}
                  >
                    Careers
                  </Typography> */}
                  <Link to="/contact">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Box>
                  <Typography
                    sx={{
          
                      fontWeight: 600,
                      color: "#e6e6e6",
                      fontSize: "14px",
                      mb: 2,
                    }}
                  >
                    BUYING ON MAMAKET
                  </Typography>
                  <Link to="/faq">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      FAQs
                    </Typography>
                  </Link>
                  {/* <Typography
                    sx={{
          
                      fontWeight: 400,
                      color: "#e6e6e6",
                      fontSize: "14px",
                    }}
                  >
                    Delivery
                  </Typography> */}
                  <Link to="/return-policy">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Return Policy
                    </Typography>
                  </Link>
                  <Link to="/mamapurse">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Mamapurse
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={3} xs={6}>
                <Box>
                  <Typography
                    sx={{
          
                      fontWeight: 600,
                      color: "#e6e6e6",
                      fontSize: "14px",
                      mb: 2,
                    }}
                  >
                    SELLING ON MAMAKET
                  </Typography>
                  <Link to="/footer-product">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Product
                    </Typography>
                  </Link>
                  <Link to="/verification">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Verification
                    </Typography>
                  </Link>
                  <Link to="/account/mamapurse">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Payment
                    </Typography>
                  </Link>
                  {/* <Link to="/ads">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Ads
                    </Typography>
                  </Link> */}
                </Box>
              </Grid>
              <Grid item lg={2} xs={6}>
                <Box>
                  <Typography
                    sx={{
          
                      fontWeight: 600,
                      color: "#e6e6e6",
                      fontSize: "14px",
                      mb: 2,
                    }}
                  >
                    MORE INFO
                  </Typography>
                  <Link to="/account/orders">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Track My Order
                    </Typography>
                  </Link>
                  <Link to="/privacy-policy">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Privacy Policy{" "}
                    </Typography>
                  </Link>
                  <Link to="/terms&conditions">
                    <Typography
                      sx={{
            
                        fontWeight: 400,
                        color: "#e6e6e6",
                        fontSize: "14px",
                      }}
                    >
                      Terms & Conditions
                    </Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={4.5}
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid container>
              <Grid item lg={8}>
                <Box>
                  <Typography sx={{ color: "#fff", fontWeight: 600 }}>
                    Download Mobile App
                  </Typography>
                  <Box sx={{ ml: -1 }}>
                    <IconButton>
                      <img src={googleButton} alt="google_download_button" />
                    </IconButton>
                    <IconButton>
                      <img src={appleButton} alt="apple_download_button" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box>
                  <Typography sx={{ color: "#fff", fontWeight: 600 }}>
                    Socials
                  </Typography>
                  <Box sx={{ ml: -1 }}>
                    <a href="https://www.facebook.com/me/">
                      <IconButton>
                        <img src={facebook} />
                      </IconButton>
                    </a>
                    <a href="https://twitter.com/MamaketN">
                      <IconButton>
                        <img src={twitter} />
                      </IconButton>
                    </a>
                    <a href="https://www.instagram.com/mamaketinc/">
                      <IconButton>
                        <img src={instagram} />
                      </IconButton>
                    </a>
                    <a href="https://www.linkedin.com/company/99913366/admin/feed/posts/">
                      <IconButton>
                        <img src={linkedin} />
                      </IconButton>
                    </a>
                    <a href="https://www.youtube.com/channel/UCRtYYp9Rn06y2ben4m53KiQ ">
                      <IconButton>
                        <img src={youtube} />
                      </IconButton>
                    </a>
                    <a href="https://www.tiktok.com/@mamaketinc?_t=8gy5D1DL7Cv&_r=1">
                      <IconButton>
                        <img src={tiktok} />
                      </IconButton>
                    </a>
                    <a href="https://threads.net/@mamaketinc">
                      <IconButton>
                        <img src={thread} />
                      </IconButton>
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider
          sx={{
            "&::before, ::after": { bgcolor: "#fff" },
            margin: "0 auto",
            width: "90%",
            mt: 9,
          }}
        >
          <Typography
            sx={{
              color: "#F8F7F7",
  
              fontSize: { lg: "12px", xs: "10px" },
            }}
          >
            Copyright © {new Date().getFullYear()} Mamaket, Inc All rights
            reserved
          </Typography>
        </Divider>
      </Box>
    </>
  );
};

export default Footer;
