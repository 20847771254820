import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  useTheme,
  Select,
  Modal
} from "@mui/material";
import React, { useState, useEffect } from "react";
import card_vector from "../../assets/mamapurse_vector.svg";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLazyQuery } from "@apollo/client";
import history_icon from "../../assets/Icons/empty_history.svg";
import Cashout from "../Components/Mamapurse/Cashout";
import AddCash from "../Components/Mamapurse/AddCash";

import Loader from "../../Components/Common/Loader";
import { GET_ALL_PUSRSES, GET_ALL_TRANSACTIONS } from "../../Utils/Queries";
import arrowUp from "../../assets/Icons/Arrow - Up Square.svg";
import arrowDown from "../../assets/Icons/Arrow - Down Square.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useLocation , useNavigate} from "react-router-dom";
import succesIcon from '../../assets/Icons/verify 1.svg'
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
borderRadius:4,
  boxShadow: 24,
  p: 4,
};

const Mamapurse = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const location = useLocation();

  const navigate = useNavigate();
    const locationState = location.state;

    const from = location.state?.from?.pathname || "/";
  const [getWallets, { data: wallets, loading, error }] = useLazyQuery(
    GET_ALL_PUSRSES
  );
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [
    getTransactions,
    { data: transactions, loading: transactionLoad },
  ] = useLazyQuery(GET_ALL_TRANSACTIONS);

  useEffect(() => {
    getWallets().then((res)=>{

    }).catch((err)=>{
    handleAlert(`${err.message}`, "error")
    });
    getTransactions().then((res)=>{

    }).catch((err)=>{
    handleAlert(`${err.message}`, "error")
    });;
  }, []);

  const theme = useTheme();
  const spendingBalance = wallets?.getWallets[0]?.spendingBalance;
  const transactiionList = transactions?.fetchDefaultWalletHistories?.items;

  const [hidden, setHidden] = useState(false);

  function formatDateString(originalDateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(originalDateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, transactiionList?.length);
  const rowsText = `${startRow}-${endRow} of ${transactiionList?.length}`;

  const { pathname } = useLocation();


  useEffect(() => {
    if (pathname.includes("confirmation")) {
      handleOpen()
    }
  }, []);

    const handleReroute = () =>{
      const mama_route = localStorage.getItem("mamapurse_reroute");
    handleClose()
      if (mama_route) {
          navigate(`${mama_route}`);
          localStorage.removeItem("mamapurse_reroute")
        } else {

        }
  }

  



  return (
    <>
      {loading && <Loader />}

      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Typography sx={{ color: "#333", fontSize: "20px", fontWeight: 500 }}>
          Mamapurse
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Grid container sx={{ display: "flex", columnGap: 2 }}>
            <Grid item lg={4.5} md={4.2} sm={12} xs={12}>
              <Box
                sx={
                  {
                    // display: "grid",
                    // placeItems: "center",
                    // height: "300px",
                  }
                }
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: "180px",
                    borderRadius: 0.3,
                    width: "100%",
                    backgroundImage: `url('${card_vector}')`,
                    p: 2,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                      Total Balance
                    </Typography>
                    <IconButton onClick={() => setHidden(!hidden)}>
                      {hidden ? (
                        <VisibilityOffOutlinedIcon
                          sx={{ color: "#fff", fontSize: "14px" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          sx={{ color: "#fff", fontSize: "14px" }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  {hidden ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "28px",
                        mt: -2,
                        fontWeight: 600,
                      }}
                    >
                      {`$${
                        spendingBalance ? spendingBalance.toLocaleString() : "0"
                      }`}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "28px",
                        mt: -2,
                        fontWeight: 600,
                      }}
                    >
                      {`$***`}
                    </Typography>
                  )}

                  <AddCash text="Add Cash" />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={7.2} md={7.2} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // height: "300px",
                  pl: { lg: 2, md: 2, sm: 0, xs: 0 },
                  mt: { lg: 0, md: 0, sm: 3, xs: 3 },
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "14px", fontWeight: 500 }}
                >
                  Transaction History
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#f8f7f7",

                    mt: 1,
                    p: 2,
                  }}
                >
                  {!transactiionList ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img src={history_icon} alt="history_empty_icon" />
                        <Typography sx={{ color: "#999", fontSize: "12px" }}>
                          No history available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {transactiionList
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((transaction, index) => (
                          <Box
                            sx={{
                              mt: 0.5,
                              display: "flex",
                              alignItems: "center",
                              borderBottom: "1px solid #f2f2f2",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <img
                                src={
                                  transaction?.action === "CREDIT"
                                    ? arrowDown
                                    : arrowUp
                                }
                                alt="cash_out"
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#212121",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                >
                                  {transaction.type}
                                </Typography>
                                <Typography
                                  sx={{ color: "#666", fontSize: "11px" }}
                                >
                                  {`${formatDateString(
                                    transaction?.updatedAt
                                  )}`}{" "}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              sx={{
                                color: "#212121",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              ${transaction?.amount.toLocaleString()}
                              <br />
                              <span style={{ color: "#666", fontSize: "10px" }}>
                                {transaction.status}
                              </span>
                            </Typography>
                          </Box>
                        ))}
                    </>
                  )}
                </Box>
                <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 3, py: 1 }}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        color: "#424242",
                        fontSize: "13px",
              
                      }}
                    >
                      {rowsText}
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} xs={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        size="small"
                        sx={{
                
                          color: "#424242",
                          fontSize: "13px",
                        }}
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        InputProps={{
                          style: {
                  
                            fontSize: "13px",
                            // borderRadius: "8px",

                            // Replace with your desired font family
                          },
                        }}
                      >
                        <MenuItem
                          sx={{ color: "#424242" }}
                          value={5}
                        >
                          5
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "#424242" }}
                          value={10}
                        >
                          10
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "#424242" }}
                          value={25}
                        >
                          25
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "#424242" }}
                          value={50}
                        >
                          50
                        </MenuItem>
                      </Select>
                      <Box
                        sx={{
                          borderLeft: "1px solid #424242",
                          pl: { md: 4, xs: 1 },
                          ml: { md: 3, xs: 1 },
                        }}
                      >
                        <IconButton
                          disabled={startRow === 1}
                          onClick={handleChangePageLeft}
                          sx={{
                            width: "30px",
                            height: "30px",
                            border: "1px solid #424242",
                            "&.Mui-disabled": {
                              border: "1px solid #4242421a",
                            },
                          }}
                        >
                          <ChevronLeftRoundedIcon />
                        </IconButton>
                        <IconButton
                          disabled={endRow === transactiionList?.length}
                          onClick={handleChangePageRight}
                          sx={{
                            ml: 4,
                            width: "30px",
                            height: "30px",
                            border: "1px solid #424242",
                            "&.Mui-disabled": {
                              border: "1px solid #4242421a",
                            },
                          }}
                        >
                          <ChevronRightRoundedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <img src={succesIcon}/>
             <Typography sx={{ mt: 4, fontWeight:700, fontSize:'20px', textAlign:'center', color:'#151515' }}>
         Payment Made Successful
          </Typography>

          <Typography sx={{cursor:'pointer', mt:2, textDecoration:'underline', fontWeight:600}} onClick={handleReroute}>Close</Typography>
          </Box>
         
        </Box>
      </Modal>
    </>
  );
};

export default Mamapurse;
