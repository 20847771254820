import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import sendIcon from "../../src/assets/Icons/send-2.svg";
import TextField from "@mui/material/TextField";
import "../STYLES/main.css";

import { useLocation, useParams } from "react-router-dom";
import socket from "../Utils/socket";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { HiChatAlt2 } from "react-icons/hi";

const CssTextField = styled(TextField)({
  background: "#f2f2f2",
  borderRadius: "8px",
  padding: "0px",
  "& label.Mui-focused": {
    color: "#A0AAB4",
    bgcolor: "#F2F2F2",
    padding: "0px",
    fontWeight: 500,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#f2f2f2",
    bgcolor: "#F2F2F2",
    padding: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#666",
      borderColor: "#f2f2f2",
      bgcolor: "#F2F2F2",
      padding: "0px",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      padding: "0px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
      padding: "0px",
    },
  },
});
const ChatOutlet = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [uniqueId, setUniqueId] = useState(null);
  const messagesContainerRef = useRef(null);
  const [message, setMessage] = useState("");
  const [chatLoad, setChatLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [otherUserName, setOtherUserName] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nameParams = searchParams.get("name");

  const userId = useSelector((state) => state.user.user.profile._id);

  const handleClickVariant = (variant, message) => () => {
    enqueueSnackbar(message, { variant });
  };
  const JOIN = "joinRoom";
  const EXCEPTION = "exception";
  const JOINED_ROOM = "joinedRoom";
  const SEND_MESSAGE = "sendMessage";
  const RECEIVED_MESSAGE = "receivedMessage";
  const RECIEVED_LAST_MESSAGE = "receivedLastMessage";
  const RECIEVED_OFFER_UPDATE = "receivedOfferUpdate";
  const OFFER_UPDATE = "acceptOrDeclineOffer";

  const payLoad = {
    roomChannel: id,
    operationMode: "Product",
  };

  function convertUTCToLocalTime(utcTimestamp, timeFormat) {
    const date = new Date(utcTimestamp);
    const localTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (timeFormat === "12-hour") {
      // Return time in 12-hour format
      return localTime;
    } else if (timeFormat === "24-hour") {
      // Return time in 24-hour format
      return localTime.replace(/(am|pm)/i, "").trim();
    } else {
      // Invalid time format
      return "Invalid time format";
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const [offerLoading, setOfferLoading] = useState(false);
  const [offerLoading2, setOfferLoading2] = useState(false);

  const handleUpdateOffer = (isAccepted, offerId) => {
    if(isAccepted === true){
     setOfferLoading(true)
    }else{

      setOfferLoading2(true);
    }
    socket.emit(OFFER_UPDATE, { offerId: offerId, isAccepted: isAccepted });

    socket.on(OFFER_UPDATE, (data) => {
      setOfferLoading(false);
      setOfferLoading2(false);
      setMessageHistory((prev) => [...prev, data]);
    });
  };

  const handleSendMessage = () => {
    setIsLoading(true);
    const newUniqueId = uuidv4();
    setUniqueId(newUniqueId);

    const chatPayload = {
      roomChannel: id,
      message: message,
      clientMessageId: newUniqueId,
    };



    socket.emit(SEND_MESSAGE, chatPayload);

    setIsLoading(false);
    setMessage("");
  };

  useEffect(() => {
    setMessageHistory(null);
    setChatLoad(true);
    socket.emit(JOIN, payLoad);

    socket.on(JOINED_ROOM, (data) => {
      setMessageHistory(data?.history);
      setOtherUserName(data?.otherUserName);
      setChatLoad(false);
    });

    socket.on(EXCEPTION, (err) => {
      enqueueSnackbar(`${err.message}`, { variant: "error" });
    });

    socket.on(RECEIVED_MESSAGE, (data) => {
      setMessageHistory((prev) => [...prev, data]);
    });

    socket.on(RECIEVED_LAST_MESSAGE, (data) => {
      setMessageHistory((prev) => [...prev, data]);
    });

    return () => {
      socket.off(EXCEPTION);
      socket.off(JOINED_ROOM);
      socket.off(RECEIVED_MESSAGE);
      socket.off(RECIEVED_LAST_MESSAGE);
    };
  }, [id]);


  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            bgcolor: "#e6e6e6",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
            <HiChatAlt2 />

            <Box>
              <Box>
                <Typography
                  sx={{ color: "#000", fontWeight: 500, fontSize: "16px" }}
                >
                  {nameParams || "user"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            {/* <Button sx={{ bgcolor: "#fff", color: "#000" }}>
              Order details
            </Button> */}
          </Box>
        </Box>
        <Box align="center" sx={{ bgcolor: "#f2f2f2b7" }}>
          <Box sx={{ mt: 2 }}>{chatLoad && <CircularProgress />}</Box>
          <Typography
            sx={{
              bgcolor: "#F2F2F2",
              width: "fit-content",
              px: 2,
              fontSize: "11px",
              color: "#333",
              borderRadius: "100px",
            }}
          >
            {`  You are now chatting with ${nameParams || "user"}`}
          </Typography>
        </Box>
        <Box
          ref={messagesContainerRef}
          sx={{
            p: 2,
            bgcolor: "#f2f2f2b7",
            height: "290px",
            pb: 2,
            overflowY: "auto",
          }}
        >
          <Box align="center">
            <Typography
              sx={{
                bgcolor: "#F2F2F2",
                width: "fit-content",
                borderRadius: "100px",
                px: 2,
                fontSize: "11px",
                color: "#333",
              }}
            >
              Today
            </Typography>
          </Box>

          {messageHistory &&
            messageHistory.map((chat, index) => (
              <>
                {/* MAIN USER CHAT ======================================================================================================== */}
                {chat.sender === userId ? (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "right",
                      alignItems: "end",
                      mt: 2,
                    }}
                  >
                    {chat.messageType === "offer" ? (
                      <>
                        {/* SERVICE OFFER FOR USER ============================================================================================= */}
                        {chat?.offer?.operationMode === "Service" && (
                          <Box
                            sx={{
                              bgcolor: "#5C30691a",
                              borderRadius: "5px",
                              p: 1,
                              maxWidth: {
                                lg: "40%",
                                md: "40%",
                                sm: "80%",
                                xs: "80%",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                border: "0.5px solid #F2F2F2",
                                bgcolor: "#fff",
                                p: 1,
                                borderRadius: "3px",
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                src={chat?.offer?.item?.images[0]}
                              />
                              <Box>
                                <InputLabel sx={{ color: "#151515" }}>
                                  {chat?.offer?.operationMode === "Service"
                                    ? chat?.offer?.item?.title
                                    : chat?.offer?.item?.name}
                                </InputLabel>
                                <Typography
                                  sx={{
                                    color: "#151515",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.item?.price)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontSize: "11px",
                                  lineHeight: "20px",
                                  mt: 0.5,
                                  fontWeight: 500,
                                }}
                              >
                                ${parseFloat(chat?.offer?.offerPrice)} Offer
                                sent
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                columnGap: 2,
                                justifyContent: "space-between",
                                mt: 1,
                              }}
                            >
                              <Box>
                                <Link
                                  to={`/cart?tab=service`}
                                >
                                  <Button
                                    sx={{
                                      bgcolor: "#3FB763",
                                      fontSize: "10px",
                                      color: "#fff",
                                    }}
                                  >
                                    Book Appointment
                                  </Button>
                                </Link>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{ color: "#333", fontSize: "9px" }}
                                >
                                  {convertUTCToLocalTime(
                                    chat.updatedAt,
                                    "12-hour"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {/* PRODUCT OFFER FOR USER ===========================================================================================*/}
                        {chat?.offer?.operationMode === "Product" && (
                          <Box
                            sx={{
                              bgcolor: "#5C30691a",
                              borderRadius: "5px",
                              p: 1,
                              maxWidth: {
                                lg: "30%",
                                md: "40%",
                                sm: "80%",
                                xs: "80%",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                border: "0.5px solid #F2F2F2",
                                bgcolor: "#fff",
                                p: 1,
                                borderRadius: "3px",
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                src={chat?.offer?.item?.images[0]}
                              />
                              <Box>
                                <InputLabel sx={{ color: "#151515" }}>
                                  {chat?.offer?.operationMode === "Service"
                                    ? chat?.offer?.item?.title
                                    : chat?.offer?.item?.name}
                                </InputLabel>
                                <Typography
                                  sx={{
                                    color: "#151515",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.item?.price)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontSize: "11px",
                                  lineHeight: "20px",
                                  mt: 0.5,
                                  fontWeight: 500,
                                }}
                              >
                                ${parseFloat(chat?.offer?.offerPrice)} Offer
                                sent
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                columnGap: 2,
                                justifyContent: "space-between",
                                mt: 1,
                              }}
                            >
                              <Box>
                                <Link
                                  to={`/product-view/${chat?.offer?.item?._id}`}
                                >
                                  <Button
                                    sx={{
                                      bgcolor: "#3FB763",
                                      fontSize: "10px",
                                      color: "#fff",
                                    }}
                                  >
                                    Buy Now
                                  </Button>
                                </Link>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{ color: "#333", fontSize: "9px" }}
                                >
                                  {convertUTCToLocalTime(
                                    chat.updatedAt,
                                    "12-hour"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    ) : (
                      <>
                        {/* NORMAL CHAT MESSAGE FOR USER ============================================================================================ */}
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: {
                              lg: "30%",
                              md: "40%",
                              sm: "80%",
                              xs: "80%",
                            },
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "11px",
                              lineHeight: "20px",
                            }}
                          >
                            {chat.message}
                          </Typography>

                          <Typography sx={{ color: "#333", fontSize: "9px" }}>
                            {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ) : (
                  // OTHER USER CHAT COMPONENTS ==============================================================================

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 2,
                    }}
                  >
                    {chat.messageType === "offer" ? (
                      <>
                        {/* OTHER USER SERVICE OFFER ======================================================================================================== */}
                        {chat?.offer?.operationMode === "Service" && (
                          <Box
                            sx={{
                              bgcolor: "#F2F2F2",
                              borderRadius: "5px",
                              p: 1,
                              maxWidth: {
                                lg: "30%",
                                md: "40%",
                                sm: "80%",
                                xs: "80%",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                border: "0.5px solid #F2F2F2",
                                bgcolor: "#fff",
                                p: 1,
                                borderRadius: "3px",
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                src={chat?.offer?.item?.images[0]}
                              />
                              <Box>
                                <InputLabel sx={{ color: "#151515" }}>
                                  {chat?.offer?.item?.title}
                                </InputLabel>
                                <Typography
                                  sx={{
                                    color: "#151515",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.item?.price)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {chat?.offer?.isAccepted && (
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "11px",
                                    lineHeight: "20px",
                                    mt: 0.5,
                                    fontWeight: 500,
                                  }}
                                >
                                  Offer Accepted 🎉🎉
                                </Typography>
                              )}
                              {!chat?.offer?.isAccepted &&
                                chat?.offer?.isClosed && (
                                  <Typography
                                    sx={{
                                      color: "#FF4C5E",
                                      fontSize: "11px",
                                      lineHeight: "20px",
                                      mt: 0.5,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Offer Declined
                                  </Typography>
                                )}

                              {chat?.offer?.isClosed === false && (
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "11px",
                                    lineHeight: "20px",
                                    mt: 0.5,
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.offerPrice)} Offer
                                  sent
                                </Typography>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                columnGap: 2,
                                justifyContent: "space-between",
                                mt: 1,
                              }}
                            >
                          
                              {chat?.offer?.isClosed === false && (
                                <Box>
                                  <Button
                                    onClick={() =>
                                      handleUpdateOffer(true, chat?.offer?._id)
                                    }
                                    sx={{
                                      bgcolor: "#3FB763",
                                      fontSize: "10px",
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      offerLoading ? (
                                        <CircularProgress  size={15} color="#fff"/>
                                      ):("Accept")
                                    }
                                 
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleUpdateOffer(false, chat?.offer?._id)
                                    }
                                    sx={{
                                      bgcolor: "#fff",
                                      fontSize: "10px",
                                      border: "1px solid #E6E6E6",
                                      color: "#151515",
                                      ml: 2,
                                    }}
                                  >
                                    {
                                      offerLoading2 ? (
                                        <CircularProgress  size={15} color="#151515"/>
                                      ):("Declined")
                                    }
                                  </Button>
                                </Box>
                              )}
                              <Box>
                                <Typography
                                  sx={{ color: "#333", fontSize: "9px" }}
                                >
                                  {convertUTCToLocalTime(
                                    chat.updatedAt,
                                    "12-hour"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {/* OTHER USER PRODUCT OFFER ================================================================================================= */}
                        {chat?.offer?.operationMode === "Product" && (
                          <Box
                            sx={{
                              bgcolor: "#F2F2F2",
                              borderRadius: "5px",
                              p: 1,
                              maxWidth: {
                                lg: "30%",
                                md: "40%",
                                sm: "80%",
                                xs: "80%",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                border: "0.5px solid #F2F2F2",
                                bgcolor: "#fff",
                                p: 1,
                                borderRadius: "3px",
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                src={chat?.offer?.item?.images[0]}
                              />
                              <Box>
                                <InputLabel sx={{ color: "#151515" }}>
                                  {chat?.offer?.item?.name}
                                </InputLabel>
                                <Typography
                                  sx={{
                                    color: "#151515",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.item?.price)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {chat?.offer?.isAccepted && (
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "11px",
                                    lineHeight: "20px",
                                    mt: 0.5,
                                    fontWeight: 500,
                                  }}
                                >
                                  Offer Accepted 🎉🎉
                                </Typography>
                              )}
                              {!chat?.offer?.isAccepted &&
                                chat?.offer?.isClosed && (
                                  <Typography
                                    sx={{
                                      color: "#FF4C5E",
                                      fontSize: "11px",
                                      lineHeight: "20px",
                                      mt: 0.5,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Offer Declined
                                  </Typography>
                                )}

                              {chat?.offer?.isClosed === false && (
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "11px",
                                    lineHeight: "20px",
                                    mt: 0.5,
                                    fontWeight: 500,
                                  }}
                                >
                                  ${parseFloat(chat?.offer?.offerPrice)} Offer
                                  sent
                                </Typography>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                columnGap: 2,
                                justifyContent: "space-between",
                                mt: 1,
                              }}
                            >
                
                              {chat?.offer?.isClosed === false && (
                                 <Box>
                                  <Button
                                    onClick={() =>
                                      handleUpdateOffer(true, chat?.offer?._id)
                                    }
                                    sx={{
                                      bgcolor: "#3FB763",
                                      fontSize: "10px",
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      offerLoading ? (
                                        <CircularProgress  size={15} color="#fff"/>
                                      ):("Accept")
                                    }
                                 
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleUpdateOffer(false, chat?.offer?._id)
                                    }
                                    sx={{
                                      bgcolor: "#fff",
                                      fontSize: "10px",
                                      border: "1px solid #E6E6E6",
                                      color: "#151515",
                                      ml: 2,
                                    }}
                                  >
                                    {
                                      offerLoading2 ? (
                                        <CircularProgress  size={15} color="#151515"/>
                                      ):("Declined")
                                    }
                                  </Button>
                                </Box>
                              )}
                              <Box>
                                <Typography
                                  sx={{ color: "#333", fontSize: "9px" }}
                                >
                                  {convertUTCToLocalTime(
                                    chat.updatedAt,
                                    "12-hour"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    ) : (
                      <>
                        {/* OTHER USER NORMAL CHAT  */}
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: {
                              lg: "30%",
                              md: "40%",
                              sm: "80%",
                              xs: "80%",
                            },
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "11px",
                              lineHeight: "20px",
                            }}
                          >
                            {chat.message}
                          </Typography>
                          <Box>
                            <Typography sx={{ color: "#333", fontSize: "9px" }}>
                              {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </>
            ))}
        </Box>
        <Box
          sx={{
            border: "1px solid #f2f2f2",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <IconButton onClick={handleOpen}>
            <img src={addIcon} alt="add_icon" />
          </IconButton> */}
          <CssTextField
            onKeyDown={handleKeyDown}
            fullWidth
            multiline
            maxRows={2}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            size="small"
            placeholder="Message"
            id="custom-css-outlined-input"
          />
          <IconButton onClick={handleSendMessage}>
            {isLoading ? <p>....</p> : <img src={sendIcon} alt="send_icon" />}
          </IconButton>
        </Box>
      </Box>

      {/* <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                textAlign: "center",
                color: "#333",
                fontSize: "20px",
              }}
            >
              Upload File
            </Typography>
            <Box
              sx={{
                mt: 4,
                mx: 4,
                border: "1px dashed #2E1834",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
              }}
            >
              <img src={uploadIcon} width={30} alt="upload_icon" />
              <Box>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Select a file or drag and drop here
                </Typography>
                <Typography sx={{ color: "#666", fontSize: "11px" }}>
                  JPG, PNG or PDF, file size no more than 10MB
                </Typography>
              </Box>
              <Button size="small" component="label">
                SELECT FILE
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
            {progress > 0 && (
           
              <Box sx={{ mt: 2, mx: 2 }}>
                <Typography sx={{ color: "#333", fontSize: "11px" }}>
                  File added
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "100%",
                  }}
                >
                  <Avatar variant="square" src={thumbnailUrl} />
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#333", fontSize: "11px" }}>
                        {fileName}
                      </Typography>
                      <Typography sx={{ color: "#333", fontSize: "11px" }}>
                        {fileSize}
                      </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            align="right"
            sx={{
              mt: "auto",
              bgcolor: "#FBFDFE",
              p: 2,
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10) inset",
            }}
          >
            <Box>
              <Button sx={{ color: "#333", mr: 2 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained">Upload</Button>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
};

export default ChatOutlet;
