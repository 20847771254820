import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,

  Button,

  Box,
  Typography,
  ListItem,
  Badge,

  Skeleton,
  List,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CloseOutlined } from "@mui/icons-material";
import sLogo from "../../assets/Logo/slogo.svg";
import Logo from "../../assets/Logo/logoNew.svg";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import user from "../../assets/Icons/user 2.svg";

import box from "../../assets/Icons/box.svg";
import wallet from "../../assets/Icons/Wallet.svg";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { logoutUser } from "../../store/userSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { GET_CATEGORIES_N_SUB } from "../../Utils/Queries";
import { useQuery , gql} from "@apollo/client";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HashLink } from "react-router-hash-link";
import useGetCart from "../../Hooks/useGetCart";
import useGetServiceCart from "../../Hooks/useGetServiceCart";
import useGetFav from "../../Hooks/useGetFav";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontSize:'7px',
          fontFamily: "IBM Plex Sans",
  },
}));
const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;
const GET_SERVICE_CART = gql`
  query GetServiceItems {
    serviceCarts @client
  }
`;

const GET_FAV_CART = gql`
  query GetFavItems {
    favItemsVar @client
  }
`;

const Drawers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


    const {  data:product_cart } = useQuery(GET_CART_ITEMS);
  const { data: serviceCart } = useQuery(GET_SERVICE_CART);
    const { data: favItems } = useQuery(GET_FAV_CART);

  const favProducts = useSelector((state) => state.favourite);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [collapse, setCollapse] = useState(false);

  const isAuthenticated = useSelector((state) => state.user?.isAuthenticated);
  const userInfo = useSelector((state) => state.user?.user?.profile);

  const { loading, data, error } = useQuery(GET_CATEGORIES_N_SUB);


    const { handleGetCart, refetch } = useGetCart();
    const { handleGetCart:handleServiceCart, refetch:refecthCart } = useGetServiceCart();

      const {handleGetFav, refetch:favRefetch} = useGetFav()

  useEffect(() => {
    handleGetCart();
    refetch();
    handleServiceCart();
    refecthCart()
        favRefetch();
    handleGetFav()
  }, []);




  const cartProducts = data?.cartItems || [];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const handleLogOut = () => {
    navigate("/");
    setAnchorEl(null);
    dispatch(logoutUser());
  };

      const isSeller = useSelector((state) => state.user?.user?.profile?.hasPaidMembershipFee);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: "70vw" }}
      >
        <Box sx={{ p: 1.5, width: "70vw" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              onClick={() => setOpenDrawer(!openDrawer)}
              sx={{ mr: 2 }}
            >
              <CloseIcon sx={{ color: "#000", fontSize: "25px" }} />
            </IconButton>
            <Link to="/">
              <img src={Logo} width={80} style={{ marginTop: "10px" }} />
            </Link>
          </Box>

          <Box sx={{ p: 1 }}>
            <Box
              sx={{
                bgcolor: "#2E1834",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {isAuthenticated ? (
                <Typography
                  sx={{ color: "#fff", fontSize: "12px" }}
                >{`Hello, ${userInfo?.firstName}`}</Typography>
              ) : (
                <Typography sx={{ color: "#fff" }}>Hello, Sign In</Typography>
              )}
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenDrawer(false)}
              >
                <CloseOutlined />
              </IconButton>
            </Box>

            <Box
              sx={{
                mt: 3,
             
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0", // Set the width of the scrollbar to zero
                },
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Box
                  onClick={() => setCollapse(!collapse)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: 700, color: "#1a1a1a" }}>
                    Categories
                  </Typography>
                  <IconButton onClick={() => setCollapse(!collapse)}>
                    {collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
                <Box sx={{ py: 1, display: collapse ? "block" : "none" }}>
                  {loading ? (
                    <>
                      <ListLoader />
                    </>
                  ) : (
                    <>
                      {data?.fetchAllCategoriesAndTheirSubCategories.map(
                        (item, index) => (
                          <Link
                            to={`/category-view/${item.category.name}/${item.category._id}`}
                          >
                            <ListItemButton
                              key={index}
                              sx={{
                                p: 0,
                                px: 1,
                                py: 0.5,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 0,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                {item?.category?.name}
                              </Typography>

                              <IconButton>
                                {/* <ChevronRightIcon /> */}
                              </IconButton>
                            </ListItemButton>
                          </Link>
                        )
                      )}
                    </>
                  )}
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 700, color: "#1a1a1a" }}>
                  Deals
                </Typography>
                <Box sx={{ p: 1 }}>
                  <HashLink
                    to="/#latestProducts"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: "#333", fontWeight: 400 }}
                    >
                      Latest
                    </Typography>
                  </HashLink>
                  <HashLink
                    to="/#topProducts"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: "#333", fontWeight: 400 }}
                    >
                      Top Sellers
                    </Typography>
                  </HashLink>
                </Box>
              </Box>
              <Box sx={{my:3}}>
     {
                isSeller ? (
                  <Link to="/sell-on-mamaket">
   <Button
   fullWidth
                  variant="contained"
                  sx={{
                    // borderRadius: "40px",
             
                  }}
                >
                  Sell on Mamaket
                </Button>
                  </Link>
                ):(
     <Link to="/download">
                <Button
                fullWidth
                  variant="contained"
                  sx={{
                    // borderRadius: "40px",
                    background: "#1a1a1a",
                    "&:hover": { background: "#1a1a1ab7" },
                  }}
                >
                  Become A Seller
                </Button>
              </Link>
                )
              }
              </Box>
            </Box>
          </Box>

          <Box>
            <Link to="/notifications">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<NotificationsNoneIcon />}
              >
                Notification
              </Button>
            </Link>
          </Box>
          <Box>
            <Link to="/account/mamapurse">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<img src={wallet} />}
              >
                Mamapurse
              </Button>
            </Link>
            <Link to="/account/orders">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<img src={box} />}
              >
                My Order
              </Button>
            </Link>
          </Box>
          <Box>
            {isAuthenticated ? (
              <>
                <Button
                  onClick={handleLogOut}
                  sx={{ fontWeight: 500, color: "#333" }}
                  startIcon={<NoAccountsIcon />}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", gap: 1, pr: 1 }}>
                  <Link to="/login">
                    <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                      Login
                    </Typography>
                  </Link>
                  <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                    /
                  </Typography>
                  <Link to="/register">
                    <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                      Signup
                    </Typography>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
      {/* </Popover> */}

      <Box
        sx={{
          py: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon sx={{ color: "#000" }} />
          </IconButton>
          <Link to="/">
            <img src={sLogo} />
          </Link>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {isAuthenticated ? (
            <>
              <IconButton>
                <Link to="/cart">
                  <StyledBadge
                         badgeContent={
                            isAuthenticated
                              ? product_cart &&
                                product_cart?.cartItems.length +
                                  serviceCart?.serviceCarts.length
                              : 0
                          }
                    color="error"
                  >
                    <ShoppingCartIcon
                      color="action"
                      sx={{ color: "#000", fontSize: "20px" }}
                    />
                  </StyledBadge>
                </Link>
              </IconButton>
              <Link to="/wishlist">
                <StyledBadge         badgeContent={isAuthenticated ? favItems?.favItemsVar?.length : 0} color="error">
                  <FavoriteIcon
                    color="action"
                    sx={{ color: "#000", fontSize: "20px" }}
                  />
                </StyledBadge>
              </Link>
              <IconButton>
                <Link to="/chat">
                  <StyledBadge badgeContent={0} color="error">
                    <ChatIcon
                      color="action"
                      sx={{ color: "#000", fontSize: "20px" }}
                    />
                  </StyledBadge>
                </Link>
              </IconButton>
              <Link to="/my-account">
                <IconButton sx={{ borderRadius: "0px", mt: 0.8 }}>
                  <img src={user} />
                </IconButton>
              </Link>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex", gap: 1, pr: 1 }}>
                <Link to="/login">
                  <Typography
                    sx={{ fontWeight: 500, color: "#1a1a1a", fontSize: "13px" }}
                  >
                    Login
                  </Typography>
                </Link>
                <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                  /
                </Typography>
                <Link to="/register">
                  <Typography
                    sx={{ fontWeight: 500, color: "#1a1a1a", fontSize: "13px" }}
                  >
                    Signup
                  </Typography>
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Drawers;

const ListLoader = () => {
  return (
    <>
      <Skeleton variant="square" sx={{ width: "30%", mb: 1 }} />
      <Skeleton variant="square" sx={{ width: "70%", mb: 1 }} />
      <Skeleton variant="square" sx={{ width: "50%", mb: 1 }} />
    </>
  );
};
