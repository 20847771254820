import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import BlogCard from "../Cards/BlogCard";
import blogImg from "../../assets/newton.jpeg";
import blog1 from "../../assets/blog/MAMAKET_WELCOME.webp";
import blog2 from "../../assets/blog/A_JOURNEY_TO_RECONNECT.webp";
import blog4 from "../../assets/blog/THE_SPIRIT.jpeg";

import blog3 from "../../assets/blog/CULTURA_CONNECTION.webp";
import { Link } from "react-router-dom";


 export const newsData = [
    {
    img: blog4,
    title: "The Spirit of Mamaket",
    author:'Mamaket',
        date:'December 8, 2024',
    caption:
      "The spirit of Mamaket is embodied in the mantra: Make the Move.Mamaket is more than just a marketplace; she is the sophisticated, middle-aged woman who nurtures you outside of home. She caters to those who are homesick and in love with their culture, providing comfort and a sense of belonging.  ",
markdown:'TheSpirit'
  },
  {
    img: blog1,
    title: "Welcome to Mamaket – The Home for Cultural Connection",
     author:'Newton-Ray Ukwuoma, Founder & CEO, Mamaket',
         date:'November 9, 2024',
         markdown:'Welcome',
    caption:
      "Welcome to Mamaket! As the Founder and CEO, I am delighted to extend a warm greeting to you. Mamaket was born from a deep understanding of the immigrant experience and the longing to stay connected to one's cultural roots, no matter how far away home may be.Many of us have journeyed across continents, carrying only fragments of our heritage. ",

  },
  {
    img: blog2,
    title: "A Journey to Reconnect: The Birth of Mamaket",
    author:'Newton-Ray Ukwuoma, Founder & CEO, Mamaket',
    date:'November 7, 2024',
    caption:
      "While at 42 Silicon Valley in Fremont, California, I found myself grappling with a relentless longing for the sights, sounds, and flavors of my native Nigeria. This was more than just a craving—it was a profound homesickness that left me feeling disconnected and yearning for a piece of home.",
        markdown:'Journey',
  },
  {
    img: blog3,
    title: "Cultural Connection: The Mission of Mamaket",
    author:'Favour, Community Manager, Mamaket, Contributor',
        date:'November 5, 2024',
    caption:
      "At Mamaket, our mission is to make foreign goods and services accessible to all foreign nationals and culture enthusiasts. We aim to be the place where immigrants can feel at home, reconnect with their culture, and recreate a sense of belonging even when miles away from their native land. ",
markdown:'CulturalConnect'
  },


];

const NewsBlog = () => {
  return (
    <>
      <Box sx={{ py: 2, px: { lg: 0, md: 0, sm: 2, xs: 2 } }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: { lg: "24px", xs: "20px", md: "24px", sm: "20px" },
            fontWeight: 700,
          }}
        >
          News Updates
        </Typography>
        <Grid container spacing={4} sx={{ mt: 0 }}>
          {newsData.map(({ img, title, caption }, index) => (
            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
              <Link to={`/blog/${title}`}>
                <BlogCard
                  image={img}
                  title={title}
                  caption={caption}
                  link={`/blog/${title}`}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default NewsBlog;
