import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  IconButton,
  Badge,
  Popover,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloseOutlined } from "@mui/icons-material";
import emptyIcon from "../../assets/Icons/empty_history.svg";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const Notification = () => {
  const [data, setData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <StyledBadge badgeContent={0} color="error">
          <NotificationsIcon
            color="action"
            sx={{ color: "#000", fontSize: "20px" }}
          />
        </StyledBadge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: "300px", p: 2, boxShadow: "border-box" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: "#151515", fontSize: "12px", fontWeight: 500 }}
            >
              Notification
            </Typography>
            <Box>
              <Button
                disabled={!data}
                variant="contained"
                sx={{ fontSize: "10px", mr: 1, borderRadius: 4 }}
              >
                Mark all as read
              </Button>
              <IconButton onClick={handleClose}>
                <CloseOutlined sx={{ fontSize: "12px" }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            {!data || data?.length === 0 ? (
              <>
                <Box
                  sx={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={emptyIcon} />
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#151515",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    No Notifications
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default Notification;
