import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Rating,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import happyface from "../../../assets/Icons/happy-face 1.svg";
import decline from "../../../assets/Icons/declined.svg";
import { useMutation } from "@apollo/client";
import { RELEASE_FUNDS, DISPUTE_ORDER } from "../../../Utils/Mutations";
import Loader from "../../../Components/Common/Loader";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  boxSizing: "border-size",
};

const ReleaseFunds = ({ id }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () =>{

    setOpen2(true);
    setIsSuccess(false)
  } 
  const handleClose2 = () => setOpen2(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [value, setValue] = React.useState(0);
  const [comments, setComments] = useState("");
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [releaseFunds, { data, loading }] = useMutation(RELEASE_FUNDS);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleReleaseFunds = () => {
    releaseFunds({
      variables: {
        itemId: id,
        rating: value,
        comments: comments,
      },
    })
      .then((res) => {
        setIsSuccess(true);
      })
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  };

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(" ");
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };
  const maxFileSize = 1 * 1024 * 1024;
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file.size > maxFileSize) {
      alert("File size is too large. Please choose a smaller file.");
    } else {
      setSelectedFile(file);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURL = e.target.result;
          setSelectedFileURL(dataURL);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const [submitDispute] = useMutation(DISPUTE_ORDER);

  const handleDispute = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    try {
      const formData = new FormData();
          formData.append("files", selectedFile);
      // for (let i = 0; i < selectedFile.length; i++) {
      //   formData.append("files", selectedFile[i]);
      // }
      const { data } = await submitDispute({
        variables: {
          files: [selectedFile],
          payload: {
            itemId: id,
            reason,
            description: comments
          },
        },
      });
      setIsSuccess(true);
          setIsLoading(false)
    } catch (error) {
          setIsLoading(false)
      console.error("Error submitting dispute:", error);
    }
  };


  return (
    <>
      {(isLoading ||loading) && <Loader />}
      <Button
        onClick={handleOpen2}
        variant="outlined"
        fullWidth
        sx={{
          mt: 2,
          borderRadius: "100px",
          fontSize: "12px",
          textTransform: "initial",
          fontWeight: 600,
          py: 1.5,
        }}
      >
        No, DIspute
      </Button>
      <Button
        onClick={handleOpen}
        fullWidth
        sx={{
          bgcolor: "#5C3069",
          mt: 2,
          borderRadius: "100px",
          fontSize: "12px",
          textTransform: "initial",
          fontWeight: 600,
          py: 1.5,
        }}
        variant="contained"
      >
        Yes, Release Fund
      </Button>

      <Modal
        open={open}
        //         onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isSuccess ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={happyface} />
                <Typography sx={{ fontWeight: 700, color: "#333", mt: 2 }}>
                  Thank you
                </Typography>
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 400,
                    fontSize: "12px",
                    mt: 2,
                    lineHeight: "135%",
                    mx: 3,
                    textAlign: "center",
                  }}
                >
                  Thank you for shopping with Mamaket! We appreciate your
                  support and hope you enjoyed your experience.{" "}
                </Typography>
                <Button
                  onClick={handleClose}
                  sx={{ mt: 3, px: 4, borderRadius: "100px" }}
                  variant="contained"
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 700, color: "#333" }}>
                  Release Fund
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
            
                  }}
                >
                  Rating:
                </Typography>
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <TextField
                  fullWidth
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  multiline
                  rows={7}
                  placeholder="Describe your experience"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      border: "0px solid #ccc",
                      fontSize: "12px",
                    },
                  }}
                />

                <Button
                  onClick={handleReleaseFunds}
                  sx={{
                    mt: 3,
                    borderRadius: "100px",
                    fontSize: "12px",
                    textTransform: "initial",
                    fontWeight: 600,
                  }}
                  fullWidth
                  variant="contained"
                >
                  Release fund
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={open2}
        //         onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isSuccess ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={decline} />
                <Typography sx={{ fontWeight: 700, color: "#333", mt: 2 }}>
            Dispute Submitted
                </Typography>
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 400,
                    fontSize: "12px",
                    mt: 2,
                    lineHeight: "135%",
                    mx: 3,
                    textAlign: "center",
                  }}
                >
             Check your email for a prompt reply. 📧 Stay tuned as we provide you with the information you need. 📨
                </Typography>
                <Button
                  onClick={handleClose2}
                  sx={{ mt: 3, px: 4, borderRadius: "100px" }}
                  variant="contained"
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 700, color: "#333" }}>
                  Dispute Order
                </Typography>
                <IconButton onClick={handleClose2}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Select
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  displayEmpty
                  sx={{
                    borderRadius: "10px",
                    border: "0px solid #ccc",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                  fullWidth
                >
                  <MenuItem sx={{ fontSize: "12px", fontWeight: 500 }}>
                    Reason for dispute
                  </MenuItem>
                  <MenuItem
                    value="Bad Product"
                    sx={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    Bad Product
                  </MenuItem>
                  <MenuItem
                    value="Not delivered"
                    sx={{ fontSize: "12px", fontWeight: 500 }}
                  >
                    Not delivered
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  multiline
                  rows={7}
                  placeholder="Describe your experience"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      border: "0px solid #ccc",
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  }}
                />
                <Box sx={{ transition: "0.2s all linear" }}>
                  {" "}
                  {selectedFileURL ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          transition: "0.2s all linear",
                        }}
                      >
                        <Box
                          onClick={handleButtonClick}
                          sx={{
                            my: 2,
                            height: "80px",
                            border: "2px  dashed #E2E6EA",
                            borderRadius: "10px",
                            bgcolor: "#F7F9FB",
                            display: "grid",
                            placeItems: "center",
                            cursor: "pointer",
                            width: "50%",
                            background: `url('${selectedFileURL}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></Box>
                        <Box
                          onClick={handleButtonClick}
                          sx={{
                            my: 2,
                            height: "80px",
                            border: "2px  dashed #E2E6EA",
                            borderRadius: "10px",
                            bgcolor: "#F7F9FB",
                            display: "grid",
                            placeItems: "center",
                            cursor: "pointer",
                            width: "50%",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              lineHeight: "19px",
                            }}
                          >
                            Click to <br />
                            change selected picture
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box
                      onClick={handleButtonClick}
                      sx={{
                        my: 2,
                        height: "80px",
                        border: "2px  dashed #E2E6EA",
                        borderRadius: "10px",
                        bgcolor: "#F7F9FB",
                        display: "grid",
                        placeItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          lineHeight: "19px",
                        }}
                      >
                        Click to browse or
                        <br /> drag and drop your files
                      </Typography>
                    </Box>
                  )}
                </Box>

                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />

                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "20px",
                    mt: 3,
                    textAlign: "justify",
                  }}
                >
                  By clicking the dispute order button, we will investigate the
                  matter and provide a resolution as quickly as possible. We are
                  committed to ensuring your satisfaction and appreciate your
                  cooperation.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Button
                    // onClick={handleReleaseFunds}
                    sx={{
                      mt: 4,
                      borderRadius: "100px",
                      fontSize: "12px",
                      py: 2,
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDispute}
                    sx={{
                      mt: 4,
                      borderRadius: "100px",
                      fontSize: "12px",
                      py: 2,
                    }}
                    fullWidth
                    variant="contained"
                  >
                    Dispute
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ReleaseFunds;
