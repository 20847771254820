import {  Box, IconButton, InputLabel, Typography } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import img1 from '../../assets/Products/Web Images.png'
import usFlag from '../../assets/Flag-United-States-of-America.webp'
import noImage from '../../assets/no-image.png'
import CurrencyDisplay from "../Common/CurrencyDisplay";

const ProductsCard = ({ image, title, price, location,flag }) => {


  return (
    <>
      <Box
        sx={{
          border: "1px solid #F8F7F7",
          bgcolor: "#fff",
          borderRadius: "8px",
          cursor: "pointer",
          transition: "0.3s all linear",
          p: 1,
          "&:hover": {
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          },
          width:{md:'auto', sm:'150px', xs:'150px'}
        }}
      >
        <Box>
          {/* <Box sx={{display:'grid', placeItems:'center', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}}> */}
          <Box
            
        
            sx={{ width: "100%", height: 
            {xs:"120px", sm:'120px', md:'150px', 
            lg:'150px'} , 
          backgroundImage:`url('${image ? image : noImage}')`,
          backgroundSize:'cover',
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center',
borderRadius:'5px'
          }}
          />
          {/* </Box> */}

          <Box align="right" sx={{ mt: -3 }}>
            <IconButton
              disabled
              sx={{
                background: 'rgba(248, 247, 247, 0.4)',
                mr: 2,
                height: "20px",
                width: "25px",
                borderRadius: 1,
              }}
            >
              <img
                src={flag ? flag : usFlag}
                
                alt="country_flag"
                width="20px"
                height="15px"
              />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2, pb: 2 }}>
            <InputLabel
              variant="caption"
              sx={{ fontFamily: "14px", fontWeight: 500 }}
            >
              {title}
            </InputLabel>

            <Typography
           
              sx={{ color: "#1a1a1a", fontSize: "14px", fontWeight: 700 }}
            >
              &#36;{price.toLocaleString()}
            </Typography>
            {/* <CurrencyDisplay amount={price.toLocaleString()}/> */}
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", color: "#1a1a1a" }}
            >
              {location}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductsCard;
