import React, { useState, useEffect } from "react";
import Navbar from "../Common/Navbar";
import Newsletter from "../Common/Newsletter";
import Footer from "../Common/Footer";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
  ListItemButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Confirmation from "./Confirmation";
import Payment from "./Payment";
import Shipping from "./Shipping";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useQuery, gql } from "@apollo/client";
import useRemoveCarts from "../../Hooks/useRemoveCart";
import useGetCart from "../../Hooks/useGetCart";
import useGetServiceCart from "../../Hooks/useGetServiceCart";
import useIncreaseCarts from "../../Hooks/useIncrease";

const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;
const Checkout = () => {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart);
  const { handleRemoveCart, isLoading } = useRemoveCarts();
  const { handleGetCart, refetch } = useGetCart();
  const {
    handleGetCart: handleServiceCart,
    refetch: refecthCart,
  } = useGetServiceCart();
  const { handleIncreaseCart } = useIncreaseCarts();
  const [itemId, setItemId] = useState("");

  const { loading, data, error } = useQuery(GET_CART_ITEMS);
  const authCart = data?.cartItems;

  const removeCart = (item_id) => {
    setItemId(item_id);
    handleRemoveCart(item_id);
    handleGetCart();
  };

  useEffect(() => {
    setItemId(itemId);
  }, [itemId]);

  useEffect(() => {
    handleGetCart();
    handleServiceCart();
  }, []);

  const handleIncreaseQuantity = async (id, quantity) => {
    const newQuantity = quantity + 1;
    handleIncreaseCart(id, newQuantity);
    handleGetCart();
  };
  const handleDecreaseQuantity = async (id, quantity) => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      handleIncreaseCart(id, newQuantity);
      handleGetCart();
    }
  };

  const totalSubtotal = authCart.reduce(
    (total, item) => total + item.totalAmount,
    0
  );

  // For Tabs

  const [activeTab, setActiveTab] = useState(0);
  const routePayment = () => {
    setActiveTab(1);
  };
  const routeConfirmed = () => {
    setActiveTab(2);
  };

  // const [selectedAddress, setSelectedAddress] = useState();

  // const updateData = (newData) => {
  //   setSelectedAddress(newData);
  // };
  const tabs = [
    {
      label: " Shipping",
      content: (
        <Shipping
          // data={selectedAddress}
          routePayment={routePayment}
          // setSelectedAddress={updateData}
        />
      ),
    },
    {
      label: "Payment",
      content: <Payment routeConfirmed={routeConfirmed} />,
    },
    { label: "Confirmation", content: <Confirmation /> },
  ];

  return (
    <>
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          width: { lg: "85%", md: "85%", sm: "90%", xs: "96%" },
          mt: { lg: 15, md: 15, sm: 10, xs: 10 },
        }}
      >
        <Typography sx={{ color: "#333", fontSize: "36px", fontWeight: 500 }}>
          Check out
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ mt: 3, bgcolor: "#fff", p: { lg: 4, md: 4, sm: 0, xs: 1 } }}
        >
          <Grid item lg={7} xs={12}>
            <Box sx={{ border: "1px solid #e6e6e6", p: 1 }}>
              <Box
                sx={{
                  bgcolor: "#1a1a1a",
                  borderRadius: "6px",
                  overflow: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <List
                  sx={{
                    display: "flex",

                    alignItems: "center",
                    px: 2,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ color: "#999", width: "20%", textAlign: "center" }}
                  >
                    Cart
                  </Typography>{" "}
                  <ChevronRightIcon
                    sx={{ color: "#999", width: "3%", fontSize: "15px" }}
                  />
                  {tabs.map((tab, index) => (
                    <>
                      <Typography
                        key={index}
                        selected={activeTab === index}
                        // onClick={() => setActiveTab(index)}
                        sx={{
                          color: "#999",
                          width: "20%",
                          fontSize: "14px",
                    
                        display:'grid', 
                        placeItems:'center',
                          ...(activeTab === index && {
                            color: "#f8f7f7",
                            bgcolor: "#1a1a1a",
                          }),
                        }}
                      >
                        {tab.label}
                      </Typography>
                      <ChevronRightIcon
                        sx={{
                          color: "#999",width: "3%", fontSize: "15px" ,
                          display: index === 2 ? "none" : "block",
                        }}
                      />
                    </>
                  ))}
                </List>
              </Box>
              <Box sx={{ px: { lg: 3, md: 3, sm: 0, xs: 0 } }}>
                {tabs[activeTab].content}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={5}
            xs={0}
            sx={{
              display: { lg: "block", md: "block", sm: "none", xs: "none" },
            }}
          >
            <Box sx={{ bgcolor: "#f8f7f7", p: 2 }}>
              <Typography
                sx={{ color: "#333", fontWeight: 600, fontSize: "16px" }}
              >
                {`Your Cart (${authCart.length} items)`}
              </Typography>
              <Box sx={{ mt: 2, bgcolor: "#fff" }}>
                <Typography
                  sx={{
                    color: "#333",
                    px: 1,
                    borderBottom: "1px solid #e6e6e6",
                  }}
                >
                  Product
                </Typography>
                {authCart.map((product, index) => (
                  <>
                    <Box
                      key={index}
                      sx={{
                        borderBottom: "1px solid #e6e6e6",
                        py: 3,
                        px: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={product.product.images[0]}
                          sx={{ height: "100px", width: "100px" }}
                        />
                        <Box>
                          <Typography
                            sx={{ color: "#121212", fontWeight: 500 }}
                          >
                            {product.product.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#121212",
                              fontWeight: 300,
                              fontSize: "14px",
                            }}
                          >
                            &#36;{product.pricePerUnit.toLocaleString()}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              alignItems: "center",
                              width: "fit-content",
                              mt: 2,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                handleDecreaseQuantity(
                                  product.product._id,
                                  product.quantity
                                )
                              }
                            >
                              <RemoveIcon sx={{ fontSize: "13px" }} />
                            </IconButton>
                            <Typography>{product.quantity}</Typography>
                            <IconButton
                              onClick={() =>
                                handleIncreaseQuantity(
                                  product.product._id,
                                  product.quantity
                                )
                              }
                            >
                              <AddIcon sx={{ fontSize: "13px" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <IconButton onClick={() => removeCart(product._id)}>
                          <CloseIcon />
                        </IconButton>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          &#36;
                          {product.totalAmount.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
              <Box align="right">
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
                  >
                    Sub-Total
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
                  >
                    {" "}
                    &#36;{totalSubtotal}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Checkout;
