import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import EmptyData from "../../Components/Common/EmptyData";
import { RiSearch2Line } from "react-icons/ri";
import { useSnackbar } from "notistack";
import { GET_MY_ORDERS } from "../../Utils/Queries";
import { useQuery } from "@apollo/client";
import Loader from "../../Components/Common/Loader";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "../../Hooks/useQueryParams";

const ProductOrder = ({handleViewDetails}) => {
  const [productOrder, setProductOrder] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [filteredProduct, setFilteredProduct] = useState(null);

  const [searchParams, setSearchParams] = useState("");

    const navigate = useNavigate();
  const location = useLocation();

const {addQueryParam} = useQueryParams()
  const [value, setValue] = React.useState(0);
  const [isOverview, setIsOverView] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const { data: orders, loading: orderLoading, error: orderError } = useQuery(
    GET_MY_ORDERS
  );

  useEffect(() => {
    setProductOrder(orders?.getSellerOrders?.items);
  }, [orders]);

  useEffect(() => {
    setFilteredProduct(productOrder);
  }, [productOrder, orders]);

  const handleFilter = (filters) => {
    setFilteredProduct(productOrder);
    if (filters === "all") {
      setFilteredProduct(productOrder);
    } else {
      const filtered = productOrder?.filter((item) => item?.status === filters);

      setFilteredProduct(filtered);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchParams(value);
    const filtered = productOrder?.filter((item) =>
      item?.product?.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredProduct(filtered);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, filteredProduct?.length);
  const rowsText = `${startRow}-${endRow} of ${filteredProduct?.length}`;

  //   console.log(filteredProduct[0])
  return (
    <>
      {orderLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <Typography>Filter:</Typography>
          <TextField
            select
            size="small"
            sx={{ width: "120px", bgcolor: "#fff" }}
          >
            <MenuItem
              onClick={() => handleFilter("all")}
              value="All"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              All
            </MenuItem>
            <MenuItem
              onClick={() => handleFilter("Processing")}
              value="Processing"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Processing
            </MenuItem>
            <MenuItem
              onClick={() => handleFilter("Shipped")}
              value=" Shipped"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Shipped
            </MenuItem>
      
            <MenuItem
              onClick={() => handleFilter("Delivered")}
              value=" Delivered"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Delivered
            </MenuItem>
            <MenuItem
              onClick={() => handleFilter("Review & Release Fund")}
              value="  Review & Release Fund"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Review & Release Fund
            </MenuItem>
                  <MenuItem
              onClick={() => handleFilter("Refunded")}
              value="Refunded"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Refunded
            </MenuItem>
                  <MenuItem
              onClick={() => handleFilter("Cancelled")}
              value=" Cancelled"
              sx={{ fontWeight: 400, fontSize: "12px" }}
            >
              Cancelled
            </MenuItem>
          </TextField>
        </Box>
        <TextField
          value={searchParams}
          onChange={handleSearch}
          sx={{ width: "35%", bgcolor: "#fff" }}
          size="small"
          placeholder="Search Products"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RiSearch2Line />
              </InputAdornment>
            ),
            style: {
              bgcolor: "#fff",
            },
          }}
        />
      </Box>

      <Box
        sx={{
          mt: 2,
          bgcolor: "#fff",
          border: "1px solid #E4E4E7",
          borderRadius: "8px",
          boxSizing: "border-box",
          p: 0.5,
        }}
      >
        {!filteredProduct || filteredProduct?.length === 0 ? (
          <>
            <Box
              sx={{
                height: "400px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <EmptyData
                title="You have no order yet"
                label={"This is where you’ll see others."}
              />
            </Box>
          </>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead sx={{ bgcolor: "#FAFAFA" }}>
                  <TableRow>
                    <TableCell    sx={{
                          display: {
                                      lg: "table-cell",
                                      md: "table-cell",
                                      sm: "none",
                                      xs: "none",
                                    },
                              }}>ID</TableCell>
                    <TableCell>Product Title</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Price</TableCell>
                    {/* <TableCell align="center">Delivery Type</TableCell> */}
                    <TableCell align="center">Delivery Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProduct
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((order, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{        display: {
                                      lg: "table-cell",
                                      md: "table-cell",
                                      sm: "none",
                                      xs: "none",
                                    },}}>{order?._id}</TableCell>
                        <TableCell          sx={{
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                  }}>{order?.product?.name}</TableCell>
                        <TableCell align="center"          sx={{
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                  }}>{order?.quantity}</TableCell>
                        <TableCell align="center"          sx={{
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                  }}>
                          ${parseFloat(order?.amountPaid).toLocaleString()}
                        </TableCell>
                        {/* <TableCell align="center">34567jghgf</TableCell> */}
                        <TableCell align="center">
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "#CA8A04",
                                fontWeight: 500,
                                bgcolor: "#FEF9C3",
                                width: "fit-content",
                                textAlign: "center",
                                px: 1,
                                py: 0.5,
                                whiteSpace:'nowrap',
                                borderRadius: "3px",
                                ...((order?.status === "Processing" ||
                                  order?.status === "Paid") && {
                                  color: "#151515",

                                  bgcolor: "#E4E4E7",
                                }),
                                ...(order?.status === "Shipped" && {
                                  color: "#CA8A04",

                                  bgcolor: "#FEF9C3",
                                }),
                                ...(order?.status === "Delivered" || order?.status === "Review & Release Fund" && {
                                  color: "#22C55E",

                                  bgcolor: "#F0FDF4",
                                }),
                                ...((order?.status === "Refunded" ||
                                  order?.status === "Cancelled") && {
                                  color: "#EF4444",

                                  bgcolor: "#FCE8EC",
                                }),
                              }}
                            >
                              {order?.status}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                                                handleViewDetails(order);
                                                addQueryParam("order_id", order?._id)
                            }}
                            sx={{
                              border: "1px solid #E4E4E7",
                              fontSize: "10px",
                              whiteSpace:'nowrap'
                            }}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 3, py: 1 }}>
              <Grid
                item
                lg={4}
                md={4}
                xs={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#424242",
                    fontSize: "13px",
                  }}
                >
                  {rowsText}
                </Typography>
              </Grid>
              <Grid item lg={8} md={8} xs={8}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    size="small"
                    sx={{
                      color: "#424242",
                      fontSize: "13px",
                    }}
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    InputProps={{
                      style: {
                        fontSize: "13px",
                        // borderRadius: "8px",

                        // Replace with your desired font family
                      },
                    }}
                  >
                    <MenuItem sx={{ color: "#424242" }} value={5}>
                      5
                    </MenuItem>
                    <MenuItem sx={{ color: "#424242" }} value={10}>
                      10
                    </MenuItem>
                    <MenuItem sx={{ color: "#424242" }} value={25}>
                      25
                    </MenuItem>
                    <MenuItem sx={{ color: "#424242" }} value={50}>
                      50
                    </MenuItem>
                  </Select>
                  <Box
                    sx={{
                      borderLeft: "1px solid #424242",
                      pl: { md: 4, xs: 1 },
                      ml: { md: 3, xs: 1 },
                    }}
                  >
                    <IconButton
                      disabled={startRow === 1}
                      onClick={handleChangePageLeft}
                      sx={{
                        width: "30px",
                        height: "30px",
                        border: "1px solid #424242",
                        "&.Mui-disabled": {
                          border: "1px solid #4242421a",
                        },
                      }}
                    >
                      <ChevronLeftRoundedIcon />
                    </IconButton>
                    <IconButton
                      disabled={endRow === filteredProduct?.length}
                      onClick={handleChangePageRight}
                      sx={{
                        ml: 4,
                        width: "30px",
                        height: "30px",
                        border: "1px solid #424242",
                        "&.Mui-disabled": {
                          border: "1px solid #4242421a",
                        },
                      }}
                    >
                      <ChevronRightRoundedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default ProductOrder;
