import {
  Box,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useEffect, useState } from "react";


import PropTypes from "prop-types";
import Overview from "./Order/Overview";
import ProductOrder from "./Order/ProductOrder";
import ServiceOrder from "./Order/ServiceOrder";
import ServiceOverview from "./Order/ServiceOverview";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from '../Hooks/useQueryParams'

const datas = [
  {
    name: "Apple",
    reference: "34783h3b",
    quantityInStock: 4,
    price: 67,
    status: "Approved",
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Order = () => {
  const [productData, setProductData] = useState(datas);

  const [activeStep, setActiveStep ] = useState(0)

  const [openDrawer, setOpenDrawer] = useState(false);

  const [value, setValue] = React.useState(0);
  const [isOverview, setIsOverView] = useState(false);

  

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [OverviewData, setOverviewData] = useState(null);


  const handleViewDetails = (product) => {
setActiveStep(1)
    setOverviewData(product);
  };
  const handleViewServiceDetails = (service) => {
setActiveStep(2)
    setOverviewData(service);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const {removeQueryParam} = useQueryParams()

 

  useEffect(()=>{
      const searchParams = new URLSearchParams(location.search);
    const tabParams = searchParams.get("order_id");
    const typeParams = searchParams.get("type")
    const appointmentParams = searchParams.get("appointment_id")
if(tabParams){
handleViewDetails()
}
if(typeParams){
  setValue(1)
}
if(appointmentParams){
  handleViewServiceDetails()
}
  }, [location])
  return (
    <>
      <Box>

        {
          activeStep === 1 &&(
   <Overview
              handleBack={() => {
         setActiveStep(0)
                removeQueryParam("order_id");
              }}
              data={OverviewData}
            />

          )
        }
   {
    activeStep === 0 && (
         <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle1">My Orders</Typography>
                <Typography variant="body1">Monitor all orders here</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 3,
                bgcolor: "#F7F6F6",
                borderRadius: "8px",
                boxSizing: "border-box",
                px: 2,
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Products" {...a11yProps(0)} />

                  <Tab label="Services" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <ProductOrder handleViewDetails={handleViewDetails} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ServiceOrder
                handleViewServiceDetails={handleViewServiceDetails}
                />
              </CustomTabPanel>
            </Box>
          </>
    )
   }

   {
    activeStep === 2 && (
      <>
    <ServiceOverview 
          handleBack={() => {
         setActiveStep(0)
                removeQueryParam("appointment_id");
              }}
              data={OverviewData}
    />
      </>
    )
   }
      </Box>
    </>
  );
};

export default Order;
