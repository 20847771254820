import React from 'react';
import emptyIcon from '../../assets/Icons/empty.svg'
import { Box, Typography } from '@mui/material';

const EmptyData = ({title, label}) => {
  return (
 <>
 <Box sx={{display:'flex', alignItems
                        :'center', justifyContent:'center', flexDirection:'column'
 }}>
                        <img src={emptyIcon

                        } width={100}/>
                        <Typography sx={{mt:2, }} variant='subtitle1'>{title}</Typography>
                        <Typography variant='body2'>{label}</Typography>
 </Box>
 </>
  )
}

export default EmptyData