import { Box, Typography,  Divider, IconButton } from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChangePassword from "../Components/Security/ChangePassword";
import ChangePin from "../Components/Security/ChangePin";


const Security = () => {
  return (
    <>
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box>
          <Typography sx={{ color: "#333", fontWeight: 500, fontSize: "16px" }}>
            Security
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              border: "1px solid #F2F2F2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 12px",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#333", fontSize: "14px" }}
            >
              Change Password
            </Typography>
            <ChangePassword />
          </Box>
          <Box
            sx={{
              border: "1px solid #F2F2F2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 12px",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#333", fontSize: "14px" }}
            >
              Change Transaction PIN
            </Typography>
            <ChangePin/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Security;
