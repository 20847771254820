import React, { useEffect, useState } from "react";

// Mui Components
import { Box, Button, Checkbox, Grid, Typography,  useTheme , useMediaQuery, IconButton } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

// Child components
import Navbar from "../../Components/Common/Navbar";
import BreadCrumbs from "../../Components/Common/BreadCrumbs";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProductData } from "../../assets/Data/ProductData";
import ProductsCard from "../../Components/Cards/ProductsCard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { LuSettings2 } from "react-icons/lu";

// Router
import { Link, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_CATEGORY_PRODUCT } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import {} from "@emotion/react";

const CategoryView = () => {
const theme = useTheme()
const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const {id, name } = useParams()
  const [products, setProducts] = useState(null)

  const [getCategoryProduct, {loading, data, error}] =useLazyQuery(GET_CATEGORY_PRODUCT)

  useEffect(()=>{
    getCategoryProduct({
      variables: {
        categoryId: id,
      },
    }) .then((res)=>{

      setProducts(res.data?.fetchProductsByCategory.items);
    }).catch((err)=>{

    });
  }, [])





  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          width: "90%",
          mt: { lg: 15, md: 15, sm: 10, xs: 9 },
        }}
      >
        <Box>
          <Box
            sx={{
              p: 2,
              bgcolor: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: 500,
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                }}
              >
                Home
              </Typography>
            </Link>
            <NavigateNextIcon />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Category
            </Typography>
            <NavigateNextIcon />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 1, bgcolor: "#fff" }}>
          <Box sx={{ p: 1 }}>
            {/* <Typography
              sx={{
                fontSize: "16px",
                color: "#1a1a1a",
                fontWeight: 500,

              }}
            >
              Category
            </Typography> */}

            <Grid container sx={{ mt: 0 }} spacing={3}>
              {/* <Grid item lg={2.5} sm={12} xs={12}>
                {isMobile ? (
                  <>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", alignItems:'center' }}
                    >
                      <Typography sx={{ color: "#000", fontWeight: 400 }}>
                        All Products
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <Typography sx={{ color: "#000", fontWeight: 400 }}>
                          Filter
                        </Typography>
                        <IconButton sx={{color:'#000', fontSize:'14px'}}>

                        <LuSettings2 />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ border: "1px solid #e6e6e6", padding: "12px" }}>
                      <Typography
                        sx={{
                          color: "#1b1b1b",
                          fontWeight: 600,
                          fontSize: "24px",
                        }}
                      >
                        {name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1,
                          fontSize: "12px",
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label="All"
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label="Sweater"
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label="Sweater & Blouses"
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label="Sweater & Blouses"
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label="Sweater & Blouses"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          sx={{
                            color: "#1b1b1b",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Sort by
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <FormControlLabel
                            control={<Checkbox size="small" />}
                            label="Price, low to high"
                          />
                          <FormControlLabel
                            control={<Checkbox size="small" />}
                            label="Price, low to high"
                          />
                        </Box>
                      </Box>

                      <Box sx={{ mt: 3 }}>
                        <Button sx={{ color: "#FF3347" }}>Clear all</Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid> */}
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <Box>
                  <Box sx={{ pb: 3, pl:1 }}>
                    <Typography
                      sx={{
                        color: "#1b1b1b",
                        fontWeight: 600,
                        fontSize: "24px",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {!products || products.length === 0 ? (
                      <>
                        <Box
                          sx={{
                            height: "400px",
                            display: "grid",
                            placeItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ fontWeight: 700 }}>
                            No products available
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        {products.map((item, _id) => (
                          <Grid item lg={3} md={3} sm={6} xs={6}>
                            <Link to={`/product-view/${item._id}`}>
                              <ProductsCard
                                image={item.images[0]}
                                price={item.price}
                              />
                            </Link>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default CategoryView;
