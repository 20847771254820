import React, { useState, useEffect } from "react";
import Navbar from "../Components/Common/Navbar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Grid, List, ListItemButton, Typography } from "@mui/material";
import emptyChat from '../assets/chat-empty.svg'
import infoIcon from '../assets/Icons/information.svg'
import ChatList from "./ChatList";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";






const Chat = () => {
  // const {socket} = useSocket()
  const [chat, setChat] = useState([])
  const [isConnected, setIsConnected] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
  const router = useLocation();
  


  return (
    <>
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          width: {lg:"85%", md:'85%', sm:'95%', xs:'100%'},
          bgcolor: "#fff",
          mt:{lg: 16, md:16, sm:10, xs:8},
          mb: 3,
          height: "550px",
        }}
      >
        <Grid
          container
          sx={{ p:2, display: "flex", height: "100%" }}
          columnSpacing={2}
        >
          <Grid item lg={3} md={3} sm= {12} xs={12} sx={{ height: "100%" }}>
            <Box sx={{ borderRight:{lg: "1px solid #f2f2f2", md:"1px solid #f2f2f2", sm:'none', xs:'none'}, height: "100%" }}>
              <Typography
                sx={{ fontSize: "24px", fontWeight: 500, color: "#333", mb:2 }}
              >
                Chat
              </Typography>
            
                  <ChatList />
        
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={emptyChat} alt="emptyChat" />
                  </Box> */}
            
            </Box>
          </Grid>
          <Grid item lg={9} xs={0} sx={{display:{lg:'block', md:'block', sm:'none', xs:'none' }}}>
            {router.pathname !== "/chat" ?  (
              <>
         <Outlet/>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    bgcolor: "#F8F7F7",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      width: "45%",
                      borderRadius: "10px",
                      p: 2,
                    }}
                  >
                    <img src={infoIcon} alt="info" />
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "12px", color: "#000" }}>
                        Mamaket strictly prohibits any actions of offsite
                        trading, please refer to this guide
                      </Typography>
                      <ol>
                        <li>
                          <Typography sx={{ fontSize: "12px", color: "#000" }}>
                            {" "}
                            Transaction made outside Mamaket is not protected
                            under Protection. Do not take the risk.
                          </Typography>
                        </li>
                        <li>
                          <Typography sx={{ fontSize: "12px", color: "#000" }}>
                            {" "}
                            All communication and correspondence between the
                            seller and the buyer must be done through the
                            Mamaket Chat Application.
                          </Typography>
                        </li>
                        <li>
                          <Typography sx={{ fontSize: "12px", color: "#000" }}>
                            {" "}
                            Seller is not allowed to provide offsite trade
                          </Typography>
                        </li>
                        <li>
                          <Typography sx={{ fontSize: "12px", color: "#000" }}>
                            {" "}
                            All the chat record out of Mamaket will not be
                            accepted as evidence for any of the dispute or
                            claim!
                          </Typography>
                        </li>
                      </ol>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Chat;
