import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
  TableContainer,
} from "@mui/material";
import verifyImg from "../../assets/Icons/verify 1.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import thumbnail from "../../assets/Products/Thumbnail Images.png";
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Newsletter from "../Common/Newsletter";
import Footer from "../Common/Footer";
import emptyCart from "../../assets/Icons/emptyCart.svg";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import Loader from "../Common/Loader";
import { GoDotFill } from "react-icons/go";
import { useSnackbar } from "notistack";

import CustomTable from "../Common/CustomTable";
import CustomMobileCart from "../Common/CustomMobileCart";
import useRemoveCarts from "../../Hooks/useRemoveCart";
import useGetServiceCart from "../../Hooks/useGetServiceCart";
import { SERVICE_CHECKOUT } from "../../Utils/Mutations";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 400, xs: 350 },
  bgcolor: "background.paper",
  boxSizing: "border-box",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;
const GET_SERVICE_CART = gql`
  query GetServiceItems {
    serviceCarts @client
  }
`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Cart = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleServiceCart();
  };
  const [value, setValue] = React.useState(0);
  const [itemId, setItemId] = useState("");
  const {
    handleGetCart: handleServiceCart,
    refetch: refecthCart,
  } = useGetServiceCart();
  
  const { handleRemoveCart, isLoading } = useRemoveCarts();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const { loading, data, error } = useQuery(GET_CART_ITEMS);
  const { data: serviceCart } = useQuery(GET_SERVICE_CART);

  const renderedCart = data?.cartItems;
  const renderedServiceCart = serviceCart?.serviceCarts;

  const totalSubtotal = renderedCart.reduce(
    (total, item) => total + item.totalAmount,
    0
  );
  const totalSubtotal2 = renderedServiceCart?.reduce(
    (total, item) => total + item.totalPrice,
    0
  );

  const removeCart = (item_id) => {
    setItemId(item_id);
    handleRemoveCart(item_id);
    handleServiceCart();
  };

  function formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const [
    serviceCheckout,
    { data: message, loading: serviceLoading },
  ] = useMutation(SERVICE_CHECKOUT);

    const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleServiceCheckout = () => {
    serviceCheckout()
      .then((res) => {
        handleOpen();
        handleServiceCart();
      })
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  };

  const location = useLocation();

    useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParams = searchParams.get("tab");

    if (tabParams === "service") {
      setValue(1);

    } else {
      setValue(0);
    }
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      {serviceLoading && <Loader />}
      <Navbar />
      {isMobile ? (
        <>
          <Box
            sx={{
              pt: 12,
              margin: "0 auto",
              width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
              mb: 2,
              px: 2,
              boxSizing: "border-box",
              bgcolor: "#fff",
            }}
          >
            <Typography
              sx={{ fontSize: {lg:"36px", md:'36px', sm:'26px', xs:'24px'}, fontWeight: 700, color: "#333" }}
            >
              Cart
            </Typography>

            <Box sx={{ bgcolor: "#fff", p: {lg:2,md:2, sm:0, xs:0}, mt: 0 }}>
              <Box sx={{}}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={
                        <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
                          ({renderedCart?.length})
                        </Typography>
                      }
                      iconPosition="end"
                      label="Product"
                      sx={{  width: "50%", fontSize:'12px', mb:-2.5 }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={
                        <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
                          ({renderedServiceCart?.length})
                        </Typography>
                      }
                      iconPosition="end"
                      label="Service"
                      sx={{  width: "50%", fontSize:'12px', mb:-2.5}}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>

                {/* Cart for Products */}
                <CustomTabPanel value={value} index={0}>
                  {renderedCart.length === 0 ? (
                    <Box
                      sx={{
                        bgcolor: "#fff",
                        p: 4,
                        mt: 3,
                        display: "grid",
                        placeItems: "center",
                        height: "50vh",
                      }}
                    >
                      <img src={emptyCart} />
                    </Box>
                  ) : (
                    <>
                      <CustomMobileCart
                        renderedCart={renderedCart}
                        loading={loading}
                      />
                    </>
                  )}
                </CustomTabPanel>

                {/* Mobile Service cart */}

                <CustomTabPanel value={value} index={1}>
                  {renderedServiceCart.length === 0 ? (
                    <Box
                      sx={{
                        bgcolor: "#fff",
                        p: 4,
                        mt: 3,
                        display: "grid",
                        placeItems: "center",
                        height: "50vh",
                      }}
                    >
                      <img src={emptyCart} />
                    </Box>
                  ) : (
                    <>
                      <Box>
                        {renderedServiceCart.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              pb: 2,
                              borderBottom: "0.5px solid #e6e6e6",
                              mb: 2,
                            }}
                          >
                            <Box sx={{ display: "flex", columnGap: 2 }}>
                              <Avatar
                                variant="square"
                                src={item?.service?.images[0]}
                                sx={{ width: "50px", height: "50px" , borderRadius:'8px'}}
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#121212",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight:'16px'
                                  }}
                                >
                                  {item?.service?.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "12px",
                                    fontWeight:400,
                                  }}
                                >
                                  &#36;
                                  {(item?.totalPrice).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "10px", fontWeight: 700 }}
                                >
                                  {formatDate(item?.scheduledDate)} /
                                  {item?.scheduledTime}
                                </Typography>
                                <Box></Box>
                              </Box>
                            </Box>
                            <IconButton
                              sx={{
                                color: "#FF4C5E",
                                height: "50px",
                                width: "50px",
                              }}
                              onClick={() => removeCart(item._id)}
                            >
                              <img
                                src={trash}
                                alt="trash_icon"
                                style={{ marginRight: "3px" }}
                              />{" "}
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{ width: "100%", bgcolor: "#fff", mt: 3 }}>
                        <Table>
                          <TableHead>
                            <TableCell align="right"></TableCell>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Subtotal</TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  color: "#1a1a1a",
                                  fontWeight: 500,
                                  
                                }}
                              >
                                $
                                {totalSubtotal2.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {isAuthenticated ? (
                          <>
                            <Button
                              onClick={handleServiceCheckout}
                              fullWidth
                              sx={{ mt: 3, borderRadius: "100px" }}
                              variant="contained"
                            >
                              Proceed To CheckOut
                            </Button>
                          </>
                        ) : (
                          <>
                            <Link to="/login">
                              <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={{ mt: 3, borderRadius: "100px" }}
                              >
                                Sign in to proceed
                              </Button>
                            </Link>
                            <Typography
                              sx={{ textAlign: "center", fontSize: "12px" }}
                            >
                              New to Mamaket?{" "}
                              <Link to="/register">Sign up </Link>
                            </Typography>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </CustomTabPanel>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              pt: 15,
              margin: "0 auto",
              width: { lg: "85%", md: "85%", sm: "90%", xs: "100%" },
              mb: 4,
            }}
          >
            <Typography
              sx={{ fontSize: "36px", fontWeight: 600, color: "#333" }}
            >
              Cart
            </Typography>

            <Box sx={{ bgcolor: "#fff", p: 4, mt: 3 }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={
                        <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                          ({renderedCart?.length})
                        </Typography>
                      }
                      iconPosition="end"
                      label="Product"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={
                        <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                          ({renderedServiceCart?.length})
                        </Typography>
                      }
                      iconPosition="end"
                      label="Service"
                      sx={{}}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                {/* Desktop Product Cart */}
                <CustomTabPanel value={value} index={0}>
                  {!renderedCart || renderedCart.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          bgcolor: "#fff",
                          p: 4,
                          mt: 3,
                          display: "grid",
                          placeItems: "center",
                          height: "50vh",
                        }}
                      >
                        <img src={emptyCart} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <CustomTable
                        renderedCart={renderedCart}
                        loading={loading}
                      />
                    </>
                  )}
                </CustomTabPanel>

                {/* Service Cart */}

                <CustomTabPanel value={value} index={1}>
                  {!renderedServiceCart || renderedServiceCart.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          bgcolor: "#fff",
                          p: 4,
                          mt: 3,
                          display: "grid",
                          placeItems: "center",
                          height: "50vh",
                        }}
                      >
                        <img src={emptyCart} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{  fontWeight: 500 }}
                            >
                              Service
                            </TableCell>
                            <TableCell
                              sx={{  fontWeight: 500 }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{  fontWeight: 500 }}
                            >
                              Scheduled Date/Time
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {renderedServiceCart?.map((item, index) => (
                            <TableRow>
                              <TableCell>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                  <Avatar
                                    variant="square"
                                    src={item?.service?.images[0]}
                                    sx={{ width: "70px", height: "70px" }}
                                  />
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#121212",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {item?.service?.title}
                                    </Typography>
                                    {/* <Button
                                      sx={{ color: "#FF4C5E" }}
                                      onClick={() => removeCart(item._id)}
                                    >
                                      <img
                                        src={trash}
                                        alt="trash_icon"
                                        style={{ marginRight: "3px" }}
                                      />{" "}
                                      Remove
                                    </Button> */}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{  fontWeight: 700 ,}}
                              >{`$ ${item?.totalPrice.toLocaleString()}`}</TableCell>
                              <TableCell
                                align="right"
                                sx={{  fontWeight: 500 }}
                              >
                                {formatDate(item?.scheduledDate)}/
                                {item?.scheduledTime}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Box align="right">
                        <Box
                          sx={{
                            width: "30%",
                            bgcolor: "#F8F7F7",
                            mt: 3,
                            px: 2,
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableCell
                                sx={{
                                  fontSize: "15px",
                                  
                                  fontWeight: 500,
                                  color: "#333",
                                }}
                              >
                                Summary
                              </TableCell>
                              <TableCell align="right"></TableCell>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Subtotal</TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "#1a1a1a",
                                    fontWeight: 500,
                                    
                                  }}
                                >
                                  $
                                  {totalSubtotal2.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          {isAuthenticated ? (
                            <>
                              <Button
                                onClick={handleServiceCheckout}
                                fullWidth
                                sx={{ mt: 3, borderRadius: "100px" }}
                                variant="contained"
                              >
                                Proceed To CheckOut
                              </Button>
                            </>
                          ) : (
                            <>
                              <Link to="/login">
                                <Button
                                  variant="contained"
                                  fullWidth
                                  size="large"
                                  sx={{ mt: 3, borderRadius: "100px" }}
                                >
                                  Sign in to proceed
                                </Button>
                              </Link>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "12px" }}
                              >
                                New to Mamaket?{" "}
                                <Link to="/register">Sign up </Link>
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                </CustomTabPanel>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Newsletter />
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box align="center">
            <img src={verifyImg} />
            <Typography
              id="modal-modal-title"
              sx={{ mt: 4, color: "#333", fontWeight: 500, fontSize: "12px" }}
            >
              {message?.checkoutMyCartItems?.message}
            </Typography>
            <Link to="/account/orders">
              <Button variant="contained" sx={{ mt: 3 }}>
                view my orders
              </Button>
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Cart;
