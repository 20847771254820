import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAvDpkP40Q-7U9X8S1-wIFxKOFtKWugOZ4",
  authDomain: "mamaket-4e994.firebaseapp.com",
  projectId: "mamaket-4e994",
  storageBucket: "mamaket-4e994.appspot.com",
  messagingSenderId: "521785778635",
  appId: "1:521785778635:web:a04939dcba5c19c63f564e",
  measurementId: "G-MLHRSC47G5"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging };