import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const OfferCards = ({ image, title, caption }) => {
  return (
    <>
      <Box>
        <Avatar
          variant="square"
          sx={{ width: "100%", height: {lg:"230px", md:'230px', md:'200px', xs
            :'150px'}, borderRadius:'5px 5px 0px 0px'
           }}
          src={image}
        />
        <Box sx={{ mt: 1 }}>
          <Typography
            variant="caption"
            sx={{ fontSize: "16px", fontWeight: 700 }}
          >
            {title}
          </Typography>
          <br />
          <Typography
            variant="caption"
            sx={{ fontSize: "12px", fontWeight: 400 }}
          >
            {caption}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default OfferCards;
