import React, {useState} from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';


const SingleImageUploader = () => {
                          const [image, setImage] = useState(null); // Store only one image

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]; // Take only the first file
    setImage(
      Object.assign(file, {
        preview: URL.createObjectURL(file), // Generate preview for the image
      })
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept only image files
    multiple: false, // Allow only one image
  });
  return (
 <>
 <Box
sx={{bgcolor:'#fff', height:'90px', border:'1px dashed #EBDCEF', borderRadius:'10px', mt:1, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', ...(image && {
  background:`linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('${image?.preview}')`,
  backgroundSize:'cover'
})}}
 {...getRootProps()}
>
<input {...getInputProps()} />
 {isDragActive ? (
          <Typography sx={{color:'#151515', fontSize:'12px', fontWeight:500}}>Drop the images here...</Typography>
        ) : (
          <>
        <Typography sx={{color:'#151515'}}>Drop your files here or <b style={{color:'#7B408C'}}>click to upload</b></Typography>
        <Typography sx={{fontSize:'12px'}}>Maximum size: 5 MB</Typography>
        </>
        )}
</Box>
 </>
  )
}

export default SingleImageUploader