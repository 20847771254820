import React from "react";
import FooterNavBar from "../Components/Common/FooterNavBar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <FooterNavBar />
      <Box
        sx={{
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 500, color: "#333" }}>
          <strong> PRIVACY POLICY</strong>
        </Typography>
        <Typography sx={{ fontWeight: 700 }}>
          Last updated January 01, 2024
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 2, textAlign:'justify' }}
        >
          This privacy notice for Mamaket Inc. (doing business as Mamaket) (
          <strong>"we," "us,"</strong>
          or <strong>"our"</strong>), describes how and why we might collect,
          store, use, and/or share ("process") your information when you use our
          services ("Services"), such as when you:
          <br />
          <ul style={{ listStyleType: "square" }}>
            <li>
              Visit our website at{" "}
              <a href="https://www.mamaket.com">
                {" "}
                <span
                  style={{
                    fontWeight: 400,
                    color: "#333",
                    textDecoration: "underline",
                  }}
                >
                  https://www.mamaket.com
                </span>
              </a>
              , or any website of ours that links to this privacy notice
            </li>
            <li>
              Download and use our mobile application (Mamaket Mobile), or any
              other application of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <br />
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          <a
            href="mailto:myprivacy@mamaket.com"
            style={{
              fontWeight: 400,
              color: "#333",
              textDecoration: "underline",
            }}
          >
            myprivacy@mamaket.com
          </a>
          <br />
        </Typography>
        <Typography
          sx={{ fontSize: "24px", fontWeight: 500, color: "#333", my: 3 }}
        >
          <strong>SUMMARY OF KEY POINTS</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#333",
            mt: 2,
            fontStyle: "italic",
          }}
        >
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our{" "}
          <strong>table of contents</strong> below to find the section you are
          looking for.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about personal information you disclose to us.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong>Do we process any sensitive personal information?</strong> We
          may process sensitive personal information when necessary with your
          consent or as otherwise permitted by applicable law. Learn more about
          sensitive information we process.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong>Do we receive any information from third parties?</strong> We
          may receive information from public databases, marketing partners,
          social media platforms, and other outside sources. Learn more about
          information collected from other sources..
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong> How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about how we process your
          information.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong>
            {" "}
            In what situations and with which parties do we share personal
            information?{" "}
          </strong>
          We may share information in specific situations and with specific
          third parties. Learn more about when and with whom we share your
          personal information.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong>How do we keep your information safe?</strong>
          We have organizational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your
          information safe.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong> What are your rights?</strong>
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information. Learn more about your privacy rights.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          <strong> How do you exercise your rights? </strong>
          The easiest way to exercise your rights is by submitting a data
          subject access request, or by contacting us. We will consider and act
          upon any request in accordance with applicable data protection laws.
          <br />
          <br />
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#333" }}>
          Want to learn more about what we do with any information we collect?
          Review the privacy notice in full.
          <br />
          <br />
        </Typography>
        <Typography
          sx={{ fontSize: "24px", fontWeight: 500, color: "#333", my: 3 }}
        >
          <strong>TABLE OF CONTENTS</strong>
        </Typography>
        <Typography>
          <ol>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                WHAT INFORMATION DO WE COLLECT?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW DO WE PROCESS YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                DO WE COLLECT INFORMATION FROM MINORS?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                DO WE MAKE UPDATES TO THIS NOTICE?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
            </li>
            <li>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#333",
                  fontWeight: 400,
                }}
              >
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
          </ol>
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
