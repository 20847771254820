import { Box, Divider, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import React, {useState} from 'react'

import SingleImageUploader from '../DropZone/SingleImageUploader';

const Identity = () => {



  return (
  <>
<Box>
      <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400 }}
        >
      Select ID Type
        </InputLabel>
        <TextField
        select
          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        >

<MenuItem>Driver License </MenuItem>
<MenuItem>Driver License </MenuItem>
<MenuItem>Driver License </MenuItem>

          </TextField>

          <Divider sx={{my:2}}/>
                <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400  }}
        >
 Front Picture of your ID
        </InputLabel>
<SingleImageUploader/>
                <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400, mt:1.5 }}
        >
Back Picture of your ID
        </InputLabel>
<SingleImageUploader/>
</Box>
  </>
  )
}

export default Identity