import { Box, Grid, Typography ,  useMediaQuery,useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ProductsCard from "../Cards/ProductsCard";
import { useQuery } from "@apollo/client";
import {
  GET_TOPSELLERS_PRODUCTS,
  GET_TOPSELLERS_PRODUCTS_AUTH,
} from "../../Utils/Queries";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";

const TopSeller = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const { loading, data } = useQuery(
    isAuthenticated ? GET_TOPSELLERS_PRODUCTS_AUTH : GET_TOPSELLERS_PRODUCTS
  );

  const topSellerProducts = isAuthenticated
    ? data?.getMarketPlaceBestSellingProductListing
    : data?.getMarketPlaceBestSellingProductListingForUnAuthenticatedUser;

      const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#f6f7f8", p: 2, pb: 4, my: 3,  boxShadow:' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', borderRadius:'5px', }}>
        <Typography
          variant="subtitle2"
          sx={{
           fontSize: {
              lg: "24px",
              xs: "16px",
              md: "24px",
              sm: "18px",
      
            },
            fontWeight: 700,
          }}
        >
          Top Sellers
        </Typography>
        <Box>
 {
  isMobile ?  (
    <>
        <Box className="hide_scrollbar" sx={{display:'flex', overflow:'scroll', flexGrow:'initial', columnGap:2, mt:3}}>
               {topSellerProducts?.slice(0, 10).map((product, index) => (
                   <Box sx={{width:'200px', }} key={product._id}>
                <Link to={`/product-view/${product._id}`} key={index}>
                  <ProductsCard
                    image={product.images && product.images[0]}
                    price={product.price.toLocaleString()}
                  />
                </Link>
              </Box>
            ))}
        </Box>
    
    </>
  ):(
             <Grid container spacing={2} sx={{ mt: 1 }}>
            {topSellerProducts?.slice(0, 10).map((product, index) => (
              <Grid item lg={2.4} md={3} sm={4} xs={6} key={index}>
                <Link to={`/product-view/${product._id}`} key={index}>
                  <ProductsCard
                    image={product.images && product.images[0]}
                    price={product.price.toLocaleString()}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
  )
 }
        </Box>
      </Box>
    </>
  );
};

export default TopSeller;
