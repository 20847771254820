import { GoArrowLeft } from "react-icons/go";
import {
  Box,
  IconButton,
  Typography,
  Chip,
  Avatar,
  Grid,
  Modal,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useLocation, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import timeIcon from "../../assets/Icons/time.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TbMessageShare } from "react-icons/tb";

import { v4 as uuidv4 } from "uuid";
import { GET_SINGLE_MY_ORDER, GET_SINGLE_ORDER } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import { CANCEL_ORDER, ORDER_DELIVERED, ORDER_SHIPPED, PROCESS_ORDER } from "../../Utils/Mutations";
import declinedImg from "../../assets/Icons/declined 2.svg";
import Lottie from "react-lottie";
import { IoChatbubblesOutline } from "react-icons/io5";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
// import ReleaseFunds from "./ReleaseFunds";
import { useSnackbar } from "notistack";
import successAnime from "../../assets/anime/HxXzwE6KaZ.json";
import socket from "../../Utils/socket";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  boxSizing: "border-box",
  p: 4,
};

const Overview = ({ handleBack }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [success, setSuccess] = useState(false);
  const [rejectReasons, setRejectReasons] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const orderParams = searchParams.get("order_id");

  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const handleClose = () => setOpen(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setRejectReasons(event.target.value);
  };

  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "iiii, do 'of' MMMM yyyy, h:mm a");
    return formattedDate;
  };

  function formatDate3(dateString) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/\//g, " - ");
    const formattedTime = date.toLocaleTimeString("en-US", options);
    return `${formattedTime}`;
  }

  const [getSingleOrder, { loading, data, refetch }] = useLazyQuery(
    GET_SINGLE_MY_ORDER
  );
  useEffect(() => {
    getSingleOrder({
      variables: {
        id: orderParams,
      },
    })
      .then((res) => {})
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  }, [orderParams]);



  const {
    amountPaid,
    createdAt,
    quantity,
    product,
    statusHistory,
    _id,
    status,
    pricePerUnit,
  } = data?.getSellerOrders?.items[0] || [];

  useEffect(() => {
    if (statusHistory) {
      const lastIndex = statusHistory.length - 1;

      if (statusHistory.length === 1) {
        setActiveStep(0);
      } else if (statusHistory[lastIndex].status === "Processing") {
        setActiveStep(1);
      } else if (statusHistory[lastIndex].status === "Shipped") {
        setActiveStep(2);
      } else if (statusHistory[lastIndex].status === "Delivered") {
        setActiveStep(3);
      } else if (statusHistory[lastIndex].status === "Review & Release Fund") {
        setActiveStep(4);
      } else if (
        statusHistory[lastIndex].status === "Canceled" ||
        status === "Refunded"
      ) {
        setIsCancelled(true);
        setActiveStep(lastIndex - 1);
      }
    }
  }, [statusHistory]);

  const [cancelOrder, { loading: cancelLoad, data: cancelData }] = useMutation(
    CANCEL_ORDER
  );

  const [processOrder] = useMutation(PROCESS_ORDER);
  const [shipOrder] = useMutation(ORDER_SHIPPED);
  const [deliveredOrder] = useMutation(ORDER_DELIVERED);

  const handleCancelOrder = () => {
    setSuccess(true);
    setIsLoading(true);
    cancelOrder({
      variables: {
        itemId: _id,
        reason: rejectReasons,
        description,
      },
    })
      .then((res) => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert(`${err.message}`, "error");
      });
  };


  const handleProcessOrder = async () => {
    setIsLoading(true);
    await processOrder({
      variables: {
        orderIds: _id,
      },
    })
      .then((res) => {
        setIsLoading(false);
        refetch();

        handleAlert(`Order successfully updated`, "success");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert(`${err.message}`, "error");
      });
  };


  const handleShipOrder = async () => {
    setIsLoading(true);
    await shipOrder({
      variables: {
        orderIds: _id,
      },
    })
      .then((res) => {
        setIsLoading(false);
        refetch();

        handleAlert(`Order successfully updated`, "success");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert(`${err.message}`, "error");
      });
  };


  const handleDeliveredOrder = async () => {
    setIsLoading(true);
    await deliveredOrder({
      variables: {
        orderIds: _id,
      },
    })
      .then((res) => {
        setIsLoading(false);
        refetch();

        handleAlert(`Order successfully updated`, "success");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert(`${err.message}`, "error");
      });
  };

  //  CHAT WITH BUYER =========================================================================================================
  const [uniqueId, setUniqueId] = useState(null);
  const [chatMessage, setChatMessage] = useState("")
  const [openChat, setOpenChat] = useState(false);
  const [chatSent, setChatSent] = useState(false)
  const handleCloseChat = () =>{
    setOpenChat(false);
    setChatSent(false)
  }


    const generateUniqueId = () => {
    const newUniqueId = uuidv4();
    setUniqueId(newUniqueId);
  };

  useEffect(() => {
    generateUniqueId();
  }, [chatMessage]);

  const MAKE_OFFERS = "receivedMessage";
  const EXCEPTION = "exception";
  const INITIATECHAT = "initiateChatWithASeller";

    const handleSendMessage = () => {
    generateUniqueId();
    setChatSent(false);
    const payload = {
      productOrServiceId: product?._id,
      message: chatMessage,
      clientMessageId: uniqueId,
      operationMode: "Product",
    };



    if (socket) {
      setIsLoading(true);
      try {
        socket.emit(INITIATECHAT, payload);

        socket.on(EXCEPTION, (data) => {

            setIsLoading(false)
            handleAlert(`${data.message}`,"error")
        });

        socket.on(MAKE_OFFERS, (receivedData) => {
          // console.log("Received offer response:", receivedData);
          setChatSent(true);
          setIsLoading(false);
          // alert("offer created")
        });
      } catch (err) {
        // console.log(err);
        setIsLoading(false);
                    handleAlert(`${err.message}`,"error")
      }
    }
  };


  return (
    <>
      {(isLoading || loading) && <Loader />}
      <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
        <IconButton onClick={handleBack}>
          <GoArrowLeft />
        </IconButton>
        <Box>
          <Typography variant="subtitle1"     sx={{ fontSize: { md: "16px", sm: "14px", xs: "14px" } }}>OrderDetails</Typography>
          <Typography     sx={{
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
              }}>
            Product / {product?.name} / <b>{orderParams}</b>
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 3, bgcolor: "#f7f6f6", p: 2 }}>
        <Grid container columnSpacing={2} sx={{ mt: 0 }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: "8px" }}>
              <Typography variant="subtitle1"     sx={{ fontSize: { md: "16px", sm: "14px", xs: "14px" } }}>Status</Typography>
              <Box sx={{}}>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent sx={{ fontSize: "12px" }}>
                      <Typography variant="body2">
                        {" "}
                        Payment Confirmed
                      </Typography>
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          formatDate3(statusHistory[0]?.updatedAt)}
                      </Typography>
                      {statusHistory && statusHistory.length === 1 && (
                        <Box
                          sx={{
                            border: "1px solid #F2F2F2",
                            p: 1.5,
                            borderRadius: "5px",
                            mt: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Change order status by “clicking on process order”
                          </Typography>
                          <Button
                          onClick={handleProcessOrder}
                            variant="contained"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                          >
                            Process Order
                          </Button>
                          <Button
                          onClick={()=>setOpenChat(true)}
                            variant="outlined"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                            endIcon={
                              <IoChatbubblesOutline
                                style={{ fontSize: "15px" }}
                              />
                            }
                          >
                            Chat with buyer{" "}
                          </Button>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem activeStep>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent sx={{ fontSize: "12px" }}>
                      <Typography variant="body2">
                        Prepare order for Pickup
                      </Typography>
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          statusHistory[1] &&
                          formatDate3(statusHistory[1]?.updatedAt)}
                      </Typography>
                      {statusHistory && statusHistory.length === 2 && (
                        <Box
                          sx={{
                            border: "1px solid #F2F2F2",
                            p: 1.5,
                            borderRadius: "5px",
                            mt: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Change order status by “clicking on order shipped”
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                            onClick={handleShipOrder}
                          >
                            Order Shipped
                          </Button>
                          <Button
                              onClick={()=>setOpenChat(true)}
                            variant="outlined"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                            endIcon={
                              <IoChatbubblesOutline
                                style={{ fontSize: "15px" }}
                              />
                            }
                          >
                            Chat with buyer{" "}
                          </Button>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 2 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 2
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 2 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 2
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent sx={{ fontSize: "12px" }}>
                      <Typography variant="body2">Delivered</Typography>
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          statusHistory[2] &&
                          formatDate3(statusHistory[2]?.updatedAt)}
                      </Typography>
                      {statusHistory && statusHistory?.length === 3 && (
                        <Box
                          sx={{
                            border: "1px solid #F2F2F2",
                            p: 1.5,
                            borderRadius: "5px",
                            mt: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Ready to mark your order as delivered? Click here
                            “Order Delivered”
                          </Typography>
                          <Button
                          onClick={handleDeliveredOrder}
                            variant="contained"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                          >
                            Order Delivered
                          </Button>
                          <Button
                              onClick={()=>setOpenChat(true)}
                            variant="outlined"
                            sx={{
                              mt: 1.5,
                              borderRadius: "50px",
                              fontSize: "10px",
                            }}
                            fullWidth
                            endIcon={
                              <IoChatbubblesOutline
                                style={{ fontSize: "15px" }}
                              />
                            }
                          >
                            Chat with buyer{" "}
                          </Button>
                        </Box>
                      )}
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 4 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 4
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent sx={{ fontSize: "12px" }}>
                      <Typography variant="body2">Payment Released</Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "#fff",
                p: 2,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "8px",
                mt:{lg:0, md:0, sm:2, xs:2}
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 2,
                  width: "70%",
                }}
              >
                <Avatar
                  variant="rounded"
                  src={product && product.images[0]}
                  sx={{ width: "80px", height: "80px" }}
                />
                <Box sx={{ width: "58%" }}>
                  <Typography
                    sx={{
                      color: "#1a1a1a",
                      fontSize: {lg:"17px", sm:'14px', xs:'14px'},
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {product && product.name}
                  </Typography>
                  <Box sx={{ display: "flex", columnGap: 3, mt: 2 }}>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "12px",
                        fontWeight: 700,
                        whiteSpace:'nowrap'
                      }}
                    >
                      QTY: {data && quantity}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "12px",
                        fontWeight: 700,
                        ml: 5,
                      }}
                    >
                      ${pricePerUnit || "--"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: {lg:"10px", md:'10px', sm:'8px', xs:'6px'},
                    color: "#CA8A04",
                    fontWeight: 500,
                    bgcolor: "#FEF9C3",
                    width: "fit-content",
                    textAlign: "center",
                    px: 1,
                    py: 0.5,
                    borderRadius: "3px",
                    ...((status === "Processing" || status === "Paid") && {
                      color: "#151515",

                      bgcolor: "#E4E4E7",
                    }),
                    ...(status === "Shipped" && {
                      color: "#CA8A04",

                      bgcolor: "#FEF9C3",
                    }),
                    ...(status === "Delivered" ||
                      (status === "Review & Release Fund" && {
                        color: "#22C55E",

                        bgcolor: "#F0FDF4",
                      })),
                    ...((status === "Refunded" || status === "Cancelled") && {
                      color: "#EF4444",

                      bgcolor: "#FCE8EC",
                    }),
                  }}
                >
                  {status}
                </Typography>
              </Box>
            </Box>

            {activeStep > 2 ? (
              <>
                <Lottie options={defaultOptions} width={150} height={150} />
                <Box>
                  {activeStep === 3 && (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        color: "#2E1833",
                      }}
                    >
                      Picked up successfully
                    </Typography>
                  )}
                  {activeStep === 4 && (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        color: "#2E1833",
                      }}
                    >
                      Order Completed
                    </Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ bgcolor: "#fff", p: 2, mt: 2, borderRadius: "8px" }}>
                  <Typography sx={{ color: "#333", fontWeight: 500 }}>
                    Delivery
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 700, fontSize: "12px" }}>
                      {" "}
                      Delivery Type:
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 500, color: "#333", fontSize: "12px" }}
                    >
                      Pick up
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  ></Box>
                </Box>
              </>
            )}
            <Box sx={{ bgcolor: "#fff", p: 2, mt: 2, borderRadius: "8px" }}>
              <Typography sx={{ color: "#333", fontWeight: 500 }}>
                Payment
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Typography variant="body2">Amount:</Typography>
                <Typography
                  sx={{ fontWeight: 400, color: "#333", fontSize: "16px" }}
                >
                  ${amountPaid && amountPaid.toLocaleString()}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
              >
                <Typography variant="body2" sx={{ width: "fit-content" }}>
                  Payment Method:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#333",
                    fontSize: "14px",
                    textAlign: "right",
                  }}
                >
                  Mamapurse
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                p: 2,
                mt: 2,
                display: "grid",
                placeItems: "center",
                borderRadius: "8px",
              }}
            >
              {activeStep === 0 ? (
                <Button
                  onClick={handleOpen}
                  color="error"
                  sx={{ textDecoration: "underline", fontWeight: 600 }}
                >
                  CANCEL ORDER
                </Button>
              ) : (
                <>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={timeIcon} />
                    <Typography
                      sx={{
                        ml: 1,
                        color: "#333",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                    >
                      Need help?
                      <Link
                        to="/contact"
                        style={{ color: "#2E1834", fontWeight: 500 }}
                      >
                        {" "}
                        Contact Support{" "}
                      </Link>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box align="center" sx={{ p: 3 }}>
                <img src={declinedImg} />
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                  Order Cancelled
                </Typography>
                <Typography
                  sx={{
                    color: "#333",
                    mt: 2,
                    fontWeight: 500,
                    lineHeight: "126%",
                  }}
                >
                  A refund will be issued and credited to your MamaPurse wallet.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ mt: 3, borderRadius: "100px", px: 5 }}
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                  Cancel Order
                </Typography>
                <IconButton onClick={handleClose}>
                  {" "}
                  <ClearIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  mt: 2,
                  color: "#333",
                  lineHeight: "145%",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                By clicking "cancel order," you confirm the cancellation of your
                purchase. A refund will be issued and credited to your MamaPurse
                wallet.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Select
                  displayEmpty
                  fullWidth
                  sx={{
                    borderRadius: "10px",
                    background: "#fff",
                    offset: " 0px solid #737373",
                    border: "0px solid red",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#333",
                  }}
                  InputProps={{
                    style: {
                      fontSize: "12px",

                      // Replace with your desired font family
                    },
                  }}
                  value={rejectReasons}
                  onChange={handleChange}
                >
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value=""
                    disabled
                  >
                    Reason for cancellation
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Better Alternative "
                  >
                    Better Alternative{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="High Cost "
                  >
                    High Cost{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Dissatisfaction with quality"
                  >
                    {" "}
                    Dissatisfaction with quality{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Others "
                  >
                    {" "}
                    Others{" "}
                  </MenuItem>
                </Select>

                <TextField
                  fullWidth
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={6}
                  margin="normal"
                  placeholder="More description"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      background: "#fff",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#333",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 3,
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    border: "1px solid #E6E6E6",
                    color: "#333",
                    borderRadius: "100px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  sx={{ borderRadius: "100px" }}
                  fullWidth
                  color="error"
                  onClick={handleCancelOrder}
                  variant="contained"
                  disabled={rejectReasons === "" || !description}
                >
                  Yes, Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {chatSent ? (
            <>
              <Box align="center" sx={{ p: 3 }}>
                {/* <img src={} /> */}
                <TbMessageShare />
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                Message Sent 
                </Typography>
                <Typography
                  sx={{
                    color: "#333",
                    mt: 2,
                    fontWeight: 500,
                    lineHeight: "126%",
                  }}
                >
         
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleCloseChat}
                  sx={{ mt: 3, borderRadius: "100px", px: 5 }}
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                  <IoChatbubblesOutline/>  Chat with Buyer
                </Typography>
                <IconButton onClick={handleCloseChat}>
                  {" "}
                  <ClearIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  mt: 2,
                  color: "#333",
                  lineHeight: "145%",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
            Connect with Your Buyer: Manage Orders and Address Inquiries Seamlessly
              </Typography>
              <Box sx={{  }}>

                <TextField
                  fullWidth
                  multiline
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  minRows={2}
                  maxRows={6}
                  margin="normal"
                  placeholder="Your message here..."
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      background: "#fff",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#333",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 3,
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    border: "1px solid #E6E6E6",
                    color: "#333",
                    borderRadius: "100px",
                  }}
                  onClick={handleCloseChat}
                >
                  Cancel
                </Button>

                <Button
                  sx={{ borderRadius: "100px" }}
                  fullWidth
                  color="error"
                  onClick={handleSendMessage}
                  variant="contained"
                  disabled={!chatMessage}
                >
               Send Message
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Overview;
