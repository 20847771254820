import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gql } from "@apollo/client";
import client from "../Utils/ApolloInstance"; // Import your Apollo Client instance
import { GET_SERVICES } from "../Utils/Queries";



export const getServices = createAsyncThunk("services/get", async () => {
  //   const client = getClient(); // Get your Apollo Client instance

  try {
    const response = await client.query({ query: GET_SERVICES });
    return response.data?.listAllAvailableServicesWithoutAuthentication.items;
  } catch (error) {
    // console.error("Error fetching products:", error);
    throw error;
  }
});

const serviceSlice = createSlice({
  name: "services",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(getServices.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export default serviceSlice.reducer;
