import { Box, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";

const StoreInformation = () => {
  return (
    <>
      <Box>
        <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400 }}
        >
          Business Name
        </InputLabel>
        <TextField
          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        />
        <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400 , mt:1.5}}
        >
      Business Category
        </InputLabel>
        <TextField
        select

          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        >
                        </TextField>
                                <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400, mt:1.5 }}
        >
 Business Address
        </InputLabel>
        <TextField
          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        />
        <Grid container spacing={2} sx={{mt:1.5}}>
<Grid item md={6}>
                <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400, }}
        >
State
        </InputLabel>
        <TextField
          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        />
</Grid>
<Grid item md={6}>
                <InputLabel
          sx={{ fontSize: "12px", color: "#151515", fontWeight: 400, }}
        >
City
        </InputLabel>
        <TextField
          fullWidth
          sx={{ bgcolor: "#fff",     borderRadius: "8px", }}
          margin="dense"
          InputProps={{
            style: {
              borderRadius: "8px",
              border:'0.5px solid #f2f2f2'
            },
          }}
        />
</Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StoreInformation;
