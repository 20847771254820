import React,{useState, useEffect} from 'react'

import {
  Box,
  TableContainer,
  Avatar,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from 'react-router-dom';
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useRemoveCarts from '../../Hooks/useRemoveCart';
import useGetCart from '../../Hooks/useGetCart';
import useGetServiceCart from '../../Hooks/useGetServiceCart';
import Loader from './Loader';
import useIncreaseCarts from '../../Hooks/useIncrease';

const CustomTable = ({renderedCart, loading}) => {
                          const [itemId, setItemId] = useState("");
                          const { handleRemoveCart, isLoading } = useRemoveCarts();
                   const { handleGetCart, refetch } = useGetCart();
                     const { handleGetCart:handleServiceCart, refetch:refecthCart } = useGetServiceCart();
                   const {handleIncreaseCart} = useIncreaseCarts()
                   const isAuthenticated =
                     localStorage.getItem("isAuthenticated") === "true";

  const totalSubtotal = renderedCart.reduce(
    (total, item) => total + item.totalAmount,
    0
  );
  useEffect(() => {
    setItemId(itemId);
  }, [itemId]);
useEffect(()=>{
          handleGetCart();     
          handleServiceCart()              
},[])
                            const removeCart = (item_id) => {
                              setItemId(item_id);
                              handleRemoveCart(item_id);
                              handleGetCart()
                            };

                             const handleIncreaseQuantity = async (
                               id,
                               quantity
                             ) => {
                               const newQuantity = quantity + 1;
                               handleIncreaseCart(id, newQuantity)
                                 handleGetCart();

                             };
                             const handleDecreaseQuantity = async (
                               id,
                               quantity
                             ) => {
                               if (quantity > 0) {
                                 const newQuantity = quantity - 1;
                                        handleIncreaseCart(id, newQuantity);
                                        handleGetCart();
                               }
                             };
  return (
    <>
    {
 loading && <Loader/>
    }
      <TableContainer>
        <Table>
          <TableHead sx={{ p: 0 }}></TableHead>
          <TableBody>
            {renderedCart &&
              renderedCart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ px: 0 }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Avatar
                        variant="square"
                        src={item?.product?.images[0]}
                        sx={{ width: "70px", height: "70px" }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#121212",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {item.product.name}
                        </Typography>
                        <Button
                          sx={{ color: "#FF4C5E" }}
                          onClick={() => removeCart(item._id)}
                        >
                          <img
                            src={trash}
                            alt="trash_icon"
                            style={{ marginRight: "3px" }}
                          />{" "}
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ px: 0 }}>
                    <Box >
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          display: "flex",
                          width: "50%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width:'fit'
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleDecreaseQuantity(
                              item.product._id,
                              item.quantity
                            )
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "#121212",
                          }}
                        >
                          {item?.quantity}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            handleIncreaseQuantity(
                              item.product._id,
                              item.quantity
                            )
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ px: 0 }}>
                    <Typography sx={{ color: "#333", fontSize: "16px" }}>
                      &#36;
                      {item.pricePerUnit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ px: 0 }}>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      &#36;
                      {item.totalAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box align="right">
                      <Box
                        sx={{ width: "30%", bgcolor: "#F8F7F7", mt: 3, px: 2 }}
                      >
                        <Table>
                          <TableHead>
                            <TableCell
                              sx={{
                                fontSize: "15px",
                   
                                fontWeight: 500,
                                color: "#333",
                              }}
                            >
                              Summary
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Subtotal</TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  color: "#1a1a1a",
                                  fontWeight: 500,
                     
                                }}
                              >
                                $
                                {totalSubtotal.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {isAuthenticated ? (
                          <>
                            <Link to="/checkout">
                              <Button
                                fullWidth
                                sx={{ mt: 3, borderRadius: "100px" }}
                                variant="contained"
                              >
                                Proceed To CheckOut
                              </Button>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="/login">
                              <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={{ mt: 3, borderRadius: "100px" }}
                              >
                                Sign in to proceed
                              </Button>
                            </Link>
                            <Typography
                              sx={{ textAlign: "center", fontSize: "12px" }}
                            >
                              New to Mamaket?{" "}
                              <Link to="/register">Sign up </Link>
                            </Typography>
                          </>
                        )}
                      </Box>
                      </Box>
    </>
  );
}

export default CustomTable