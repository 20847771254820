import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import messageIcon from "../../../assets/Icons/message.svg";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
// import { useSocket } from '../../../context/SocketContext';
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import sentIcon from "../../../assets/Icons/sent.svg";
import socket from "../../../Utils/socket";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../store/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 260 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const MakeOffer = ({ product, quantity }) => {
  // const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };
  const handleClose = () => setOpen(false);

  const [offerValue, setOfferValue] = useState(0);

  const location = useLocation()

  const handleSuggestOffer = (e) => setOfferValue(e.target.value);

  const MAKE_OFFERS = "newProductOffer";
  const EXCEPTION = "exception";
  const INITIATEPRODUCT = "initiateProductOffer";

  const handleMakeOffer = () => {
    setSuccess(false);
    const payload = {
      productId: product?._id,
      priceOffer: parseFloat(offerValue),
      quantity: quantity,
    };

    if (socket) {
      setIsLoading(true);
      try {
        socket.on("connect");
        socket.emit(INITIATEPRODUCT, payload);

        socket.on(EXCEPTION, (data) => {

        });

        socket.on(MAKE_OFFERS, (receivedData) => {

          setSuccess(true);
          setIsLoading(false);

        });
      } catch (err) {

        setIsLoading(false);
      }
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login", {
                            state: { redirectTo: location.pathname },
                          });
  };

  return (
    <>
      <Button
        onClick={isAuthenticated ? handleOpen : handleLogout}
        endIcon={<img src={messageIcon} />}
        variant="contained"
        sx={{
          fontSize: "12px",
          bgcolor: "#5C3069",
          color: "#fff",
          borderRadius: "100px",
        }}
      >
        Make An Offer
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box
                sx={{
                  px: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={sentIcon} />
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 500,
                    textAlign: "center",
                    my: 3,
                  }}
                >
                  Your price offer sent to seller, you will get notified what
                  the seller decides.
                </Typography>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "100px" }}
                  onClick={handleClose}
                >
                  {" "}
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
                >
                  Make an offer
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>

              <Box sx={{ mt: 2, pb: 2 }}>
                <TextField
                  fullWidth
                  value={offerValue}
                  onChange={(e) => setOfferValue(e.target.value)}
                  type="number"
                  InputProps={{
                       inputMode:'numeric', pattern:'[0-9]*',
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    style: {

                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    value={product?.price - 10}
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 2,
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    ${(product?.price - 10).toLocaleString()}
                  </Button>
                  <Button
                    value={product?.price - 15}
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 2,
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    ${(product?.price - 15).toLocaleString()}
                  </Button>
                  <Button
                    value={product?.price - 20}
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 2,
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    ${(product?.price - 20).toLocaleString()}
                  </Button>
                  <Button
                    value={product?.price - 25}
                    onClick={handleSuggestOffer}
                    sx={{
                      border: "1px dashed #333",
                      width: "fit-content",
                      color: "#333",
                      borderRadius: 3,
                      px: 2,
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    ${(product?.price - 25).toLocaleString()}
                  </Button>
                </Box>
              </Box>
              <Box sx={{ py: 2 }}></Box>
              <Box sx={{ mt: 3 }}>
                {!isLoading && (
                  <Button
                    disabled={offerValue == 0}
                    onClick={handleMakeOffer}
                    fullWidth
                    variant="contained"
                    sx={{ borderRadius: "100px" }}
                  >
                    Send Offer
                  </Button>
                )}
                {isLoading && (
                  <LoadingButton
                    fullWidth
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled
                    sx={{ borderRadius: "100px" }}
                  >
                    Sending offer
                  </LoadingButton>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default MakeOffer;
