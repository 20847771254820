import { useEffect, useState } from "react";
import { favItemsVar } from "../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { ADD_CARTS, ADD_FAVOURITE } from "../Utils/Mutations";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

const useAddToFav = (product_id, isProduct) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setSuccess(false);
      }, 2600);
    }
  });

  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const favItems = useReactiveVar(favItemsVar);

  const [addFav, { data }] = useMutation(ADD_FAVOURITE);

  let isInCart = favItems?.some(
    (item) => item?.productOrService?._id === product_id
  );

  const handleAddToFav = async () => {
    const payload = { productOrServiceId: product_id, isProduct };

    if (!isInCart) {
      setIsLoading(true);
      await addFav({
        variables: payload,
      })
        .then((res) => {
          setIsLoading(false);
          setSuccess(true);
          favItemsVar([...favItems, res?.data?.addFavourite]);
        })
        .catch((err) => {
          console.error("Error Newe Cart", err);
          setIsLoading(false);
        });
    } else {
      handleAlert("warning", `Item is already saved.`);
    }
  };

  return { handleAddToFav, isLoading, isSuccess };
};

export default useAddToFav;
