import { Box, Typography } from "@mui/material";
import React from "react";
import Navbar from "../Components/Common/Navbar";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";

const Faq = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ margin: "0 auto", width:{lg: "85%", md:'85%', sm:'100%', xs:'100%'}, mt: {lg:17, md:17, sm:10, xs:10}, mb:5, bgcolor: "#fff" }}>
        <Box sx={{ py: 3, px: {lg:4, md:4, sm:2, xs:2} }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <Typography
              sx={{ color: "#333", fontSize: "18px", fontWeight: 600 }}
            >
              Frequently Asked Questions
            </Typography>{" "}
            <ErrorOutlineIcon />
          </Box>
          <Box
            sx={{
              border: "1px solid #F1F2F4",
              borderRadius: "20px",
              p: 2,
              px: 3,
              mt: 3,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, color: "#333", fontSize: "18px" }}
            >
              How does it work?
            </Typography>
            <ul
              style={{ color: "#4D4D4D", fontSize: "14px", lineHeight: "160%" }}
            >
              <li style={{ color: "#4D4D4D" }}>
                Download the Mamaket mobile app or use the website to browse
                deals on tons of great items nearby.
              </li>
              <li>
                Instantly message the seller securely through the app to
                negotiate prices and set up delivery or meetup options.
              </li>
              <li>
                Make and receive payment using the Mamaket e-wallet system known
                as Mamapurse.
              </li>
              <li>
                Sell something of your own, by simply taking a picture with your
                phone, you can sell old and new products as well as services.
                Posting takes less than 30 seconds!
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              border: "1px solid #F1F2F4",
              borderRadius: "20px",
              p: 2,
              mt: 3,
              px: 3,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, color: "#333", fontSize: "18px" }}
            >
              What is a marketplace?
            </Typography>
            <p
              style={{ color: "#4D4D4D", fontSize: "14px", lineHeight: "160%" }}
            >
              A marketplace is a platform or an environment where buyers and
              sellers come together to engage in commercial transactions. The
              marketplace provides a venue for sellers to offer their goods or
              services to potential customers, who can compare prices and
              quality, make purchases, and provide feedback. Marketplaces can be
              physical locations, such as farmers markets, or online platforms
              like Mamaket. In recent years, there has been a significant
              increase in the number of online marketplaces, providing buyers
              and sellers with greater access to a wider range of products and
              services, and enabling them to transact business more efficiently
              and conveniently. Basically, what you need is here just type the
              search.
            </p>
          </Box>
          <Box
            sx={{
              border: "1px solid #F1F2F4",
              borderRadius: "20px",
              p: 2,
              mt: 3,
              px: 3,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, color: "#333", fontSize: "18px" }}
            >
              What is a marketplace?
            </Typography>
            <p
              style={{ color: "#4D4D4D", fontSize: "14px", lineHeight: "160%" }}
            >
              A marketplace is a platform or an environment where buyers and
              sellers come together to engage in commercial transactions. The
              marketplace provides a venue for sellers to offer their goods or
              services to potential customers, who can compare prices and
              quality, make purchases, and provide feedback. Marketplaces can be
              physical locations, such as farmers markets, or online platforms
              like Mamaket. In recent years, there has been a significant
              increase in the number of online marketplaces, providing buyers
              and sellers with greater access to a wider range of products and
              services, and enabling them to transact business more efficiently
              and conveniently. Basically, what you need is here just type the
              search.
            </p>
          </Box>
          <Box
            sx={{
              border: "1px solid #F1F2F4",
              borderRadius: "20px",
              p: 2,
              mt: 3,
              px: 3,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, color: "#333", fontSize: "18px" }}
            >
              What is a marketplace?
            </Typography>
            <p
              style={{ color: "#4D4D4D", fontSize: "14px", lineHeight: "160%" }}
            >
              A marketplace is a platform or an environment where buyers and
              sellers come together to engage in commercial transactions. The
              marketplace provides a venue for sellers to offer their goods or
              services to potential customers, who can compare prices and
              quality, make purchases, and provide feedback. Marketplaces can be
              physical locations, such as farmers markets, or online platforms
              like Mamaket. In recent years, there has been a significant
              increase in the number of online marketplaces, providing buyers
              and sellers with greater access to a wider range of products and
              services, and enabling them to transact business more efficiently
              and conveniently. Basically, what you need is here just type the
              search.
            </p>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Faq;
