// src/requestNotificationPermission.js
import { getToken } from 'firebase/messaging';
import { messaging } from './FirebaseConfig';

export const RequestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BMfKrqOHpXN-RLTHHQ5FD7-USmffFB-owiOsXcgtoJf3gW839F57PuFR4Fh8gRf97zcPdsNhidDeRSt-Uw58_lQ', // Find this in the Firebase console
      });

      // Save the token to your server or use it directly
    } else {
  
    }
  } catch (error) {

  }
};
