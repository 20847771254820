import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  IconButton,
  Badge,
  Popover,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloseOutlined } from "@mui/icons-material";
import emptyIcon from "../../assets/Icons/empty_history.svg";
import Navbar from "./Navbar";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const NotificationMobile = () => {
  const [data, setData] = React.useState(null);

  return (
    <>
      <Navbar />
      <Box sx={{ p: 2, boxShadow: "border-box", mt: 8 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#151515", fontSize: "14px", fontWeight: 500 }}
          >
            Notifications
          </Typography>
          <Box>
            <Button
              disabled={!data}
              variant="contained"
              sx={{ fontSize: "10px", borderRadius: 4 }}
            >
              Mark all as read
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          {!data || data?.length === 0 ? (
            <>
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={emptyIcon} />
                <Typography
                  sx={{
                    mt: 1,
                    color: "#151515",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  No Notifications
                </Typography>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NotificationMobile;
