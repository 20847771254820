import { gql } from "@apollo/client";

// AUTH
export const SIGNUP_MUTATION = gql`
  mutation SignUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $password: String!
    $lat: Float!
    $long: Float!
    $country: String!
    $favouriteCategories: [String!]!
  ) {
    signUp(
      signUpInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        password: $password
        lat: $lat
        long: $long
        country: $country
        favouriteCategories: $favouriteCategories
      }
    )
  }
`;

export const SIGNIN_SOCIAL = gql`
  mutation loginSocial(
    $accessToken: String!
    $socialAuthProviderKey: String!
    $useAccessToken: Boolean!
  ) {
    loginThroughSocialAuth(
      payload: {
        accessToken: $accessToken
        socialAuthProviderKey: $socialAuthProviderKey
        useAccessToken: $useAccessToken
      }
    )
  }
`;

export const SIGNUP_SOCIAL = gql`
  mutation signupSocial(
    $accessToken: String!
    $socialAuthProviderKey: String!
    $useAccessToken: Boolean!
    $country:String!
  ) {
    registerThroughSocialAuth(
      payload: {
        accessToken: $accessToken
        socialAuthProviderKey: $socialAuthProviderKey
        country: $country
        lat: 0.002222
        long: 0.002222
        useAccessToken: $useAccessToken
        favouriteCategories: []
      }
    )
  }
`;

export const RESEND_OTP = gql`
  mutation ResendOTP($email: String!) {
    resendOTP(emailInput: { email: $email })
  }
`;

export  const RESET_PASSWORD = gql`
mutation reset($otp:String! $uuid: String! $password:String!) {
    resetPasswordWithOTP(passwordResetInput: {
        otp: $otp
        uuid: $uuid
        password: $password
    })
}
`

export const VALIDATE_OTP = gql`
  mutation ValidateOTP($uuid: String!, $otp: String!) {
    validateOTP(validateOtpInput: { uuid: $uuid, otp: $otp })
  }
`;

export const CREATE_PIN = gql`
  mutation CreateTransactionPin($userId: String!, $transactionPin: String!) {
    createTransactionPinOnSignUp(
      createTransactionPinInput: {
        userId: $userId
        transactionPin: $transactionPin
      }
    )
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(loginPayload: { email: $email, password: $password })
  }
`;
// .....................................................


// DASHBOARD

export const PROFILE_EDIT = gql`
  mutation updateProfileInformation(
    $firstName: String!,
    $lastName: String!,
    $sex: String!,
    $country: String!,
  ) {
    updateProfileInformation(
      payload: {
        firstName: $firstName,
        lastName: $lastName,
        sex: $sex,
        country: $country,
      }
    ) {
      uuid
      firstName
      lastName
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      payload: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      firstName
      lastName
    }
  }
`;

export const CHANGE_PIN = gql`
  mutation changeTransactionPin(
    $currentPassword: String!
    $currentTransactionPin: String!
    $newTransactionPin: String!
  ) {
    changeTransactionPin(
      payload: {
        currentPassword: $currentPassword
        currentTransactionPin: $currentTransactionPin
        newTransactionPin: $newTransactionPin
      }
    ) {
      firstName
      lastName
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation addNewAddress(
    $firstName: String!
    $lastName: String!
    $country: String!
    $state: String!
    $city: String!
    $addressOne: String!
    $addressTwo: String
    $zipCode: String!
    $phone: String!
    $isDefault: Boolean!
  ) {
    addNewAddress(
      payload: {
        firstName: $firstName
        lastName: $lastName
        country: $country
        state: $state
        city: $city
        addressOne: $addressOne
        addressTwo: $addressTwo
        zipCode: $zipCode
        phone: $phone
        isDefault: $isDefault
      }
    )
  }
`;

export const EDIT_ADDRESS = gql`
mutation addNewAddress(
    $firstName: String!
    $lastName: String!
    $country: String!
    $state: String!
    $city: String!
    $addressOne: String!
    $addressTwo: String
    $zipCode: String!
    $addressId : String!
    $phone: String!
    $isDefault: Boolean!
  ){
    updateAddress(payload: {
         firstName: $firstName
        lastName: $lastName
        country: $country
        state: $state
        city: $city
        addressOne: $addressOne
        addressTwo: $addressTwo
        zipCode: $zipCode
        addressId: $addressId
     phone: $phone
        isDefault: $isDefault
    })
}
`

export const ADD_FUNDS = gql`
  mutation initializeWalletFunding($amount: Float!) {
    initializeWalletFunding(
      payload: {
        amount: $amount
        gatewayProviderClass: "stripe.service"

      }
    )
  }
`;
// .................................................



// CHECKOUT
export const CREATE_ORDER = gql`
  mutation createOrder(
    $deliveryOption:String!
  $transactionPin:String!
    ) {
    createOrder(
      payload: {
        deliveryOption: $deliveryOption
        transactionPin: $transactionPin
      }
    )
  }
`;

export const SERVICE_CHECKOUT = gql`
mutation {
    checkoutMyCartItems {
        message
    }
}
`

export const ADD_CARTS = gql`
  mutation addToCart($product:String!, $quantity:Float!) {
    addToCart(payload: { product:$product , quantity: $quantity }) {
      _id
      quantity
      pricePerUnit
      totalAmount
      createdAt
      updatedAt
      product {
        _id
        name
        description
        images
      }
      session {
        _id
        currency {
          name
          shortCode
          shortCode
        }
      }
    }
  }
`;

export const REMOVE_CARTS = gql`
  mutation removeItems($itemIds:String!) {
    removeItems(payload: { itemIds: [$itemIds] })
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelProduct($itemId:String! $reason:String! $description:String!){
    cancelProductOrderByBuyer(
      payload: {
        itemId:$itemId
        reason: $reason
        description: $description
      }
    ) {
      message
    }
  }
`;

export const RELEASE_FUNDS = gql`
mutation releaseFunds($itemId:String! $rating:Float! $comments:String!) {
  confirmIndividualProductOrderByTheBuyer(payload: { 
    itemId: $itemId,
    rating: $rating,
    comments: $comments
  }) {
    message
  }
}
`

export const SERVICE_RELEASE_FUNDS = gql `
mutation orderId($appointmentId :  String!) {
    triggerReleaseFundByBuyerAfterCompletion(
        payload: { appointmentId: $appointmentId}) {
        message
    }
}
`

export const SUBSCRIBE = gql`
mutation subscribe($email:String!) {
    subscribeToNewsLetter(payload: { email: $email }) {
        message
    }
}
`

export const CONTACT_US = gql`
mutation sendContact($fullName:String! $email:String! $message:String!) {
    sendContactUsMessage(payload: {
        fullName: $fullName,
        email: $email,
        message: $message
    }) {
        message
    }
}
`


export const DISPUTE_ORDER = gql`
  mutation($files: [Upload!], $payload: DisputeOrderDto!) {
    submitDispute(files: $files, payload: $payload) {
      message
    }
  }
`;

export const CHANGE_PHONE = gql`
mutation changePhone( $country:String!, $phone : String!){
    requestToChangePhoneNumber(payload: { 
        country: $country, 
        phone: $phone
    }) {
        message
        userId
        otp # This would be removed. Only available at the time of development
    }
}
`

export const EDIT_PHONE_OTP = gql`
mutation confirmOtp($otp:String!) {
    handlePhoneNumberChangeRequest(payload: { otp: $otp }) {
        message
    }
}
`


export const UPDATE_NOTIFICATION = gql`
mutation update($notification: [String!]!) {
    updateMyNotificationPreferences(configDto: { notifications: $notification }) {
      notifications {
        _id
        title
      }
    }
  }
`;


export const ADD_FAVOURITE = gql`
mutation addFav($productOrServiceId:String!, $isProduct: Boolean!) {
    addFavourite(payload: { productOrServiceId:$productOrServiceId, isProduct: $isProduct })
    {
        _id
        isProduct
        productOrService
        createdAt
        updatedAt
    }
}
`

export const DELETE_FAV = gql`
mutation delete($userFavouriteId:String!){
    deleteFavourite(payload: { userFavouriteId: $userFavouriteId }) {
        message
    }
}
`

export const CREATE_PRODUCT = gql`
  mutation($files: [Upload!]!, $payload: CreateProductDto!) {
    createProduct(files: $files, payload: $payload)
  }
`

export const CREATE_SERVICE = gql`
  mutation($file: Upload!, $payload: CreateUserServiceDto!) {
    creatService(file: $file, payload: $payload) {
    reference, createdAt, updatedAt, images, title, description, price, rateType, status
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct($payload: EditProductDto!) {
    editProduct(payload: $payload)
  }
`;

export const EDIT_SERVICE = gql`
 mutation EditService(
    $serviceId: String!,
    $title: String!,
    $category: String!,
    $description: String!,
    $price: Float!,
    $isNegotiable: Boolean!,
    $city: String!
  ) {
  editService(payload: {
    serviceId: $serviceId,  
    title: $title,
    category: $category,
    description: $description,
    price:$price,
    isNegotiable: $isNegotiable,
    city: $city
  }) {
    _id
    reference
    title
    description
    price
    rateType
    status
    isNegotiable
    images
    createdAt
    updatedAt  
    city {
      name
      state {
        name
          country {
            name
            currency {
              name
              symbol
              alias 
          }
        }
      }
    }
  }
}
`;


export const DELETE_PRODUCT = gql`
mutation delete($productId:String!){
  deleteProduct(payload: { productId: $productId })
}
`

export const PROCESS_ORDER = gql`
mutation changeOrder($orderIds:String!){
  changeOrderToProcessing(payload: {
    orderIds: [$orderIds] 
  }) {
    message
  }
}
`

export const ORDER_SHIPPED = gql`
mutation changeOrderShip($orderIds:String!){
  changeOrderToShipped(payload: {
    orderIds: [$orderIds]  
  }) {
    message
  }
}
`


export const ORDER_DELIVERED = gql`
mutation changeOrderDel($orderIds:String!){
  changeOrderToDelivered(payload: {
    orderIds: [$orderIds]  
  }) {
    message
  }
}
`

export const PAY_VERIFICATION = gql`
mutation {
    payToBecomeASeller {
        message
    }
}
`

export const EDIT_PRODUCT_IMAGE = gql`
  mutation AddMoreImagesToProduct($files: [Upload!]!, $serviceId: String!) {
    addMoreImagesToProduct(files: $files, productId: $productId)
  }
`;
export const EDIT_SERVICE_IMAGE = gql`
  mutation addMoreServiceImages($files: [Upload!]!, $serviceId: String!) {
    addMoreServiceImages(files: $files, serviceId: $serviceId)
  }
`;


export const DELETE_EXISTING_IMAGE = gql`
  mutation RemoveImagesFromProduct($productId: String!, $images: [String!]!) {
    removeImagesFromProduct(payload: { productId: $productId, images: $images })
  }
`;
export const DELETE_SERVICE_IMAGE = gql`
  mutation deleteServiceImages($serviceId: String!, $images: [String!]!) {
    deleteServiceImages(payload: { serviceId: $serviceId, images: $images })
  }
`;

export const START_APPOINTMENT = gql`
mutation start($appointmentId : String!){
    startAppointmentBySeller(payload: { appointmentId: $appointmentId }) {
        message
    }
}
`

export const COMPLETE_APPOINTMENT = gql`
mutation start($appointmentId : String!){
    triggerAppointmentCompletionBySeller(
        payload: { appointmentId: $appointmentId }) {
        message
    }
}
`

export const ADD_ACCOUNT = gql`
mutation add($bankName:String!, $accountName:String!, $accountNumber:String!,$routingNumber:String! ) {
    addNewBank(payload: {
        bankName: $bankName,
        accountHolderType: "individual", 
        accountName: $accountName,
        accountNumber: $accountNumber,
        routingNumber: $routingNumber
    }) {
        _id
        accountName
        accountNumber
        routingNumber
        bankName
        createdAt
        updatedAt
    }
}
`

export const WITHDRAWAL_REQUEST = gql`
mutation withdraw($bankId:String!, $amount:Float!){
    sendWithdrawalRequest(payload: { bankId: $bankId, amount: $amount }) {
        _id
        reference
        amount
        remarks
        provider
        status,
        createdAt
        updatedAt
        currency
    }
}

`


