import { makeVar, InMemoryCache } from "@apollo/client";

export const cartItemsVar = makeVar([]);
export const serviceCartVar = makeVar([]);
export const favItemsVar = makeVar([]);

export const customInMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cartItems: {
          read() {
            return cartItemsVar();
          },
        },
        serviceCarts: {
          read() {
            return serviceCartVar();
          },
        },
        favItemsVar: {
          read() {
            return favItemsVar();
          },
        },
      },
    },
  },
});
