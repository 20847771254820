import React from 'react'
import FooterNavBar from '../Components/Common/FooterNavBar'
import Newsletter from '../Components/Common/Newsletter'
import Footer from '../Components/Common/Footer'
import { Box, Typography } from '@mui/material'
import Navbar from '../Components/Common/Navbar'

const About = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },

          width: "85%",
          margin: "0 auto",
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 700, color: "#333" }}>
          About Us
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 2, textAlign:'justify' }}
        >
          Mamaket, Inc. is an e-commerce industry incorporated in 2023 to
          specifically cater to the vibrant tapestry of immigrant communities
          within the United States.
          <br />
          <br /> We do more than facilitate transactions between buyers and
          sellers of international goods and services: we weave a rich narrative
          of unity and understanding.
          <span style={{ fontStyle: "italic", fontWeight: 400 }}>
            {" "}
            Our mission is to provide visibility for products and services that
            are steeped in the heritage and traditions of immigrant cultures.
          </span>{" "}
          We understand the challenges faced by immigrants in a foreign land,
          especially when it comes to sourcing products that echo the familiar
          comforts of home. That&#39;s why Mamaket isn’t just a marketplace;
          it’s a bridge that connects cultures, a platform that celebrates
          diversity, and a sanctuary that cures homesickness.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            Empowering Communities, One Click at a Time
          </Typography>{" "}
          For many immigrants, the struggle to locate goods and services from
          their own cultures is real. Mamaket steps in to obliterate this
          struggle. Through our user- friendly platform, we empower sellers from
          various ethnic backgrounds to showcase their products, talents, and
          crafts. Whether you’re craving the taste of authentic cuisine, seeking
          traditional attire, or longing for cultural artifacts, Mamaket is your
          one-stop destination.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            Beyond Borders: A Global Marketplace
          </Typography>{" "}
          But Mamaket’s vision extends far beyond the borders of the United
          States. We aspire to become the largest platform for buying and
          selling products from different ethnic groups worldwide. Our reach
          knows no bounds; we aim to serve immigrant populations not only in the
          United States but also in countries across the globe, fostering a
          sense of belonging and community no matter where you are.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
            Cultural Richness in Every Aspect
          </Typography>
          Mamaket isn’t just about products; it’s about experiences. We curate a
          myriad of offerings that go beyond mere materialism. From goods and
          services to entertainment, travel, and events, Mamaket is your gateway
          to exploring the world’s cultural richness. Picture this: vibrant
          cultural events, electrifying festivals, and colorful carnivals - all
          at your fingertips. At Mamaket, we believe that understanding and
          celebrating diverse cultures is essential, and that’s why we bring the
          world’s cultural tapestry right to your screen.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
            Home Away from Home
          </Typography>
          Ever felt like a stranger in a foreign land? We understand that
          feeling all too well. That’s why Mamaket is designed to make you feel
          at home in any foreign territory. Our platform isn’t just about
          transactions; it’s about building connections, fostering friendships,
          and nurturing a sense of community. We invite you to embark on a
          journey with us, where every click brings you closer to your roots,
          and every purchase resonates with the warmth of shared traditions.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            Join Us on this Cultural Odyssey
          </Typography>
          So, whether you’re an entrepreneur eager to showcase your products, a
          shopper yearning for a piece of home, or an enthusiast seeking to
          explore the world’s cultural wonders, Mamaket welcomes you with open
          arms. Join us on this cultural odyssey, where diversity isn’t just
          respected; it’s celebrated. Together, let’s create a world where every
          culture finds its place, and every individual feels cherished.<br/><br/> Mamaket
          - Where Cultures Converge, and Connections Flourish.
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
}

export default About