import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Modal,
  Checkbox,
  LinearProgress,
} from "@mui/material";

import successIcon from "../../assets/Icons/sell/5.svg";
import errorIcon from "../../assets/Icons/information.svg";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { ImImages } from "react-icons/im";
import { HiViewfinderCircle } from "react-icons/hi2";
import { RiDeleteBin2Line } from "react-icons/ri";
import ConfettiExplosion from "react-confetti-explosion";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { MdOutlineLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_CITY,
  GET_CATEGORIES_N_SUB,
  GET_PROFILE,
} from "../../Utils/Queries";
import { CREATE_SERVICE } from "../../Utils/Mutations";
import Loader from "../../Components/Common/Loader";
import UploadComponent from "./UploadComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const AddService = ({ open, onClose, refetch }) => {
  const country_id = "65fe9e4dd5a2f2d3f3021a0b";

  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(1);

  const navigate = useNavigate();

  const [uploadedImages, setUploadedImages] = useState([]);

  const [openModal, setOpenModal] = React.useState(false);

  const [openModal2, setOpenModal2] = React.useState(false);

  const [isExploding, setIsExploding] = React.useState(false);

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const updateUploadedImages = (images) => {
    setUploadedImages(images);
  };

  const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);

  useEffect(() => {
    if (uploadedImages) {
      setSelectedPreviewImage(uploadedImages[0]);
    }
  }, [uploadedImages]);

  const handleOpen = () => {
    setIsExploding(true);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setIsExploding(false);
  };

  const [serviceDetails, setServiceDetails] = useState({
    category: "",
    title: "",
    description: "",
    state: "",
    city: "",
    price: "",
    quantity: "",
    negotiable: false,
  });

  const { loading: loadingCategories, data: categories, error } = useQuery(
    GET_CATEGORIES_N_SUB
  );
  const [
    getCity,
    { loading: cityLoad, error: cityError, data: cityData },
  ] = useLazyQuery(GET_CITY);

  const [
    getState,
    { loading: stateLoad, error: statError, data: stateData },
  ] = useLazyQuery(GET_STATES);

  useEffect(() => {
    getState({
      variables: {
        country: country_id,
      },
    });
    getCity({
      variables: {
        state: serviceDetails?.state?._id,
      },
    });
  }, [serviceDetails?.state]);

  const {
    category,
    title,
    description,
    state,
    city,
    price,
    quantity,
    negotiable,
  } = serviceDetails;

  const [isLoading, setIsLoading] = useState(false);

  const [createService] = useMutation(CREATE_SERVICE);

  const handleCreateService = async () => {
    setIsLoading(true);

    try {
      const { data } = await createService({
        variables: {
          file: uploadedImages[0],
          payload: {
            title: title,
            category: category?._id,
            description: description,
            price: parseFloat(price),
            isNegotiable: negotiable,
            city: city?._id,
          },
        },
      });
      handleOpen();
      refetch();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting dispute:", error);
      if (error.message.includes("Failed to fetch")) {
        handleAlert(
          "File size too large. Please upload smaller images.",
          "error"
        );
      } else if (error.message.includes("Load failed")) {
        handleAlert(
          "File size too large. Please upload smaller images.",
          "error"
        );
      } else {
        // General error handling
        handleAlert(`${error.message}`, "error");
      }
    }
  };

  const {
    data: profile,
    error: profileErrror,
    loading: profileLoading,
  } = useQuery(GET_PROFILE);

  const { firstName, lastName, addresses, imageUrl } =
    profile?.getProfile?.profile || [];

  const [defaultAddress, setDefaultAddress] = useState([]);

  useEffect(() => {
    if (addresses) {
      const newAddress = addresses?.filter((item) => item?.isDefault === true);

      if (newAddress?.length > 0) {
        setDefaultAddress(newAddress[0]);
        setServiceDetails({
          ...serviceDetails,
          state: { _id: newAddress[0]?.state },
          city: { _id: newAddress[0]?.city },
        });
      } else {
        if (open) {
          setOpenModal2(true);
        }
      }
    }
  }, [addresses, defaultAddress, open]);

  return (
    <>
      {isLoading && <Loader />}
      <Drawer open={open} onClose={onClose} anchor="right">
        <Box
          sx={{
            width: { lg: "50vw", md: "50vw", sm: "80vw", xs: "95vw" },
            p: 5,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "20px", color: "#151515" }}
            >
              {activeStep === 1 ? "Add Service" : "Review Service"}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#151515" }}>
              <CloseOutlined />
            </IconButton>
          </Box>

          {activeStep === 1 && (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Upload Images
                </Typography>
                <Typography sx={{ mt: 0.4 }}>
                  First picture-is the title picture. Drag to order <br />
                  (maximum of 4 photos, Pictures may not exceed <b>5MB</b>)
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <UploadComponent
                  updateImages={updateUploadedImages}
                  initialImages={uploadedImages}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Title"
                        placeholder="Title"
                        fullWidth
                        margin="dense"
                        value={serviceDetails.title}
                        onChange={(e) =>
                          setServiceDetails({
                            ...serviceDetails,
                            title: e.target.value,
                          })
                        }
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Description"
                        placeholder="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="dense"
                        value={serviceDetails.description}
                        onChange={(e) =>
                          setServiceDetails({
                            ...serviceDetails,
                            description: e.target.value,
                          })
                        }
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Category"
                        placeholder="Select Category"
                        fullWidth
                        size="large"
                        margin="dense"
                        select
                        value={serviceDetails.category}
                        onChange={(e) =>
                          setServiceDetails({
                            ...serviceDetails,
                            category: e.target.value,
                          })
                        }
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      >
                        {categories?.fetchAllCategoriesAndTheirSubCategories?.map(
                          (cat, index) => (
                            <MenuItem
                              key={index}
                              value={cat?.category}
                              sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}
                            >
                              {cat?.category?.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Box>
                  </Grid>
                  {/* <Grid item md={6}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Pricing Option"
                        placeholder="Select pricing option"
                        fullWidth
                        select
                        size="large"
                        margin="dense"
                        type="number"
                        InputProps={
                          {
                            //                       disableUnderline: true,
                          }
                        }
                      />
                    </Box>
                  </Grid> */}
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Amount"
                        placeholder="Amount per service"
                        fullWidth
                        size="large"
                        margin="dense"
                        type="number"
                        value={serviceDetails.price}
                        onChange={(e) =>
                          setServiceDetails({
                            ...serviceDetails,
                            price: e.target.value,
                          })
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Checkbox />
                      <Typography variant="body2">
                        Mark if price is negotiable (optional)
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box align="right" sx={{ mt: 7 }}>
                  <Button
                    variant="contained"
                    disabled={
                      !title ||
                      !description ||
                      !price ||
                      !category ||
                      !city ||
                      !state ||
                      uploadedImages.length === 0
                    }
                    sx={{
                      width: "300px",
                      borderRadius: "50px",
                      height: "50px",
                    }}
                    onClick={() => setActiveStep(2)}
                  >
                    Proceed
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ height: "380px", display: "flex", columnGap: 2 }}>
                  <Box
                    className="hide_scrollbar"
                    sx={{
                      width: "75px",
                      height: "375px",
                      overflowY: "scroll",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {uploadedImages?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedPreviewImage(image)}
                        sx={{
                          background: `url('${image?.preview}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          height: "70px",
                          mb: 1.5,
                          width: "100%",
                          transition: "0.2s all linear",
                          flexShrink: 0,
                          ...(selectedPreviewImage === image && {
                            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('${image?.preview}')`,
                          }),
                        }}
                      />
                    ))}
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box
                    sx={{
                      flex: 1,
                      background: `url('${selectedPreviewImage?.preview}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "5px",
                      transition: "0.1s all linear",
                    }}
                  ></Box>
                </Box>

                <Box sx={{ mt: 3 }}>
                  {/* <Typography sx={{ fontSize: "12px", color: "#4D4D4D" }}>
                    Product Origin: <b> Florida</b>
                  </Typography> */}

                  <Typography variant="subtitle1" sx={{ fontSize: "25px" }}>
                    {title}
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <Typography sx={{}} variant="subtitle1">
                      ${parseFloat(price).toLocaleString()}
                    </Typography>
                    <Chip
                      label="Buy now"
                      sx={{
                        bgcolor: "#E9FCF9",
                        color: "#148B77",
                        fontWeight: 500,
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography>{`${city?.name}, ${state?.name}`}</Typography>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1">Description</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {description}
                    </Typography>
                  </Box>

                  <Divider sx={{ mt: 5 }} />
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant=""
                      sx={{
                        bgcolor: "#F3F3F3",
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                      onClick={handleCreateService}
                    >
                      Submit Product
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfettiExplosion
              duration={6000}
              zIndex={10000}
              particleSize={7}
              colors={["#EBDCEF", "#7B408C", "#1F1023", "#FFCD29", "#FFF9E5"]}
            />
            <img src={successIcon} width={80} />
            <Typography
              sx={{
                mt: 2,
                fontWeight: 700,
                color: "#151515",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Service Submitted
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              You'll receive an email and in-app notification once it's
              approved.
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                onClose();
                setActiveStep(1);
              }}
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openModal2}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={errorIcon} width={80} />
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: 900,
                color: "#151515",
              }}
            >
              Default Address Not Found
            </Typography>
            <Typography
              sx={{
                mt: 2,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              You need to create a default address or set an existing address as
              your default
            </Typography>

            <Button
              startIcon={<MdOutlineLocationOn />}
              variant="contained"
              onClick={() => {
                navigate("/account/address");
              }}
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              My Address
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddService;
