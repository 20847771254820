import React from 'react'
import { LoadingButton } from '@mui/lab';
import SaveIcon from "@mui/icons-material/Save";
import { Button } from '@mui/material';

const LoadButton = ({isLoading, action, text, text2}) => {
  return (
    <>
      {!isLoading && (
        <Button
          onClick={action}
          variant="contained"
          fullWidth
          sx={{ borderRadius: "20px", py: 1, mt: 3 }}
        >
       {text}
        </Button>
      )}

      {/* </Link> */}
      {isLoading && (
        <>
          <LoadingButton
            fullWidth
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled
            sx={{ borderRadius: "20px", py: 1, mt: 3 }}
          >
            {text2}
          </LoadingButton>
        </>
      )}
    </>
  );
}

export default LoadButton
