import React from 'react'
import { Outlet } from 'react-router-dom'

const MyOrders = () => {
  return (
<Outlet/>
  )
}

export default MyOrders
