import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import Footer from '../../Components/Common/Footer'
import Newsletter from '../../Components/Common/Newsletter'
import { Box, Button } from '@mui/material'
import noPageImg from '../../assets/404.svg'
import { Link } from 'react-router-dom'

const NoPage = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: 15,
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={noPageImg} alt="404_image" width={300} />
        <Link to="/">
          <Button variant="contained" sx={{ mt: 3 }}>
            Back to Home
          </Button>
        </Link>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
}

export default NoPage
