import { useState } from "react";
import { cartItemsVar } from "../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { REMOVE_CARTS } from "../Utils/Mutations";
import { useMutation } from "@apollo/client";

const useRemoveCarts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const cartItems = useReactiveVar(cartItemsVar);

  const [removeCart, { data }] = useMutation(REMOVE_CARTS);

//   let isInCart = cartItems?.some((item) => item._id === id);

  const handleRemoveCart = async (itemIds) => {

 

                              setIsLoading(true);
                              await removeCart({
                                variables:{itemIds},
                              })
                                .then((res) => {
                   
                                  setIsLoading(false);
                                  setSuccess(true);
                                  cartItemsVar(
                                    cartItems.filter((item) => item._id !== itemIds)
                                  );
                                })
                                .catch((err) => {
                                  console.error("Error Newe Cart", err);
                                  setIsLoading(false);
                                });

    
  };

  return { handleRemoveCart, isLoading, isSuccess };
};

export default useRemoveCarts;
