import React, { useState, useEffect } from "react";

const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    },4000);

    return () => clearInterval(interval);
  }, [images.length, 4000]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div
      className="carousel"
      style={{
        height: "400px",
        borderRadius: "8px",
        transition: "0.2s all linear",
      }}
    >
      <div className="carousel-images">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Slide ${index}`}
            className={`carousel-image ${
              index === activeIndex ? "active" : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
