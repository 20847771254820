import React, { useEffect } from "react";
// MUI
import { ThemeProvider } from "@mui/material";


// Providers n Actions
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { store, persistor } from "./store/store";

import { SnackbarProvider } from "notistack";

// Routes
import { Routes } from "./Routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessage } from "firebase/messaging";
import { messaging } from "./Utils/FirebaseConfig";
import { RequestNotificationPermission } from "./Utils/RequestNotificationPermission";

// Assets
import "./STYLES/main.css";
import ScrollToTop from "./ScrollToTop";
import Theme from "./STYLES/Theme";
import socket from "./Utils/socket";
import ErrorAlert from "./Components/Alert/ErrorAlert";

import WarningAlert from "./Components/Alert/WarningAlert";
import SuccessAlert from "./Components/Alert/SuccessAlert";

function App() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === true;
  useEffect(() => {
    socket.on("connect", () => {
      // console.log("Socket connected!");
    });

    return () => {
      // Cleanup on unmount
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    RequestNotificationPermission();

    // Handle messages received while the app is in the foreground
    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
      toast(payload.notification.body);
    });
  }, []);

  const triggerNotification = () => {
    // Manually trigger a notification for testing
    const payload = {
      notification: {
        title: "Test Notification",
        body: "This is a hard-coded test notification.",
      },
    };
    toast(payload.notification.body);
    new Notification(payload.notification.title, {
      body: payload.notification.body,
    });
  };
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={Theme}>
      
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                Components={{
                  error: ErrorAlert,
                  success: SuccessAlert,
                  warning: WarningAlert,
                }}
              >
                <ScrollToTop />
                {/* <button onClick={triggerNotification}>Trigger Notification</button>
      <ToastContainer /> */}
                <Routes />
              </SnackbarProvider>
      
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
