import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const Timeout = ({ duration, onTimeout }) => {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      onTimeout(); // Call the callback function when the timer reaches 0
    }
  }, [seconds, onTimeout]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Typography
      sx={{
        textAlign: "center",
        mt: 1,
        fontSize: "14px",
              color: "#666",
              fontWeight: 500,
        ml:1
      }}
    >
      {formatTime(seconds)}
    </Typography>
  );
};

export default Timeout;
