import {
  Avatar,
  Box,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import shippingIcon from "../../../assets/Icons/shipping.svg";
import returnIcon from "../../../assets/Icons/return.svg";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_PRODUCT_DESCRIPTION } from "../../../Utils/Queries";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import emptyIcon from '../../../assets/Icons/empty_history.svg'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Description = ({ productID }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);

  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCT_DESCRIPTION, {
    variables: {
      _id: productID, // Replace with the actual dynamic _id value
    },
  });

  const product = data?.listAllAvailableProductsWithoutAuthentication?.items[0];



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formattedDate, setFormattedDate] = useState("");

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <>
      <Box sx={{ margin: " 0 auto", width: "100%", bgcolor: "#fff", mt: 1 }}>
        <Box sx={{ padding: "24px 24px" }}>
          {isMobile ? (
            <>
              <Box>
                <div>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
                        Description
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                  <Box sx={{ my: -1.5, bgcolor:'#f5f5f5', p:2 }}>
                       <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#4d4d4d",
                          fontWeight: 500,
                          fontStyle: "italic",
                          lineHeight:'16px'
                        }}
                      >
                        {" "}
                        {product?.description}
                      </Typography>
                 </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
                        Reviews ( {product?.comments?.length} )
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{my: -1.5, bgcolor:'#f5f5f5', p:2 }}>
                        {
                          product?.comments?.length === 0 && (
                            <>
                            <Box sx={{my:2, display:'grid', placeItems:'center'}}>
                              <Typography sx={{fontWeight:500, fontSize:'12px'}}> No Review Available for this product</Typography>
                            </Box>
                            </>
                          )
                        }
                        {product?.comments?.map((comment, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      {/* <Avatar sx={{ width: "50px", height: "50px" }} /> */}
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                    
                          <Typography
                            sx={{
                              color: "#999",
                              fontSize: "10px",
                              fontWeight: 500,
                              lineHeight:'16px'
                            }}
                          >
                            {formatDate(comment?.createdAt)}
                          </Typography>
                        </Box>
                        <Rating readOnly value={comment?.rating} sx={{fontSize:'12px'}}/>
                        <Typography
                          sx={{ fontSize: "10px", color: "#666", mt:0.5 , fontWeight:400, lineHeight:'16px'}}
                        >
                          {comment?.content}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
                        Pick up Time
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{my: -1.5,  bgcolor:'#f5f5f5', p:2 }}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                          <img src={shippingIcon} alt="shipping_icon" />
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "12px",
                                fontWeight: 600,
                              }}
                            >
                              Delivery Time
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "10px", fontWeight:400 }}
                            >
                              Estimate delivery time 1-7 days
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
                        Return Policy & Warranty
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{my: -1.5, bgcolor:'#f5f5f5' , p:2}}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                          <img src={returnIcon} alt="shipping_icon" />
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "12px",
                                fontWeight: 600,
                              }}
                            >
                              7 Day Return Guarantee
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "10px" , lineHeight:'16px', fontWeight:400}}
                            >
                              For more information on the return shipping
                              options, go to{" "}
                              <Link to="/" style={{ color: "#2E1834" }}>
                                Mamaket Shipping Policy
                              </Link>
                            </Typography>
                                <Typography
                          sx={{
                            color: "#4d4d4d",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          Warranty information unavailable for this item.
                        </Typography>
                          </Box>
                        </Box>
                    
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
                        Seller Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ my: -1.5, bgcolor:'#f5f5f5', p:2 }}>
                        <Box sx={{ display: "flex", columnGap: 2, alignItems:'center' }}>
                          <Avatar sx={{ width: "40px", height: "40px" }}  src={product?.merchant?.imageUrl}/>
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {`${product?.merchant?.firstName} ${product?.merchant?.lastName}`}
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "10px", fontWeight:400 }}
                            >
                              {`${product?.merchant?.addresses[0]?.addressOne}, ${product?.merchant?.addresses[0]?.city?.name}, ${product?.merchant?.addresses[0]?.state?.name}, ${product?.merchant?.addresses[0]?.country?.name}  ${product?.merchant?.addresses[0]?.zipCode}.`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Description" />
                  <Tab label={`Review (${product?.comments?.length})`} />
                  <Tab label="  Pick up Time" />
                  <Tab label="Return Policy & Warranty" />
                  <Tab label="Seller Information" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#4d4d4d",
                    fontWeight: 400,
                    my: 1.5,
                  }}
                >
                  {" "}
                  {product?.description}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ my: 1.5 }}>
                  {
                    (!product?.comment && product?.comment?.length === 0) && (
                      <>
                      <Box sx={{my:1.5, }} align="center">
                        <img src={emptyIcon}/>
<Typography sx={{textAlign:'center', fontWeight:700, }}>No Reviews yet</Typography>
                      </Box>
                      </>
                    )
                  }
                  {product?.comments?.map((comment, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      {/* <Avatar sx={{ width: "50px", height: "50px" }} /> */}
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              color: "#1a1a1a",
                              fontSize: "15px",
                              fontWeight: 500,
                            }}
                          ></Typography>
                          <Typography
                            sx={{
                              color: "#999",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {formatDate(comment?.createdAt)}
                          </Typography>
                        </Box>
                        <Rating readOnly value={comment?.rating} size="small" />
                        <Typography
                          sx={{ fontSize: "13px", color: "#666", mt: -1 }}
                        >
                          {comment?.content}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <img src={shippingIcon} alt="shipping_icon" />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Delivery Time
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px" }}>
                        Estimate delivery time 1-7 days
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <img src={returnIcon} alt="shipping_icon" />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        7 Day Return Guarantee
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px" }}>
                        For more information on the return shipping options, go
                        to{" "}
                        <Link to="/" style={{ color: "#2E1834" }}>
                          Mamaket Shipping Policy
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{ color: "#4d4d4d", fontSize: "12px", fontWeight: 400 }}
                  >
                    Warranty information unavailable for this item.
                  </Typography>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <Avatar sx={{ width: "50px", height: "50px" }} src={product?.merchant?.imageUrl} />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        {`${product?.merchant?.firstName} ${product?.merchant?.lastName}`}
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px", fontWeight:400 }}>
                    {`${product?.merchant?.addresses[0]?.addressOne}, ${product?.merchant?.addresses[0]?.city?.name}, ${product?.merchant?.addresses[0]?.state?.name}, ${product?.merchant?.addresses[0]?.country?.name}  ${product?.merchant?.addresses[0]?.zipCode}.`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Description;
