import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Typography, Switch, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ACTIVE_NOTIFICATION, NOTIFICATION_TYPE } from "../../Utils/Queries";
import { UPDATE_NOTIFICATION } from "../../Utils/Mutations";

const Notification = () => {
  const [checked, setChecked] = React.useState(true);
  const [activeData, setActiveData] = useState(null)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [getNotificationTypes, { data, loading }] = useLazyQuery(
    NOTIFICATION_TYPE
  );

  useEffect(() => {
    getNotificationTypes();
    getActiveNotificationTypes();
  }, []);

  const [
    getActiveNotificationTypes,
    { data: activeNotification },
  ] = useLazyQuery(ACTIVE_NOTIFICATION);

  const [
    updateNotification,
    { data: update, loading: updateLoading },
  ] = useMutation(UPDATE_NOTIFICATION);

  const checkIdExists = (object, array) => {
    return array?.some((item) => item._id === object._id);
  };

  const filteredData = data?.getActiveNotificationTypes?.filter((item) =>
    checkIdExists(
      item,
      activeNotification?.fetchUserNotificationPreferences?.notifications
    )
  );

  const removeIdFromArray =( idToRemove) => {
  const index = activeData?.indexOf(idToRemove);
  let updatedArray = [];

  if (index !== -1) {
    // If the ID exists, remove it
    updatedArray = activeData?.filter(id => id !== idToRemove);
  } else {
    // If the ID doesn't exist, add it
    updatedArray = [...activeData, idToRemove];
  }

  setActiveData(updatedArray);
  return updatedArray;
};


  

  
useEffect(()=>{
  const transformedData =  activeNotification?.fetchUserNotificationPreferences?.notifications?.map(item => item._id);

  setActiveData(transformedData)
}, [data, activeNotification])


useEffect(()=>{
const handleUpdateNotification = () =>{
  updateNotification({
    variables:{
      notification:activeData
    }
  })
  .then((res)=>{

  }).catch((err)=>{

  })
}
handleUpdateNotification()
},[activeData])
  return (
    <>
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box>
          <Typography sx={{ color: "#333", fontWeight: 500, fontSize: "16px" }}>
            Notifcation
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box sx={{ mt: 3, p: 2 }}>
          {data?.getActiveNotificationTypes?.map((item, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
                >
                  {item?.title}
                </Typography>
                <Switch
                  checked={activeData?.some(
                    (noti) => noti === item?._id
                  )}
                  onChange={()=>removeIdFromArray(item?._id)}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Notification;
