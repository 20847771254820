import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { customInMemoryCache } from "./cache";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";




const URL = process.env.REACT_APP_GRAPHQL_URL ;


const httpLink = createUploadLink({
  uri: URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("mamaketUserToken") ;


  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
         "content-type": "application/json",
        'apollo-require-preflight': true,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: customInMemoryCache,
});

export default client;
