import { useLocation, useNavigate } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to add or update a query parameter
  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    // Update the URL without reloading the page
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // Function to remove a query parameter
  const removeQueryParam = (key) => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has(key)) {
      searchParams.delete(key); // Remove the parameter
    }

    // Update the URL without reloading the page
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return { addQueryParam, removeQueryParam };
};

export default useQueryParams;
