import React from "react";
import Navbar from "../../Components/Common/Navbar";
import { Box, Button, Typography } from "@mui/material";
import { NavData } from "../../assets/Data/NavData";
import { Link, useNavigate } from "react-router-dom";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../store/userSlice";

const MyAccount = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

    const handleLogOut = () => {
    navigate("/");
    dispatch(logoutUser());
  };
  return (
    <>
      <Navbar />
      <Box
        sx={{
          bgcolor: "#fff",
          width: { lg: "85%", md: "85%", sm: "95%", xs: "100%" },
          margin: "0 auto",
          mt: { lg: 14, md: 14, sm: 9, xs: 9 },
          px: 1.5,
          boxSizing: "border-box",
          pt: 4,
          pb: 3,
          mb: 4,
        }}
      >
        <Typography
          sx={{ fontWeight: 500, fontSize: "24px", color: "#101010" }}
        >
          My Account
        </Typography>
        <Box sx={{ mt: 3, border: "1px solid #F8F7F7", borderRadius: 3, p: 1 }}>
          {NavData.map((nav, index) => (
            <Link to={nav.link} key={index}>
              <Box
                sx={{
                  p: 2,
                  borderBottom: "0.8px solid #F8F7F7",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ color: "#333", fontWeight: 400, }}
                >
                  {nav.title}
                </Typography>
                <ChevronRightIcon sx={{ color: "#333" }} />
              </Box>
            </Link>
          ))}
          <Box
            align="center"
            sx={{ mt: 3, borderTop: "0px  solid #2E1834", pt: 2 }}
          >
            <Button
            onClick={handleLogOut}
              variant="contained"
              sx={{ px: 8, mr: 0, borderRadius: "100px", py:2  }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default MyAccount;
