import { Box, Chip, IconButton, Skeleton } from "@mui/material";
import React, { useRef, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES_N_SUB } from "../../Utils/Queries";
import { Link } from "react-router-dom";

const CategoriesBanner = () => {
  const [show, setShow] = useState(false);

  const containerRef = useRef(null);

  const handleScrollRight = () => {
    const container = containerRef.current;
    container.scrollBy({ left: 100, behavior: "smooth" });
    setShow(true);
  };
  const handleScrollLeft = () => {
    const container = containerRef.current;
    container.scrollBy({ left: -100, behavior: "smooth" });
  };

  const {loading, data, error} = useQuery(GET_CATEGORIES_N_SUB)

  return (
    <>
      <Box
        sx={{
          bgcolor: "#fff",
          p: {md:2, sm:2, xs:1},
          margin: "0 auto ",
              width: {xl:'75%', lg: "85%", md: "85%", sm: "90%", xs: "90%" },
          my: { lg: 3, md: 3, sm: 0, xs: 0 },
                   bgcolor:'#f6f7f8',
                   boxSizing:'border-box',
                   boxShadow:' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', borderRadius:'5px'
        }}
      >
        {/* <Typography variant='subtitle2'>Categories</Typography> */}
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
   
          }}
        >
          {loading ? (
            <>
              <Skeleton variant="rectangular" width={"100%"} height={15} />
            </>
          ) : (
            <>
              {data?.fetchAllCategoriesAndTheirSubCategories.map(
                (category, index) => (
                  <Link           key={index} to={`/category-view/${category.category.name}/${category.category._id}`}>
                    <Chip
            
                      label={category.category.name}
                      variant="outlined"
                      sx={{
                        mx: { xs: 1, sm: 1, md: 2, lg: 2 },
              
                        bgcolor: "#D7B9DF",
                        color: "#1F1023",
                        fontWeight: 500,
                        border: "none",
                        fontSize:{md:'12px', sm:'10px', xs:'10px'},
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor: "#F5EEF7",
                        },
                      }}
                    />
                  </Link>
                )
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            justifyContent: "space-between",
            mt: -4,
          }}
        >
          <Box>
            {show && (
              <IconButton
                onClick={handleScrollLeft}
                sx={{
                  border: "1px solid #2E1834",
                  height: "30px",
                  width: "30px",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    background: "#2E1834",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                />
              </IconButton>
            )}
          </Box>
          <IconButton
            onClick={handleScrollRight}
            sx={{ border: "1px solid #2E1834", height: "30px", width: "30px" }}
          >
            <KeyboardArrowRightIcon
              sx={{ background: "#2E1834", borderRadius: "50%", color: "#fff" }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default CategoriesBanner;
