import React from "react";
import FooterNavBar from "../Components/Common/FooterNavBar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Typography } from "@mui/material";
import Navbar from "../Components/Common/Navbar";

const ReturnPolicy = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },

          width: "85%",
          margin: "0 auto",
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 700, color: "#333" }}>
          Return Policy
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 2 , textAlign:'justify'}}
        >
          We are always excited whenever an order is successfully completed on
          our platform. And by ‘successfully completed’ we mean that you
          received and love your order.
          <br />
          <br /> However, we are also mindful that certain circumstances beyond
          your control might prevent this from happening. And if you are not
          100% satisfied, we have made our return policy extremely easy and
          within your control.
          <br />
          <br />
          In Mamaket, every transaction is completed when our buyers{" "}
          <strong>Release Funds</strong>. This means that our sellers don’t get
          paid until you are satisfied that you received what you ordered. Cool,
          right? But how does it all work?
          <br />
          <br />
          When you make payment for a product or service in our platform we hold
          the funds in trust. We wait for your permission to release the funds
          to the seller and to complete the transaction.
          <br />
          <br />
          You receive a<strong> Review &amp; Release Fund notification </strong>
          after the product has been successfully delivered and you have the
          option to dispute the order or to release funds.
          <br />
          <br />
          Clicking the Released Fund button notifies us of your satisfaction of
          the product and service indicating the completion of the transaction,
          while clicking the<strong> Dispute</strong> button tells us otherwise.
          <br />
          <br />
          You are required to fill-out an extra form telling us while you are
          disputing the order, including the pictures of the order you received.
          Once the dispute form has been successfully submit our team will
          review your concerns and based on our fair and equitable consideration
          will push the process to full <strong>refund</strong> and order
          reshipping.
          <br />
          <br />
          Buyers, however, have within two days after delivery to fill out the
          review &amp; release fund form or the system automatically releases
          funds and mark the transaction as completed. Because of the
          particularity of our products, we expect buyers to make sure their
          orders meet their satisfaction in form and in function and to tell us
          about it.
          <br />
          <br />
          Also, note that all returns will be shipped back to the vendors or
          manufacturers with exception of the following products: perishable
          food items. Based on the decision of our quality assurance team, a
          full refund can still be processed without reshipping of all
          perishable food items.
          <br />
          <br />
          You have until 30 days to return product after a dispute have been
          successfully decided.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
            Quality Check for Returns
          </Typography>
          When a return item arrives at our warehouse, we will inspect and/or
          test it to ensure it is return- eligible and meets the applicable
          return requirements listed above. If the item passes our quality
          check, we will issue a refund. If our inspection finds that the
          returned item is not return-eligible or does not meet the return
          requirements listed above, we will reject the return and refuse to
          issue a refund or credit.
          <br />
          <br />
          If the return item is rejected, we will send the item back to you at
          the same address that was specified in the original order and will
          deduct its return shipping fee from the amount of refund of other
          item(s) returned in the same order, if any. Currently, the return
          shipping fee for sending the rejected-return item to you is $7.99, and
          we reserve the right to adjust this fee.
          <br />
          <br />
          In certain cases, we reserve the right, at our sole discretion, to
          offer a refund on the rejected-return item, instead of sending it back
          to you.
          <br />
          <br />
          If you receive a damaged/defective item, please contact Mamaket
          Customer Service within 24 hours of receipt.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            Return Shipping Fee
          </Typography>
          Return Shipping is free on your 1st return package for any order. You
          must use the return label (&quot;Return Label&quot;) we provide (See
          &quot;Return Request Process&quot; section below for details), then
          send the return package to us postmarked within 35 days after the
          purchase date.
          <br />
          <br />
          If you have already returned item(s) from an order but would like to
          make additional returns of item(s) in the same order, you can use the
          Return Label we provided, and a $7.99 shipping fee will be deducted
          from your refund. Please note that additional returns must also be
          postmarked within 35 days after the purchase date.
          <br /> [11:34 PM]
          <br />
          <ul style={{ listStyleType: "square" }}>
            <li>Sign into your Mamaket User Account.</li>
            <li>
              Go to &quot;My Orders&quot; and find the order that contains the
              item(s) you would like to return and click &quot;Order
              Details&quot;.
            </li>
            <li>
              Then click the &quot;Return Item&quot; button, select the item(s)
              you would like to return, indicate the reason, and click
              &quot;Next Step.&quot; Please note that some items are
              non-returnable as specified above. Select the &quot;Refund
              Method.&quot; Please note that the Mamaket Wallet refund method is
              not currently available for Third-Party Sellers’ products.
            </li>
            <li>
              Select the &quot;Return Method&quot;: You can return either by
              USPS or UPS. You can use either the Return Label (Printer
              Required) or the QR Code (No Printer Required) that we provide.
              The Return Label and QR Code only work if you are shipping within
              the US.
            </li>
            <li>
              Pack your return item(s) securely, in the original package if
              possible, and securely tape the Return Label on the outside of
              your return package.
            </li>
            <li>
              Mail or ship the return package at your nearest Fedex (as
              applicable) location. Your return package must be postmarked
              within 35 days after the purchase date.
            </li>
          </ul>
          Please note that returned items must be correctly shipped using the
          correct Return Label or QR Code. If there are multiple Return Labels
          or QR Codes for multiple items to be returned, please do not mix them
          up.
          <br />
          <br />
          <strong> NOTE:</strong>
          <br /> Please do NOT send your return to the sender&#39;s address on
          your original package. This is not the return address and will affect
          the processing of your return. You should only send it to the address
          on the Return Label we provide.
          <br />
          <br />
          Please make sure you do not include any items that were not purchased
          on the Mamaket Site or App by mistake in your return package. We will
          not be responsible for sending those items back to you. To ensure a
          smooth and timely refund process, please make sure that the item(s)
          you are returning match(es) the details listed on your return request.
          Any discrepancies may cause delays or prevent us from being able to
          process your return.
          <br />
          <br />
          Locate the Generated Return Label
          <br />
          <br />
          Find the order containing the returned item(s) in the “My Orders”
          section of your User Account. → Click “Order Details” → Click “Return
          &amp; Refund Record” → Click “Details” → Click “Save the Label” to
          view and download the Return Label. [11:35 PM]
          <br /> Refunds
          <br />
          <br />
          Refunds will be issued only after a return-eligible item, postmarked
          within 35 days after the purchase date, has been received at our
          warehouse and has passed our quality check.
          <br />
          <br />
          For products that are returned in accordance with this Return Policy
          and pass our quality check, refunds will be processed within 7
          business days after we receive your return package. The refund will be
          issued to the original payment method or your Mamaket Wallet, as per
          your request.
          <br />
          <br />
          Unfortunately, due to transportation delays, your refund may require
          an additional 3-5 days to process. Thank you for your patience and
          understanding. The original shipping fee, if any, is non- refundable.
          <br />
          <br />
          NOTE: If you have any issues with your return, please contact Customer
          Support within 90 days after your purchase date.
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default ReturnPolicy;
