import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { PiBankFill } from "react-icons/pi";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { ADD_ACCOUNT } from "../../Utils/Mutations";
import { useSnackbar } from "notistack";
import Loader from "../../Components/Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {md:400, sm:400, xs:350},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  boxSizing: "border-box",
  borderRadius: "8px",
};

const AddAccount = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [account, setAccount] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
  });

  const {
    bankName,
    accountName,
    accountNumber,
    confirmAccountNumber,
    routingNumber,
  } = account;

  const [addAccount, { loading, error }] = useMutation(ADD_ACCOUNT);

  const handleAddAccount = async () => {
    // console.log(account);
    addAccount({
      variables: {
        bankName,
        accountName,
        accountNumber,
        routingNumber,
      },
    })
      .then((res) => {
        handleAlert(`Bank account added successfully!`, "success");
 handleClose()
 setAccount({
     bankName: "",
    accountName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
 })
      })
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  };

  if (error) {
    handleAlert(`${error.message}`, "error");
  }
  return (
    <>
    {
      loading && <Loader/>
    }
      <Box
        sx={{
          mt: 2,
          bgcolor: "#F5EEF7",
          p: 1,
          border: "1px solid",
          borderColor: "primary.main",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <Box
            sx={{
              bgcolor: "primary.main",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              display: "grid",
              placeContent: "center",
            }}
          >
            <PiBankFill style={{ color: "#fff" }} />
          </Box>
          <Typography sx={{ fontWeight: 700, color: "#151515", display:{md:'block', sm:'none', xs:'none'} }}>
            Add settlement account
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ fontSize: "10px", borderRadius: "50px" }}
        >
          Add Account
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle1">Add Account</Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ color: "#000" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Account Information</Typography>
            <Box sx={{}}>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                label="Bank Name"
                placeholder="Bank Name"
                value={bankName}
                onChange={(e) =>
                  setAccount({ ...account, bankName: e.target.value })
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                label="Account Number"
                placeholder="Account Number"
                type="number"
                value={accountNumber}
                onChange={(e) =>
                  setAccount({ ...account, accountNumber: e.target.value })
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                label="Confirm Account Number"
                placeholder="Confirm Account Number"
                type="number"
                error={accountNumber !== confirmAccountNumber}
               helperText={
    accountNumber !== confirmAccountNumber 
      ? "Account numbers do not match" 
      : "Please confirm your account number"
  }
                value={confirmAccountNumber}
                onChange={(e) =>
                  setAccount({
                    ...account,
                    confirmAccountNumber: e.target.value,
                  })
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                label="Account Name"
                placeholder=" Account Name"
                value={accountName}
                onChange={(e) =>
                  setAccount({ ...account, accountName: e.target.value })
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                label="Routing Number"
                placeholder="Routing Number"
                type="number"
                value={routingNumber}
                onChange={(e) =>
                  setAccount({ ...account, routingNumber: e.target.value })
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "grid", placeContent: "center", mt: 4 }}>
              <Button
                onClick={handleAddAccount}
                variant="contained"
                sx={{
                  width: "200px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  height: "45px",
                }}
                disabled={!accountName || !bankName || !routingNumber || !confirmAccountNumber || !accountName || (accountNumber !== confirmAccountNumber)}
              >
                Save Account
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAccount;
