import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { GET_COUNTRIES, GET_STATES, GET_CITY } from "../../../Utils/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../../Components/Common/Loader";
import { useSelector } from "react-redux";
import { EDIT_ADDRESS } from "../../../Utils/Mutations";
import { useSnackbar } from "notistack";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md:400, sm: 320, xs:310 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

const EditAddress = ({action, selectedAddress}) => {
             
    const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateID, setStateID] = useState("")
  const [cityID, setCityID] = useState("")
  const [selectedState, setSelectedState] = useState("");
  const [zip, setZip] = useState("")
  const [selectedCity, setSelectedCity] = useState("");
  const [countryID, setCountryID] = useState(null);
  const [getCountries, { loading, error, data }] = useLazyQuery(GET_COUNTRIES);
  const [getState, { loading: stateLoad, error: statError, data: stateData }] =
    useLazyQuery(GET_STATES);
  const [address, setAddress] = useState("")
  const { firstName, lastName, phone } = useSelector((state) => state.user?.user.profile)

    const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [getCity, { loading: cityLoad, error: cityError, data: cityData }] =
    useLazyQuery(GET_CITY);
      const [checked, setChecked] = React.useState(false);
  


  const handleChange2 = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleChange = (event) => {
    setSelectedState(event.target.value);
  };
  const handleChange3 = (event) => {
    setSelectedCity(event.target.value);
  };


    const handleChecked = (event) => {
      setChecked(event.target.checked);
    };
  useEffect(() => {
    getCountries();
    getState({
      variables: {
        country: countryID,
      }
    })
         getCity({
           variables: {
             state: stateID,
           },
         });
  }, [countryID, stateID]);

  const [addAddress, {loading:addLoading, data:addData}] = useMutation(EDIT_ADDRESS)

  const handleAddAddress = async () => {
    handleClose()
    await addAddress({
      variables: {
        firstName,
        lastName,
        country: countryID,
        state: stateID,
        city: cityID,
        addressOne: address,
        addressTwo: "",
        zipCode:zip,
        addressId:selectedAddress?._id,
        phone,
        isDefault:checked
      
        
      }
    }).then((response) => {

      action()
    handleAlert(`Address added successfully`, "success")
    }).catch((err) => {
    handleAlert(`${err.message}`, "error")

    })
  }

  useEffect(()=>{
                        setAddress(selectedAddress?.addressOne)
                        setCityID(selectedAddress.city?._id)
                        setSelectedCity(selectedAddress.city?.name)
                        setZip(selectedAddress?.zipCode)
                        setCountryID(selectedAddress?.country?._id)
                        setSelectedCountry(selectedAddress?.country?.name)
                        setSelectedState(selectedAddress?.state?.name)
                        setStateID(selectedAddress?.state?._id)
                        setChecked(selectedAddress?.
isDefault)
  }, [selectedAddress])
  return (
    <>
      {addLoading && <Loader />}
      {loading && <Loader />}
  
              <MenuItem
          sx={{ color: "#333", px: 4, borderBottom: "1px solid #F8F7F7" }}
           onClick={handleOpen}
        >
          <DriveFileRenameOutlineOutlinedIcon sx={{ mr: 1 }} />
          Edit
        </MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#333", fontSize: "20px" }}
            >
         Edit Address
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#333" }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{ color: "#4d4d4d", fontSize: "14px", fontWeight: 500 }}
            >
              {" "}
              Address
            </Typography>
            <TextField
              fullWidth
              placeholder="Address"
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              InputProps={{
                style: {
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                },
              }}
            />
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Select
                  fullWidth
                  value={selectedCountry}
                  onChange={handleChange2}
                  sx={{
                    fontSize: "13px",
                    // border: "0.5px solid #671E78",
        
                    borderRadius: "10px",
                  }}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontWeight: 600,
                      mt: 1.5,
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    Country
                  </MenuItem>
                  {data?.getAllCountries?.items.map(({ name, _id }) => (
                    <MenuItem
                      value={name}
                      onClick={(e) => {
                        setCountryID(_id);
                        setSelectedState("");
                        setSelectedCity("");
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Select
                  fullWidth
                  value={selectedState}
                  onChange={handleChange}
                  sx={{
                    fontSize: "13px",
                    // border: "0.5px solid #671E78",
        
                    borderRadius: "10px",
                  }}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontWeight: 600,
                      mt: 1.5,
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    {stateLoad ? "State Loading" : "State"}
                  </MenuItem>
                  {stateData?.fetchStates?.items.map(({ name, _id }) => (
                    <MenuItem
                      value={name}
                      sx={{ p: 1, display: "flex", alignItems: "center" }}
                      onClick={(e) => {
                        setStateID(_id);
                        setSelectedCity("");
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Select
                  fullWidth
                  value={selectedCity}
                  onChange={handleChange3}
                  sx={{
                    fontSize: "13px",
                    // border: "0.5px solid #671E78",
        
                    borderRadius: "10px",
                  }}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontWeight: 600,
                      mt: 1.5,
                    },
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    {cityLoad ? "City Loading" : "City"}
                  </MenuItem>
                  {cityData?.fetchCities?.items.map(({ name, _id }) => (
                    <MenuItem
                      value={name}
                      sx={{ p: 1, display: "flex", alignItems: "center" }}
                      onClick={(e) => {
                        setCityID(_id);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  fullWidth
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  placeholder="Zip code"
                  InputProps={{
                       inputMode:'numeric', pattern:'[0-9]*',
                    style: {
                  
                      mt: 1.5,
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
              <Box>
                <Checkbox checked={checked} onChange={handleChecked} />
                <Typography variant="caption">
                  Make this as the default shipping address
                </Typography>
              </Box>
              <Button
                disabled={!selectedCity || !zip}
                variant="contained"
                fullWidth
                onClick={handleAddAddress}
                sx={{ borderRadius: "100px", mt: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditAddress;
