import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import successIcon from "../../assets/Icons/security.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import { PinSchema } from "../../Utils/Schema";
import { CREATE_PIN } from "../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  pt: 6,
};

const Verify = () => {
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const userID = useSelector((state) => state.user.uuid);
  const [createPinMutation, { loading, error, data }] = useMutation(CREATE_PIN);

  React.useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      pin: "",
      confirmPin: "",
    },
    validationSchema: PinSchema,
    onSubmit: () => {
      handleCreatePin();
    },
  });
  const handleCreatePin = async () => {
    try {
      const variables = {
        userId: userID,
        transactionPin: values.pin,
      };

      const { data } = await createPinMutation({
        variables,
      });

      setOpen(true);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error(error);
    }
  };
  return (
    <>
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Create Transaction PIN
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 400, fontSize: "14px" }}>
            Create a 4 digit pin to authorize all transactions.
          </Typography>

          <Box sx={{ mt: 3 }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              Enter 4-digit code
            </InputLabel>
            <TextField
              error={errors.pin}
              helperText={errors.pin}
              id="pin"
              value={values.pin}
              onChange={handleChange}
              onBlur={handleBlur}
              size="large"
              fullWidth
           
              InputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: {
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mt: 1,
              }}
            >
              Confirm 4 digit Code
            </InputLabel>
            <TextField
              error={errors.confirmPin}
              helperText={errors.confirmPin}
              id="confirmPin"
              value={values.confirmPin}
              onChange={handleChange}
              onBlur={handleBlur}
              //   placeholder="Email Address"
              size="large"
              fullWidth
         
              InputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: {
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            {error && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,

                  fontSize: "12px",
                  mt: 2,
                }}
              >
                {error.message ===
                "Profile already completed. Kindly login instead." ? (
                  <>
                    Profile already completed. Kindly login instead{" "}
                    <Link
                      to="/login"
                      style={{ fontWeight: 500, color: "#333" }}
                    >
                      Login
                    </Link>
                  </>
                ) : (
                  error.message
                )}
              </Typography>
            )}
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{ borderRadius: "20px", py: 1, mt: 3 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} align="center">
            <img src={successIcon} alt="success" width={100} />

            <Typography
              sx={{
                fontWeight: 600,
                color: "#1a1a1a",
                fontSize: "20px",
                mt: 4,
              }}
            >
              {data?.createTransactionPinOnSignUp?.message}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                color: "#1a1a1a",
                fontSize: "12px",
                mt: 1,
              }}
            >
              Proceed to enjoy your shopping experience
            </Typography>
            {/* <Typography sx={{fontWeight:200, color:'#1a1a1a', fontSize:'13px',mt:-1 }}>Proceed to create transaction PIN</Typography> */}
            <Link to="/login">
              <Button
                variant="contained"
                fullWidth
                sx={{ py: 1, mt: 3, borderRadius: "50px" }}
              >
                Proceed
              </Button>
            </Link>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Verify;
