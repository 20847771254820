import {
  Avatar,
  Badge,
  Box,
  Chip,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import socket from "../Utils/socket";
import emptyList from "../assets/Icons/emptylist.svg";
import { useDispatch } from "react-redux";

const ChatList = () => {
  const router = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [list, setList] = useState(null);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(true);

  const MY_OFFERS = "myUserList";
  const GETOFFERS = "getMyUserList";

  useEffect(() => {
    // Ensure loading is true initially
    setLoading(true);

    if (!socket.connected) {
      socket.connect();
    }

    socket.emit(GETOFFERS);

    socket.on(MY_OFFERS, (data) => {
      setLoading(false); // Stop loading

      if (!data || data.length === 0) {
        setEmpty(true);
        setList([]);
      } else {
        setEmpty(false);
        setList(data);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off(MY_OFFERS);
    };
  }, []); // Only run once when the component mounts

 const formatDate = (inputDate) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };


  return (
    <Box
      className="hide_scrollbar"
      sx={{
        overflow: "scroll",
        height: "100%",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {/* Show Empty State when list is empty */}
      {empty && !loading && (
        <Box
          sx={{
            height: { lg: "50vh", md: "50vh", sm: "auto", xs: "auto" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={emptyList} alt="No Chats Available" />
        </Box>
      )}

      {/* Show skeleton while loading */}
      {loading && (
        <>
          {[...Array(5)].map((_, index) => (
            <Box key={index} sx={{ py: 2, display: "flex", columnGap: 2 }}>
              <Skeleton variant="circular" width={45} height={45} />
              <Box sx={{ width: "70%" }}>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "0.5rem", width: "50%" }} />
              </Box>
            </Box>
          ))}
        </>
      )}

      {/* Render the chat list if available */}
      {!loading && list?.length > 0 && (
        <List>
          {list.map((item, index) => (
            <Link
              key={index}
              to={
                isMobile
                  ? `/chat-mobile/${item?.roomChannel}`
                  : `/chat/${item?.roomChannel}?name=${item?.user?.firstName} ${item?.user?.lastName}`
              }
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <ListItemButton
                selected={router.pathname === `/chat/${item?.roomChannel}`}
                sx={{
                  borderBottom: "1px solid #F2F2F2",
                  cursor: "pointer",
                  "&.Mui-selected, :hover": {
                    bgcolor: "#EBDCEF",
                    borderLeft: "4px solid #2E1834",
                  },
                }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={2} sx={{ display: "flex", placeItems: "center" }}>
                    <Avatar sx={{ width: "40px", height: "40px" }}>
                      {item?.user?.imageUrl ? (
                        <img src={item?.user.imageUrl} width={"100%"} alt="User" />
                      ) : (
                        item?.user?.firstName?.charAt(0)
                      )}
                    </Avatar>
                  </Grid>
                  <Grid item lg={8} sx={{ pl: 2 }}>
                    <InputLabel
                      sx={{
                        color: "#000",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      {` ${item?.user?.firstName} ${item?.user?.lastName}`}
                    </InputLabel>

                    <InputLabel
                      sx={{
                        color: "#4d4d4d",
                        fontWeight: 400,
                        fontSize: "10px",
                        mt: 0.3,
                      }}
                    >
                      {item?.lastMessage?.message.length > 30
                        ? `${item?.lastMessage?.message.substring(0, 30)}...`
                        : item?.lastMessage?.message}
                    </InputLabel>
                  </Grid>
                  <Grid item lg={2} align="right">
                    {/* <Typography sx={{ fontSize: "5px" }}>
             {formatDate(item?.lastMessage?.updatedAt)}
                    </Typography> */}
                    <Badge color="primary" badgeContent={item?.chat || 0} />
                  </Grid>
                </Grid>
              </ListItemButton>
            </Link>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ChatList;
