import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import Newsletter from "../../Components/Common/Newsletter";
import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import { RiSearchLine } from "react-icons/ri";
import StatusCode from "../../Utils/StatusCode";
import Loader from "../../Components/Common/Loader";
import { Link } from "react-router-dom";
import ProductsCard from "../../Components/Cards/ProductsCard";

const LatestProduct = () => {
  const { data, status } = useSelector((state) => state.products);

  const [filteredProducts, setFilteredProduct] = useState([])

  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Navbar />
      <Box
        sx={{
          mt: { lg: 14, md: 13, sm: 8, xs: 8 },

          background:
            "linear-gradient(356deg, rgba(255,255,255,1) 0%, rgba(215,185,223,1) 48%, rgba(46,24,52,1) 100%)",
          height: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ color: "#2e1834", fontWeight: 700, fontSize: "30px" }}
        >
          Latest Products
        </Typography>

        {/* <TextField
          margin="dense"
          sx={{ width: {md:"50%", sm:'70%', xs:'85%'} }}
          placeholder="Search for Product Name"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <RiSearchLine style={{ color: "#2e1834" }} />
                </InputAdornment>
              ),
              style: {
                color: "#2e1834",
                borderRadius: "8px",
                background: "#fff",
              },
            },
          }}
        /> */}
      </Box>
      <Divider />
      <Box sx={{ py: 2,    margin: "0 auto",
          width: { xl: "75%", lg: "85%", md: "87%", sm: "90%", xs: "95%" }, }}>
                        <Link to="/">
                          <Button startIcon={<IoIosArrowRoundBack />} sx={{ fontSize: "12px" }}>
            Back to homepage
          </Button>
                        </Link>
        
        </Box>
      <Box
        sx={{
          pb: 4,
          margin: "0 auto",
          width: { xl: "75%", lg: "85%", md: "87%", sm: "90%", xs: "95%" },
          height: "60vh",
          overflowY: "scroll",
        }}
        className="hide_scrollbar"
      >
        
        <Grid container spacing={2}>
          {data.map((product, index) => (
            <Grid item md={3} sm={3} xs={6}>
              <Link to={`/product-view/${product._id}`} key={index}>
                <ProductsCard
                  title={product.name}
                  image={product.images && product.images[0]}
                  price={product.price.toLocaleString()}
                  flag={product?.country?.flagUrl}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Newsletter />
      {/* <Footer /> */}
    </>
  );
};

export default LatestProduct;
