import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  profile: {}, // Add a profile object here to store profile data
  isAuthenticated: false,
  uuid: null,
  email: null,
  userID: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setUserId: (state, action) => {
      state.uuid = action.payload;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
    setUserProfile: (state, action) => {
      state.profile = {
        ...state.profile, // Keep other profile fields unchanged
        ...action.payload, // Update only the fields provided in the action payload
      };
    },
    logoutUser: (state) => {
      state.user = null;
      state.uuid = null;
      state.profile = {}; // Clear profile on logout
      state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", "false");
    },
  },
});

export const {
  setUser,
  logoutUser,
  setUserId,
  setUserEmail,
  setUserID,
  setUserProfile, // Export the new action
} = userSlice.actions;

export default userSlice.reducer;
