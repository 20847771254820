import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  Switch,
  Rating,
} from "@mui/material";
import { GoArrowLeft } from "react-icons/go";
import { RiSearch2Line } from "react-icons/ri";
import EmptyData from "../../Components/Common/EmptyData";
import EditProduct from "./EditProduct";
import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT } from "../../Utils/Mutations";
import Modal from "@mui/material/Modal";
import { MdRemoveCircle } from "react-icons/md";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxSizing: "border-box",
  boxShadow: 24,
  p: 4,
};

const Overview = ({ handleBack, data, refetch }) => {
  const [productData, setProductData] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [deleteProduct, { loading, error, data: deleteRes }] = useMutation(
    DELETE_PRODUCT
  );

  const handleDelete = async () => {
    try {
      await deleteProduct({
        variables: {
          productId: data?._id,
        },
      });
      handleClose();
      handleBack();
      refetch();
      handleAlert(`${deleteRes?.deleteProduct?.message}`, "success");
    } catch (error) {
      handleAlert(`${error.message}`, "error");

    }
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <>
      {loading && <Loader />}
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <IconButton onClick={handleBack}>
            <GoArrowLeft />
          </IconButton>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: { md: "16px", sm: "14px", xs: "14px" } }}
            >
              Product Details
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "12px", md: "12px", sm: "10px", xs: "10px" },
              }}
            >
              Product / <b>{data?._id}</b>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <Box
                sx={{
                  bgcolor: "#FBFBFB",
                  border: "1px solid #F0F2F4",
                  p: 2,
                  boxSizing: "border-box",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body2">Details</Typography>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    {data?.images?.map((image, index) => (
                      <Box
                        sx={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "8px",

                          border: "0.5px solid #dedede",
                          background: `url('${image}')`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      />
                    ))}
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography>Product Origin:</Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "25px", mt: 1 }}
                    >
                      {data?.name}
                    </Typography>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Typography sx={{}} variant="subtitle1">
                        ${parseFloat(data?.price).toLocaleString()}
                      </Typography>
                      <Chip
                        label={data?.isNegotiable ? "Negotiable" : "Buy now"}
                        sx={{
                          bgcolor: "#E9FCF9",
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography>City, State</Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle1">Description</Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, fontWeight: 400, textAlign: "justify" }}
                      >
                        {data?.description}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                      <Typography>Category</Typography>
                      <Typography variant="body2">
                        {data?.categories[0]?.name}
                      </Typography>
                      <Typography sx={{ mt: 1 }}>Stock Quantity</Typography>
                      <Typography variant="body2">
                        {data?.quantityInStock}
                      </Typography>
                      <Typography sx={{ mt: 1 }}>Sold</Typography>
                      <Typography variant="body2">100</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ mt: 1 }} variant="body2">
                          Negotiable:
                        </Typography>
                        <Switch checked={data?.isNegotiable} />
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Typography>Status:</Typography>
                      <Chip
                        label="Active"
                        sx={{ bgcolor: "#F0FDF4", color: "#22C55E" }}
                      />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography>Late Updated</Typography>
                      <Typography variant="body2">
                        {" "}
                        {formatDate(data?.updatedAt)}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {data?.status === "CREATED" ||
                        (data?.status === "REJECTED" && (
                          <Button
                            onClick={handleOpen}
                            variant="outlined"
                            sx={{
                              color: "#FF3347",
                              borderColor: "#FF3347",
                              width: "40%",
                              borderRadius: "50px",
                              height: "50px",
                            }}
                          >
                            Delete
                          </Button>
                        ))}

                      <Button
                        onClick={() => setOpenDrawer(true)}
                        sx={{
                          color: "#fff",
                          bgcolor: "#151515",
                          width: "40%",
                          borderRadius: "50px",
                          height: "53px",
                          fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}
                        }}
                      >
                        Edit Product
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              {/* <Box
                sx={{
                  border: "1px solid #E2E5E9",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #F0F2F4",
                    bgcolor: "#FBFBFB",
                    borderRadius: "8px",
                    p: 1,
                  }}
                >
                  <Typography variant="subtitle1">Orders</Typography>
                </Box>

                <Box
                  sx={{
                    mt: 1,
                    bgcolor: "#F7F6F6",
                    p: 2,
                    boxSizing: "border-box",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Typography>Filter:</Typography>
                      <TextField
                        select
                        size="small"
                        sx={{ width: "120px", bgcolor: "#fff" }}
                      ></TextField>
                    </Box>
                    <TextField
                      sx={{ width: "35%", bgcolor: "#fff" }}
                      size="small"
                      placeholder="Search Products"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiSearch2Line />
                          </InputAdornment>
                        ),
                        style: {
                          bgcolor: "#fff",
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: 2,
                      bgcolor: "#fff",
                      border: "1px solid #E4E4E7",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      p: 0.5,
                    }}
                  >
                    {!productData || productData?.length === 0 ? (
                      <>
                        <Box
                          sx={{
                            height: "400px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <EmptyData
                            title="You have no orders yett"
                            label={"This is where you’ll see orders.."}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Table>
                          <TableHead sx={{ bgcolor: "#FAFAFA" }}>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Title</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Stock</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productData?.map((product, index) => (
                              <TableRow>
                                <TableCell>{product?.id}</TableCell>
                                <TableCell>{product?.name}</TableCell>
                                <TableCell>{product?.price}</TableCell>
                                <TableCell>{product?.quantity}</TableCell>
                                <TableCell>{product?.status}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </Box>
                </Box>
              </Box> */}
              <Box
                sx={{
                  border: "1px solid #E2E5E9",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #F0F2F4",
                    bgcolor: "#FBFBFB",
                    borderRadius: "8px",
                    p: 1,
                  }}
                >
                  <Typography variant="subtitle1">Comments</Typography>
                </Box>
                <Box
                  sx={{
                    mt: 1,
                    bgcolor: "#F7F6F6",
                    p: 2,
                    boxSizing: "border-box",
                    borderRadius: "8px",
                  }}
                >
                  <Box>
                    {data?.comments?.length === 0 ? (
                      <>
                        <Box
                          sx={{
                            height: "400px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <EmptyData
                            title="No Reviews yet"
                            // label={"This is where you’ll see orders.."}
                          />
                        </Box>
                      </>
                    ) : (
                      data?.comments?.map((comment, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          {/* <Avatar sx={{ width: "50px", height: "50px" }} /> */}
                          <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  color: "#999",
                                  fontSize: "10px",
                                  fontWeight: 500,
                                  lineHeight: "16px",
                                }}
                              >
                                {formatDate(comment?.createdAt)}
                              </Typography>
                            </Box>
                            <Rating
                              readOnly
                              value={comment?.rating}
                              sx={{ fontSize: "12px" }}
                            />
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "#666",
                                mt: 0.5,
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              {comment?.content}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <MdRemoveCircle style={{ color: "#FF3347", fontSize: "80px" }} /> */}

            <Typography variant="subtitle1" sx={{ mt: 2, textAlign: "center" }}>
              Are you sure you want to remove this product?
            </Typography>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: 3,
                width: "100%",
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                fullWidth
                sx={{
                  fontSize: "12px",
                  borderRadius: "50px",
                  fontWeight: 400,
                  py: 1.5,
                  color: "#FF3347",
                  borderColor: "#FF3347",
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => handleDelete()}
                variant="contained"
                fullWidth
                sx={{
                  fontSize: "12px",
                  borderRadius: "50px",
                  fontWeight: 400,
                  py: 1.5,
                  bgcolor: "#FF3347",
                }}
              >
                Yes, Remove
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <EditProduct
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        data={data}
        refetch={() => refetch()}
      />
      
    </>
  );
};

export default Overview;
