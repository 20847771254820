import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import successIcon from "../../assets/Icons/sell/5.svg";

import React, { act, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImImages } from "react-icons/im";
import { HiViewfinderCircle } from "react-icons/hi2";
import { RiDeleteBin2Line } from "react-icons/ri";
import ConfettiExplosion from "react-confetti-explosion";
import { GET_CATEGORIES_N_SUB, GET_PRODUCT_ORIGIN } from "../../Utils/Queries";
import { GET_COUNTRIES, GET_STATES, GET_CITY } from "../../Utils/Queries";
import UploadComponent from "../Products/UploadComponent";
import { CREATE_PRODUCT, DELETE_SERVICE_IMAGE, EDIT_SERVICE, EDIT_SERVICE_IMAGE } from "../../Utils/Mutations";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const EditService = ({ open, onClose, data , refetch}) => {
  const { enqueueSnackbar } = useSnackbar();

  const country_id = "65fe9e4dd5a2f2d3f3021a0b";
  const [activeStep, setActiveStep] = useState(1);

  console.log(data, "New Data");

  const [uploadedImages, setUploadedImages] = useState(null);

  const [openModal, setOpenModal] = React.useState(false);

  const [isExploding, setIsExploding] = React.useState(false);

  const [serviceDetails, setserviceDetails] = useState({
    category: "",
    title: "",
    description: "",
    state: "",
    city: "",
    price: "",
    quantity: "",
    negotiable: false,
  });

  const updateUploadedImages = (images) => {
    setUploadedImages(images);
  };

  useEffect(() => {
    setserviceDetails({
      ...serviceDetails,
      description: data?.description,
      title: data?.description,
      price: data?.price,
      negotiable: data?.isNegotiable,
      city: data?.city?._id,
      state: data?.city?.state?._id,
      category: data?.category,
    });
        setExistingImages(data?.images);
  }, [data]);

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);

  useEffect(() => {
    if (uploadedImages) {
      setSelectedPreviewImage(uploadedImages[0]);
    }
  }, [uploadedImages]);

  const handleOpen = () => {
    setIsExploding(true);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setIsExploding(false);
  };

  const { loading: loadingCategories, data: categories, error } = useQuery(
    GET_CATEGORIES_N_SUB
  );






  const {
    category,
    title,
    description,
    state,
    city,
    price,

    negotiable,
  } = serviceDetails;

  const [isLoading, setIsLoading] = useState(false);

  const [editService] = useMutation(EDIT_SERVICE);



  const [selectedImage, setSelectedImage] = useState(null);
  // ////////////  EVERYTHING IMAGES

   const [existingImages, setExistingImages] = useState([]);
  const [images, setImages] = useState([]);
  const generateId = () => {
    return Date.now() + Math.random().toString(36).substr(2, 9);
  };

  const onDrop = (acceptedFiles) => {
    setImages((prevImages) => {
      // If the number of previously uploaded images + new files exceeds 4, limit the upload
      const totalImages = prevImages.length + acceptedFiles.length;
      if (totalImages > 4 - existingImages?.length) {
        const remainingSlots = 4 - existingImages?.length - prevImages.length;
        const limitedFiles = acceptedFiles.slice(0, remainingSlots);

        const imageFiles = limitedFiles.map((file) =>
          Object.assign(file, {
            id: generateId(), // Assign a unique ID to each file
            preview: URL.createObjectURL(file), // Generate a preview URL for the image
          })
        );
        return [...prevImages, ...imageFiles];
      }

      // If the total is less than or equal to 4, allow all the files
      const imageFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          id: generateId(), // Assign a unique ID to each file
          preview: URL.createObjectURL(file), // Generate a preview URL for the image
        })
      );
      return [...prevImages, ...imageFiles];
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [], // Accept .jpg and .jpeg
      "image/jpg": [],
      "image/svg+xml": [], // Accept .svg files
    },
  });
  const deleteImage = (idToDelete) => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.id !== idToDelete)
    );
  };

  const [editProductImage] = useMutation(EDIT_SERVICE_IMAGE);

  const [deleteExistImage] = useMutation(DELETE_SERVICE_IMAGE);

  const [existSelected, setExistSelected] = useState(null);

  const handleEditProductImage = async () => {
    setIsLoading(true);

    await editProductImage({
      variables: {
        files: images,
        serviceId: data?._id,
      },
    })
      .then((res) => {
        handleAlert(`${res?.data?.addMoreServiceImages?.message}`, "success");
        setIsLoading(false);
        setImages([])
      })
      .catch((err) => {
        console.log(err);
        handleAlert(`${error?.message}`, "error");
        setIsLoading(false);
      });
  };

  const handleDeleteExistingImage = async () => {
    setIsLoading(true);
    const newImages = existingImages.filter((image) => image !== existSelected);
    // console.log(newImages, existingImages)

    await deleteExistImage({
      variables: {
        serviceId: data?._id,
        images: [existSelected],
      },
    })
      .then((res) => {
        handleAlert(
          `${res?.data?.deleteServiceImages?.message}`,
          "success"
        );
        setIsLoading(false);
        setExistingImages(newImages);
      })
      .catch((err) => {
        // console.log(err);
        handleAlert(`${error?.message}`, "error");
        setIsLoading(false);
      });
  };


    const handleEditService = async () => {
    setIsLoading(true);

    try {
  

    await editService({
      variables: {
        serviceId: data?._id || "", // Added fallback for undefined serviceId
        title: title || "",          // Added fallback for undefined title
        category: category?._id || "", // Added fallback for undefined category
        description: description || "", // Added fallback for undefined description
        price: parseFloat(price) || 0, // Added fallback for invalid price
        isNegotiable: negotiable || false, // Added fallback for undefined negotiable flag
        city: data?.city?._id || ""  // Added fallback for undefined city
      }
    });
      handleOpen();
   refetch();
      onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting dispute:", error);
      handleAlert(`${error.message}`, "error");
    }
  };

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="right">
        {isLoading && <Loader />}
        <Box
          sx={{
            width: { lg: "50vw", md: "50vw", sm: "80vw", xs: "95vw" },
            p: 5,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "20px", color: "#151515" }}
            >
              {activeStep === 1 ? "Edit Service" : "Review Product"}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#151515" }}>
              <CloseOutlined />
            </IconButton>
          </Box>

          {activeStep === 1 && (
            <>
                  <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Upload Images
                </Typography>
                <Typography sx={{ mt: 0.4 }}>
                  First picture-is the title picture. Drag to order <br />
                  (maximum of 4 photos, Pictures may not exceed <b>5MB</b>)
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <>
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      height: "150px",

                      borderRadius: "10px",
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      overflowX: "scroll",
                      overflowY: "hidden",

                      boxSizing: "border-box",
                      columnGap: 2,

                      "&::-webkit-scrollbar": {
                        borderRadius: "50px",
                        height: "5px",
                        // Set width of the scrollbar
                      },

                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#EBDCEF", // Style the thumb (scrollbar handle)
                        borderRadius: "50px",
                        width: "5px",
                        // Round the corners of the thumb
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: "0.5px dashed #C296CF",
                        boxSizing: "border-box",
                        width: "110px",
                        p: 1,
                        borderRadius: "8px",
                        height: "130px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        flexShrink: 0,
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <ImImages style={{ color: "#AE73BF" }} />
                      <Typography sx={{ fontSize: "8px", mt: 0.5 }}>
                        Add More Images..
                      </Typography>
                    </Box>
                    {existingImages?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setExistSelected(image)}
                        sx={{
                          border: "1px solid #EBEAED",
                          boxSizing: "border-box",
                          width: "110px",
                          p: 1,
                          borderRadius: "8px",
                          height: "130px",
                          display: "grid",
                          placeContent: "center",
                          transition: "0.1s all linear",
                          cursor: "pointer",
                          // opacity: 0.5,
                          ...(existSelected === image && {
                            border: "1.5px solid #7B408C",
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            background: `url('${image}')`,
                            width: "100px",
                            height: "120px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#FF3347",
                              fontSize: "8px",
                              fontWeight: 500,
                              bgcolor: "#fff",
                              width: "fit-content",
                              p: 0.2,
                            }}
                          >
                            Existing
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                    {images?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedImage(image)}
                        sx={{
                          border: "1px solid #EBEAED",
                          boxSizing: "border-box",
                          width: "110px",
                          p: 1,
                          borderRadius: "8px",
                          height: "130px",
                          display: "grid",
                          placeContent: "center",
                          transition: "0.1s all linear",
                          cursor: "pointer",
                          ...(selectedImage === image && {
                            border: "1.5px solid #7B408C",
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            background: `url('${image?.preview}')`,
                            width: "100px",
                            height: "120px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#178c78",
                              fontSize: "8px",
                              fontWeight: 500,
                              bgcolor: "#fff",
                              width: "fit-content",
                              p: 0.2,
                            }}
                          >
                            New
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                      rowGap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #EBEAED",
                        mt: 2,
                        width: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        //               p:1,
                        borderRadius: "5px",
                        boxSizing: "border-box",
                      }}
                    >
                      {/* <IconButton
                        disabled={selectedImage === null}
                        onClick={() => {
                          // handleOpen();
                        }}
                      >
                        <HiViewfinderCircle style={{ fontSize: "16px" }} />
                      </IconButton>{" "} */}

                      <IconButton
                        disabled={selectedImage === null}
                        onClick={() => deleteImage(selectedImage?.id)}
                      >
                        <RiDeleteBin2Line
                          style={{ fontSize: "16px", color: "#FF3347" }}
                        />
                      </IconButton>
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        sx={{ fontSize: "10px" }}
                        disabled={!images || images.length === 0}
                        onClick={handleEditProductImage}
                      >
                        Update more images
                      </Button>
                      <Button
                        onClick={handleDeleteExistingImage}
                        disabled={!existSelected}
                        variant="contained"
                        sx={{ fontSize: "10px", ml: 3, bgcolor: "#FF3347" }}
                      >
                        Delete Existing Image
                      </Button>
                    </Box>
                  </Box>

                  {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={selectedImage?.preview} width={"60%"} />
                        <Box sx={{ width: "40%", flex: 1 }}>
                          <Box align="center">
                            <IconButton
                              onClick={handleClose}
                              sx={{
                                bgcolor: "#fff",
                                mb: 2,
                                width: "70px",
                                height: "70px",
                              }}
                            >
                              <CloseOutlined />
                            </IconButton>
                          </Box>
                          <ProductsCard
                            image={selectedImage?.preview}
                            price={"00.00"}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Modal> */}
                </>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Title"
                        fullWidth
                        margin="dense"
                        value={serviceDetails.title}
                        onChange={(e) =>
                          setserviceDetails({
                            ...serviceDetails,
                            title: e.target.value,
                          })
                        }
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="dense"
                        value={serviceDetails.description}
                        onChange={(e) =>
                          setserviceDetails({
                            ...serviceDetails,
                            description: e.target.value,
                          })
                        }
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Category"
                        fullWidth
                        value={serviceDetails.category?.name}
                        size="large"
                        margin="dense"
                        select
                        slotProps={{
                          input: {
                            style: {
                              borderRadius: "8px",
                            },
                          },
                        }}
                      >
                        {categories?.fetchAllCategoriesAndTheirSubCategories?.map(
                          (cat, index) => (
                            <MenuItem
                              key={index}
                              onClick={() =>
                                setserviceDetails({
                                  ...serviceDetails,
                                  category: cat?.category,
                                })
                              }
                              value={cat?.category?.name}
                              sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}
                            >
                              {cat?.category?.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Price"
                        fullWidth
                        size="large"
                        type="number"
                        margin="dense"
                        value={serviceDetails.price}
                        onChange={(e) =>
                          setserviceDetails({
                            ...serviceDetails,
                            price: e.target.value,
                          })
                        }
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Switch
                        checked={serviceDetails.negotiable}
                        onChange={(e) =>
                          setserviceDetails({
                            ...serviceDetails,
                            negotiable: e.target.checked,
                          })
                        }
                      />
                      <Typography variant="body2">
                        Mark if price is negotiable
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box align="right" sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: "50px",
                      height: "50px",
                    }}
                    disabled={
                      !category ||
                      !title ||
                      !description ||
                      !city
              
                    }
                    onClick={()=>handleEditService()}
                  >
                 Update Service
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ height: "380px", display: "flex", columnGap: 2 }}>
                  <Box
                    className="hide_scrollbar"
                    sx={{
                      width: "75px",
                      height: "375px",
                      overflowY: "scroll",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {existingImages?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedPreviewImage(image)}
                        sx={{
                          background: `url('${image?.preview}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          height: "70px",
                          mb: 1.5,
                          width: "100%",
                          transition: "0.2s all linear",
                          flexShrink: 0,
                          ...(selectedPreviewImage === image && {
                            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('${image?.preview}')`,
                          }),
                        }}
                      />
                    ))}
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box
                    sx={{
                      flex: 1,
                      background: `url('${selectedPreviewImage?.preview}')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      borderRadius: "5px",
                      transition: "0.1s all linear",
                      border: "0.5px solid #dedede",
                    }}
                  ></Box>
                </Box>

                <Box sx={{ mt: 3 }}>
           

                  <Typography variant="subtitle1" sx={{ fontSize: "25px" }}>
                    {title}
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <Typography sx={{}} variant="subtitle1">
                      ${parseFloat(price).toLocaleString()}
                    </Typography>
                    <Chip
                      label={negotiable ? "Negotiable" : "Buy now"}
                      sx={{
                        bgcolor: "#E9FCF9",
                        color: "#148B77",
                        fontWeight: 500,
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography>{`${city?.name}, ${state?.name}`}</Typography>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1">Description</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {description}
                    </Typography>
                  </Box>

                  <Divider sx={{ mt: 5 }} />
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant=""
                      sx={{
                        bgcolor: "#F3F3F3",
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                      onClick={handleEditService}
                    >
                      Submit Product
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>

      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfettiExplosion
              duration={6000}
              zIndex={10000}
              particleSize={7}
              colors={["#EBDCEF", "#7B408C", "#1F1023", "#FFCD29", "#FFF9E5"]}
            />
            <img src={successIcon} width={80} />
            <Typography
              sx={{
                mt: 2,
                fontWeight: 700,
                color: "#151515",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Service Updated
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              You'll receive an email and in-app notification once it's
              approved.
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                onClose();
              }}
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditService;
