import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const HelpCenter = () => {
  return (
    <Box sx={{ bgcolor: "#fff", p: 2 }}>
      <Box>
        <Typography sx={{ color: "#333", fontSize: "20px", fontWeight: 500 }}>
          Help Center
        </Typography>
        <Divider sx={{ mt: 3 }} />
        <Box
          sx={{
            bgcolor: "#EBDCEF",
            p: 2,
            mt: 1,
            borderRadius: "10px",
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#1a1a1a",
                fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "14px" },
                fontWeight: 600,
              }}
            >
              Still have questions?
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Can’t find the answer you’re looking for? Please chat to our
              friendly team.
            </Typography>
          </Box>
          <Link to="/contact">
          <Button variant="contained" sx={{ borderRadius: "20px" }}>
            Get in touch
          </Button>
          </Link>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography
            sx={{
              color: "#1a1a1a",
              fontSize: "24px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            FAQS
          </Typography>
        </Box>
        <Box>
          <Accordion
            defaultExpanded={true}
            sx={{
              mb: 2,
              border: "1px solid #E6E6E6",
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#333",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: 500,
                }}
              >
                How do i sell on Mamaket
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>Download the Mamaket mobile app</li>
                  <li>Login / create account.</li>
                  <li>
                    Click on Sell, then Select Products to upload products.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={true}
            sx={{
              mb: 2,
              border: "1px solid #E6E6E6",
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#333",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: 500,
                }}
              >
                How do i sell on Mamaket
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>Download the Mamaket mobile app</li>
                  <li>Login / create account.</li>
                  <li>
                    Click on Sell, then Select Products to upload products.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={true}
            sx={{
              mb: 2,
              border: "1px solid #E6E6E6",
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#333",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: 500,
                }}
              >
                How do i sell on Mamaket
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>Download the Mamaket mobile app</li>
                  <li>Login / create account.</li>
                  <li>
                    Click on Sell, then Select Products to upload products.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={true}
            sx={{
              mb: 2,
              border: "1px solid #E6E6E6",
              boxShadow: "none",
              borderRadius: "8px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#333",
                  fontSize: { lg: "16px", md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: 500,
                }}
              >
                How do i sell on Mamaket
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li>Download the Mamaket mobile app</li>
                  <li>Login / create account.</li>
                  <li>
                    Click on Sell, then Select Products to upload products.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpCenter;
