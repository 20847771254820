import { gql } from "@apollo/client";

// MISCELLANEOUS

export const GET_CITY = gql`
  query GetCity($state: String!) {
    fetchCities(query: { state: $state }, pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        name
      }
      pagination
    }
  }
`;
export const GET_STATES = gql`
  query GetStates($country: String!) {
    fetchStates(
      query: { country: $country }
      pageOptions: { page: 1, limit: 100 }
    ) {
      items {
        _id
        name
        abbreviation
        country {
          _id
          name
        }
      }
      pagination
    }
  }
`;

export const GET_COUNTRIES = gql`
  query {
    getAllCountries(
      query: { name: "United" }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        flagUrl
        dialingCode
      }
    }
  }
`;

export const GENERAL_SEARCH = gql`
  query serach($searchText: String!) {
    searchGlobalInput(
      payload: { searchCategory: "all", searchText: $searchText }
    ) {
      source
      result {
        ... on Product {
          _id
          reference
          name
          description
          alias
          price
          # createdAt
          merchant {
            firstName
            lastName
            phone
          }
          currency {
            name
            symbol
            shortCode
          }
          country {
            _id
            name
            alias
          }
          isInStock
          isNegotiable
          quantityInStock
          images
          status
        }
        ... on UserService {
          _id
          reference
          title
          description
          price
          rateType
          status
          isNegotiable
          # createdAt
          # updatedAt
          currency {
            name
            symbol
            shortCode
          }
          city {
            name
          }
        }
      }
    }
  }
`;
// .............................

// PRODUCTS
export const GET_CATEGORIES_N_SUB = gql`
  query {
    fetchAllCategoriesAndTheirSubCategories {
      category {
        _id
        name
        isActive
      }
      subCategories {
        _id
        name
        description
      }
    }
  }
`;

export const GET_CATEGORY_PRODUCT = gql`
  query fetchProduct($categoryId: String!) {
    fetchProductsByCategory(
      query: { categoryId: $categoryId }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        reference
        name
        description
        alias
        price
        createdAt
        merchant {
          firstName
          lastName
          phone
        }
        currency {
          name
          symbol
          shortCode
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
      pagination
    }
  }
`;

export const GET_SINGLE_PRODUCT = gql`
  query GetSingleProduct($_id: String!) {
    listAllAvailableProductsWithoutAuthentication(
      query: { _id: $_id }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        price
        createdAt
        updatedAt
        categories {
          name
        }
        country {
          flagUrl
          name
        }
               merchant {
          firstName
          lastName
          phone

          addresses {
            addressOne
            isDefault
            state{
              name
            }
            city{
              name
            }
          }
        }

        currency {
          name
          symbol
          shortCode
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
    }
  }
`;
export const GET_SINGLE_PRODUCT_DESCRIPTION = gql`
  query GetSingleProduct($_id: String!) {
    listAllAvailableProductsWithoutAuthentication(
      query: { _id: $_id }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        price
        description
        rating
        country {
          name
          flagUrl
        }

        pickupAddresses {
          address1
        }

        merchant {
          firstName
          lastName
          phone
          imageUrl

          addresses {
            addressOne
            isDefault
            state{
              name
            }
            city{
              name
            }
              country{
              name}
              zipCode
          }
        }
        comments {
          _id
          content
          rating
          createdAt
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
    }
  }
`;

export const GET_TOPSELLERS_PRODUCTS = gql`
  query {
    getMarketPlaceBestSellingProductListingForUnAuthenticatedUser {
      _id
      name
      price
      rating
      country {
        flagUrl
        name
      }
      merchant {
        firstName
        lastName
        phone
      }
      images
      isInStock
      isNegotiable
      quantityInStock
      images
      status
    }
  }
`;
export const GET_TOPSELLERS_PRODUCTS_AUTH = gql`
  query {
    getMarketPlaceBestSellingProductListing {
      _id
      reference
      name
      description
      alias
      price
      merchant {
        firstName
        lastName
        phone
      }
      currency {
        name
        symbol
        shortCode
      }
      isInStock
      isNegotiable
      quantityInStock
      images
      status
    }
  }
`;

// SERVICES

export const GET_SERVICES = gql`
  query {
    listAllAvailableServicesWithoutAuthentication(
      filter: {}
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        title
        description
        price
        rateType
        status
        isNegotiable
        createdAt
        images
        updatedAt
        city {
          name
          state {
            name
            country {
              name
              currency {
                name
                symbol
                alias
              }
            }
          }
        }
      }
      pagination
    }
  }
`;
export const GET_SINGLE_SERVICE = gql`
  query GetSingleService($_id: String!) {
    listAllAvailableServicesWithoutAuthentication(
      filter: { _id: $_id }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        title


        description
        price
        rateType
        status
        isNegotiable
        createdAt
        images
        updatedAt

        city {
          name
          state {
            name
            country {
              name
              flagUrl
              currency {
                name
                symbol
                alias
              }
            }
          }
        }
           user{
           firstName
           lastName
           imageUrl
           }    
      }
        
      pagination
    }
  }
`;

// ..........................

// CHECKOUT N CART

export const GET_CART = gql`
  query {
    getMyShoppingCart(pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        quantity
        pricePerUnit
        totalAmount
        createdAt
        updatedAt
        product {
          _id
          name
          description
          images
        }
        session {
          _id
          currency {
            name
            shortCode
            shortCode
          }
        }
      }
      pagination
    }
  }
`;

export const GET_SERVICE_CART = gql`
  query {
    fetchMyCartServices(filter: {}, pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        reference
        offerAmount
        totalPrice
        scheduledDate
        scheduledTime
        service {
          title
          description
          images
        }

        status
        createdAt
        updatedAt
        currency {
          shortCode
          symbol
        }
      }
      pagination
    }
  }
`;

// .............................

// DASHBOARD
export const GET_PROFILE = gql`
  query {
    getProfile
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query {
    fetchDefaultWalletHistories(
      query: {}
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        amount
        gateway
        description
        action
        type
        status
        paidAt
        updatedAt
        currency {
          symbol
          shortCode
          name
          alias
        }
      }
      pagination
    }
  }
`;

export const GET_ALL_ADDRESS = gql`
  query {
    fetchAllAddresses {
      firstName
      lastName
      addressOne
      addressTwo
      zipCode
      phone
      isDefault
      createdAt
      updatedAt
      _id
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      country {
        _id
        name
      }
    }
  }
`;

export const GET_ALL_PUSRSES = gql`
  query {
    getWallets {
      _id
      spendingBalance
      earningBalance
      isActive
      isDefault
      currency {
        symbol
        shortCode
      }
    }
  }
`;

export const GET_BUYER_ORDERS = gql`
  query {
    getMyOrders(query: {}, pageOptions: { page: 1, limit: 60 }) {
      items {
        _id
        reference
        pricePerUnit
        amountPaid
        quantity
        status
        createdAt
        updatedAt
        order {
          currency {
            name
            alias
            shortCode
            symbol
          }
        }
        statusHistory {
          _id
          status
          createdAt
          updatedAt
        }
        product {
          _id
          name
          images
        }
      }
      pagination
    }
  }
`;

export const SERVICE_APPOINTMENT = gql`
  query {
    fetchBuyerAppointments(filter: {}, pageOptions: { page: 1, limit: 60 }) {
      items {
        _id
        reference
        offerAmount
        totalPrice
        scheduledDate
        scheduledTime
        service {
          title
          description
          images
        }
        seller {
          firstName
          lastName
          phone
          email
        }
        status
        createdAt
        updatedAt
        currency {
          shortCode
          symbol
        }
      }
      pagination
    }
  }
`;
export const SINGLE_SERVICE_APPOINTMENT = gql`
  query singleOrder($reference: String!) {
    fetchBuyerAppointments(
      filter: { reference: $reference }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        offerAmount
        totalPrice
        scheduledDate
        scheduledTime
        service {
          title
          description
          images
        }
        seller {
          firstName
          lastName
          phone
          email
        }
        status
        createdAt
        updatedAt
        currency {
          shortCode
          symbol
        }
      }
      pagination
    }
  }
`;
export const GET_SINGLE_ORDER = gql`
  query singleOrder($reference: String!) {
    getMyOrders(
      query: { reference: $reference }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        pricePerUnit
        amountPaid
        quantity
        status
        createdAt
        updatedAt

        order {
          currency {
            name
            alias
            shortCode
            symbol
          }
        }
        statusHistory {
          _id
          status
          createdAt
          updatedAt
        }
        product {
      price
          _id
          name
          merchant {
            
            addresses {
            addressOne
            isDefault
     
    
           
        }
          }
          images
          pickupAddresses {
            address1
          }
        }
      }
      pagination
    }
  }
`;

// ..........................


export const NOTIFICATION_TYPE = gql `
query {
    getActiveNotificationTypes {
        _id
        title
    }
}
`

export const ACTIVE_NOTIFICATION = gql `
query {
    fetchUserNotificationPreferences {
        notifications {
            _id
            title
        }
    }
}
`

export const GET_FAVOURITES = gql`
query {
    getMyFavourites(filter: { }, pageOptions: { page: 1, limit: 25 }) {
        items {
            _id
            isProduct
            productOrService
            createdAt
            updatedAt
        }
        pagination
    }
}
`

export const GET_PRODUCT_ORIGIN = gql`
query {
    getProductOrigins(query: {}, pageOptions: { page: 1, limit: 1000 }) {
        items {
            _id
            name
            flagUrl
        }
        pagination
    }
}
`

export const GET_MY_PRODUCTS = gql`
query FetchMerchantProducts( $limit: Float!)  {
  fetchMerchantProducts(query: {}, pageOptions: { page: 1, limit: $limit}) {
    items {
      _id
      reference
      name
      description
      alias
      price
      currency {
        name
        symbol
        shortCode
      }
      features
      boxContent
      isInStock
      isNegotiable
      quantityInStock
      images
      createdAt
      updatedAt
      status
      specifications {
        _id
        specification
        value
      }
      subCategories {
        _id
        name
        category {
          name
          image
        }
      }
      categories {
        _id
        name
        image
      }
      pickupAddresses {
        _id
        address1
        address2
      }


      comments {
        _id
        content
        rating
        createdAt
      }
    }
    pagination
  }
}
`

export const GET_MY_SERVICE = gql `
query FetchMerchantService( $limit: Float!){
    getMyServices(filter: {}, pageOptions: { page: 1, limit: $limit }) {
        items {
          _id
          reference
          title
          description
          price
          rateType
          status
          isNegotiable
          images
          createdAt
          updatedAt
          category {
        _id
        name
     
      }
          city {
            _id
            name
            state {
            _id
              name
              country {
              _id
                name
                currency {
                   name
                   symbol
                   alias 
                }
              }
            }
          }
        }
        pagination
    }
}
`

export const GET_MY_ORDERS = gql`
query {
  getSellerOrders(query: {}, pageOptions: { page: 1, limit: 25 }) {
    items {
      _id  
      reference
      pricePerUnit
      amountPaid
      quantity
      status
      createdAt
      updatedAt
      dispute {
        reason
        description
        files
      }
      cancellation {
        reason
        description  
      }
      order {
        reference  
        currency {
          name
          alias
          shortCode
          symbol
        }
      }
      statusHistory {
        _id,
        status
        createdAt
        updatedAt
      }
      product {
        _id
        name
        images
      }
            buyer{
      firstName
      lastName
      imageUrl
      }
    }
  
    pagination
  }
}
`

export const GET_SINGLE_MY_ORDER = gql`
query getSingleOrders($id: String!){
  getSellerOrders(query: {_id:$id}, pageOptions: { page: 1, limit: 25 }) {
    items {
      _id  
      reference
      pricePerUnit
      amountPaid
      quantity
      status
      createdAt
      updatedAt
      dispute {
        reason
        description
        files
      }
      cancellation {
        reason
        description  
      }
      order {
        reference  
        currency {
          name
          alias
          shortCode
          symbol
        }
      }
      statusHistory {
        _id,
        status
        createdAt
        updatedAt
      }
      product {
        _id
        name
        images
      }
    }
    pagination
  }
}
`

export const GET_MY_APPOINTMENT = gql `
query {
    fetchSellerAppointments(filter: {}, pageOptions: { page: 1, limit: 25 }) {
        items {
            _id
            reference
            offerAmount
            totalPrice
            scheduledDate
            scheduledTime
            service {
                title
                description
            }
            seller {
                firstName
                lastName
                phone
                email
            }
            status
            createdAt
            updatedAt
            currency {
                shortCode
                symbol
            }
        }
        pagination
    }
}
`
export const GET_SINGLE_APPOINTMENT = gql `
query getSingleOrders($id: String!){
    fetchSellerAppointments(filter: {_id:$id}, pageOptions: { page: 1, limit: 25 }) {
        items {
            _id
            reference
            offerAmount
            totalPrice
            scheduledDate
            scheduledTime
            service {
                title
                description
            }
            seller {
                firstName
                lastName
                phone
                email
            }
            status
            createdAt
            updatedAt
            currency {
                shortCode
                symbol
            }
        }
        pagination
    }
}
`

export const GET_MY_STORE_BALANCE = gql `
query user($userId:String!){
    getUserStoreBalanceInformationByUserId(
        payload: { userId: $userId }) 
    {
        totalEarned
        totalWithdrawn
        balance
    }
}
`

export const GET_SELL_AMOUNT = gql`
query {
  getSellerBills {
    amountToPay
    currency {
        shortCode
        symbol
    }
  }
}
`

export const GET_MY_BANKS = gql`
query {
    allMyBanks {
        _id
        accountName
        accountNumber
        routingNumber
        bankName
        createdAt
        updatedAt
    }
}
`


