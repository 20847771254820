import { useState } from "react";
import { favItemsVar } from "../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { DELETE_FAV, REMOVE_CARTS } from "../Utils/Mutations";
import { useMutation } from "@apollo/client";

const useRemoveFav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const favItems = useReactiveVar(favItemsVar);

  const [removeCart, { data }] = useMutation(DELETE_FAV);

//   let isInCart = favItems?.some((item) => item._id === id);

  const handleRemoveFav = async (userFavouriteId) => {

 

                              setIsLoading(true);
                              await removeCart({
                                variables:{userFavouriteId},
                              })
                                .then((res) => {
                   
                                  setIsLoading(false);
                                  setSuccess(true);
                                  favItemsVar(
                                    favItems.filter((item) => item._id !== userFavouriteId)
                                  );
                                })
                                .catch((err) => {
                                  console.error("Error Newe Cart", err);
                                  setIsLoading(false);
                                });

    
  };

  return { handleRemoveFav, isLoading, isSuccess };
};

export default useRemoveFav;
