import { Avatar, Box, Grid } from '@mui/material'
import React from 'react'
import img1 from '../../assets/sell.png'
import img2 from '../../assets/new.png'
import { useNavigate } from 'react-router-dom'

const SellsBanner = () => {

    const navigate = useNavigate()

    const handleDownload = () =>{
        navigate('/download')
    }
  return (
<>
<Box sx={{margin:'0 auto', width:'100%',mb:5}}>
    <Grid container spacing={2}>
<Grid item lg={6} md={6}>
    <Box sx={{cursor:'pointer'}} onClick={handleDownload}>
        <img src={img2} width="100%" alt='sellBanner'/>
    </Box>
</Grid>
<Grid item lg={6} md={6}>
<Box>
        <img src={img1} width="100%" alt='sellBanner'/>
    </Box>
</Grid>
    </Grid>
</Box>
</>
  )
}

export default SellsBanner
