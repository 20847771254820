import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
const location = useLocation()
      const user = useSelector(
        (state) => state.user
      );
    

  if (user.isAuthenticated === false || user.user === null) {
    return <Navigate to="/login" state={{ redirectTo: location.pathname }}  />;
  }
  return children;
};

export default ProtectedRoute;