import { useState } from "react";
import { serviceCartVar } from "../Utils/cache";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { GET_SERVICE_CART } from "../Utils/Queries";

const useGetServiceCart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const serviceItems = useReactiveVar(serviceCartVar);

  const [getCart, { data, refetch }] = useLazyQuery(GET_SERVICE_CART);

  const handleGetCart = async () => {

    setIsLoading(true);
    

    await getCart({
      pollInterval: 200,
    })
      .then((res) => {

        setIsLoading(false);
        setSuccess(true);
        serviceCartVar(res?.data?.fetchMyCartServices?.items);
      })
      .catch((err) => {
        console.error("Error Newe Cart", err);
        setIsLoading(false);
      });
  };

  return { handleGetCart, isLoading, isSuccess, refetch };
};

export default useGetServiceCart;
