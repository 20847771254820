import React, { useState } from "react";

// Mui
import { Box } from "@mui/material";
// Routers
import { Link, useParams } from "react-router-dom";

// Assets
import "../../STYLES/main.css";

// Child COmponents
import Navbar from "../../Components/Common/Navbar";
import SimilarProducts from "../../Components/Products/ProductsView/SimilarProducts";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import Overview from "../../Components/Services/ServiceView/Overview";
import Description from "../../Components/Services/ServiceView/Description";

const ServiceView = () => {
  const { id } = useParams();

  return (
    <>
      <Navbar />
      <Box>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
            mt: { lg: 15, md: 15, sm: 8, xs: 9 },
          }}
        >
          <Overview serviceID={id} />
          <Description serviceID={id} />
          {/* <SimilarProducts /> */}
        </Box>
      </Box>

      <Newsletter />
      <Footer />
    </>
  );
};

export default ServiceView;
