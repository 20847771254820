// PLEASE EVERY WHERE YOU SEE PRODUCT I'M ACTUALLY REFERRING TO SERVICE===========================================================================================================================

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import nigFlag from "../../../assets/Flags/Japan.svg";
import "../../../STYLES/main.css";
import whatsapp from "../../../assets/Icons/whatsapp 1.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import twitter from "../../../assets/Icons/twitter 1 (1).svg";
import facebook from "../../../assets/Icons/facebook 1 (1).svg";
import love from "../../../assets/Icons/love.svg";
import socket from "../../../Utils/socket";
import { useQuery } from "@apollo/client";
import Loader from "../../Common/Loader";
import { GET_SINGLE_SERVICE } from "../../../Utils/Queries";
import noImage from "../../../assets/no-image.svg";
import { formatDistanceToNow } from "date-fns";
import calenderImg from "../../../assets/Icons/calender.svg";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import { serviceCartVar } from "../../../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { useSnackbar } from "notistack";
import ChatSeller from "./ChatSeller";
import useGetServiceCart from "../../../Hooks/useGetServiceCart";
import useAddToFav from "../../../Hooks/useAddToFav";
import useGetFav from "../../../Hooks/useGetFav";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 400, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const Overview = (serviceID) => {
  const location = useLocation();

  const navigate = useNavigate();

  const [openBook, setOpenBook] = React.useState(false);

  const [time, setTime] = useState("");

  const [date, setDate] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // ALERT FNC ============================================================================================================
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  // ==========================

  // PLEASE EVERY WHERE YOU SEE PRODUCT I'M ACTUALLY REFERRING TO SERVICE

  const handleCloseBook = () => setOpenBook(false);
  const serviceItems = useReactiveVar(serviceCartVar);

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popover" : undefined;
  const handleOpenBook = () => {
    setOpenBook(true);
    setAnchorEl(null);
  };

  const { loading, error, data } = useQuery(GET_SINGLE_SERVICE, {
    variables: {
      _id: serviceID?.serviceID,
    },
  });

  const product = data?.listAllAvailableServicesWithoutAuthentication?.items[0];


  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const handleToLogin = () => {
    navigate(`/login`, {
      state: { redirectTo: location.pathname },
    });
  };

    useEffect(() => {
    handleGetFav();

    favRefetch();
  }, []);

    const {
    handleAddToFav,
    isLoading: favLoading,
    isSuccess: favSuccess,
  } = useAddToFav(serviceID?.serviceID, false);

  const { handleGetFav, refetch: favRefetch } = useGetFav();





  // SOCIAL MEDIA SHARE FUNCTION ===========================================================================================================

  const productLink = `sandbox.mamaket.com/service-view/${serviceID?.serviceID}`;

  const shareOnTwitter = () => {
    const tweetText = encodeURIComponent(
      `Check out this awesome product: ${product.name} ${productLink}`
    );
    const url = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(url, "_blank");
  };

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      productLink
    )}`;
    window.open(url, "_blank");
  };

  const shareOnWhatsApp = () => {
    const message = `Check out this awesome product: ${product.name} ${productLink}`;
    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;
    window.open(url);
  };
  // ===========================================



//  OFFERS SOCKET, EVENTS & EMIT =================================================================================================================

  const NEWSERVICE = "newServiceOffer";
  const EXCEPTION = "exception";
  const INITIATESERVICEOFFER = "initiateServiceOffer";


const handleBookOffer = async () => {
    setSuccess(false);
    setIsLoading(true);

    try {
      const payload = {
        serviceId: serviceID?.serviceID,
        offerAmount: product?.price,
        scheduledDate: date,
        scheduledTime: time,
      };

      // Emit the event to initiate the service offer
      socket.emit(INITIATESERVICEOFFER, payload);
    } catch (err) {
      setIsLoading(false);
      handleAlert(`${err.message}`, "error");
    }
  };

  useEffect(() => {
    // Connect socket and set up event listeners
    socket.connect();

    // Listen for exceptions
    socket.on(EXCEPTION, (data) => {
      setIsLoading(false);
      handleAlert(`${data?.message}`, "error");
    });

    // Listen for new service offers
    socket.on(NEWSERVICE, (receivedData) => {
      serviceCartVar([...serviceItems, receivedData]);
      setSuccess(true);
      setIsLoading(false);
      handleAlert("Offer created successfully", "success");
    });

    // Cleanup the socket event listeners and connection on unmount
    return () => {
      socket.off(EXCEPTION); // Remove the specific listener
      socket.off(NEWSERVICE); // Remove the specific listener
      socket.disconnect();    // Disconnect the socket if no longer needed
    };
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Box
        sx={{
          p: 1,
          px: 4,
          bgcolor: "#fff",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link to="/" style={{ fontWeight: 400, color: "#666" }}>
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,

                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Home
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Link to="/">
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,

                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Service
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "100%",
          bgcolor: "#fff",
          mt: { lg: 5, md: 5, sm: 0, xs: 0 },
        }}
      >
        <Box sx={{ p: 2, px:4 }}>
          <Grid container spacing={3}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column-reverse",
                    xs: "column-reverse",
                  },
                }}
              >
                <Grid
                  item
                  lg={1.5}
                  md={1.5}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "column",
                      md: "column",
                      sm: "row",
                      xs: "row",
                    },
                    gap: 2,
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={10.5}>
                  <Avatar
                    src={product?.images[0] ? product?.images[0] : noImage}
                    variant="square"
                    sx={{ width: "100%", height: "400px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Info Product */}
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{}}>
              <Box sx={{}}>
                <Box
                  sx={{
                    px: "12px",
                    py: "0px",
                    borderBottom: "1px solid #f2f2f2",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                      Product Origin:
                    </Typography>
                    <img
                      src={
                        product?.city?.state?.country?.flagUrl
                          ? product?.city?.state?.country?.flagUrl
                          : nigFlag
                      }
                      width={20}
                    />
                  </Box>
                  <Typography
                    // className="product_title"
                    sx={{
                      fontSize: {
                        lg: "26px",
                        md: "26px",
                        sm: "20px",
                        xs: "20px",
                        color: "#333",
                        fontWeight: 500,
                      },
                      mt: 1,
                    }}
                  >
                    {product?.title}
                  </Typography>
                  <Box sx={{ mt: 1.5, display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 500, color: "#333" }}
                    >
                      &#36; {product?.price.toLocaleString()}
                    </Typography>
                    {/* {product?.isNegotiable === true ? ( */}
                      <Typography
                        sx={{
                          bgcolor: "#E9FCF9",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Negotiable
                      </Typography>
                    {/* ) : (
                      <Typography
                        sx={{
                          bgcolor: "#EBDCEF",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#2E1834",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Buy Now
                      </Typography>
                    )} */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: { lg: 2, md: 2, sm: 1, xs: 1 },
                      color: "#333",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      {`${product?.city?.name}, ${product?.city?.state?.name}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    ></Typography>

                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Posted:{" "}
                      {product?.createdAt &&
                        formatDistanceToNow(new Date(product?.createdAt), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: "1px solid #f2f2f2",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: "20px",
                    width: "100%",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      rowGap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#333",
                        mr: 1,
                        ml: 1,
                      }}
                    >
                      Share with friends
                    </Typography>
                    <Box>
                      <IconButton onClick={shareOnFacebook}>
                        <img src={facebook} alt="facebook_icon" />
                      </IconButton>
                      <IconButton onClick={shareOnTwitter}>
                        <img src={twitter} alt="twitter_icon" />
                      </IconButton>
                      <IconButton onClick={shareOnWhatsApp}>
                        <img src={whatsapp} alt="whatsapp_icon" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <ChatSeller serviceID={serviceID?.serviceID} />
                  </Box>
                </Box>

                <Box
                  sx={{
                    pt: "24px",
                    px: { lg: "12px", md: "12px", xs: "0px", sm: "0px" },
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    aria-describedby={id}
                    sx={{
                      width: "350px",
                      borderRadius: "100px",
                      padding: "12px 20px",
                    }}
                    onClick={isAuthenticated ? handleOpenBook : handleToLogin}
                  >
                    Book appointment
                  </Button>
                  <Button
                    onClick={
                      isAuthenticated ? () => handleAddToFav() : handleToLogin
                    }
                    sx={{ color: "#333", fontSize: "16px" }}
                  >
                    <img
                      src={love}
                      alt="fav_icon"
                      style={{ marginRight: "10px" }}
                    />
                    Save For Later
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Mmake an Offer Modal */}

      {/* Makke an offer modal */}

      {/* Buttonn Poppeer */}

      {/* Buttonn Poppeer */}

      {/* Book an Offer Modal */}
      <Modal
        open={openBook}
        onClose={handleCloseBook}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={calenderImg} />
                <Typography sx={{ py: 2 }}>
                  <strong>Schedule Sent</strong>
                </Typography>
                <Typography>
                  Your schedule date and time has been sent to seller, you will
                  get notified what the seller accept it.
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={() => {
                    setOpenBook(false);
                    setSuccess(false);
                  }}
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              {loading && <Loader />}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
                >
                  Book Appointment
                </Typography>
                <IconButton onClick={handleCloseBook}>
                  <CloseOutlined sx={{ color: "#333" }} />
                </IconButton>
              </Box>

              <Box sx={{ py: 2 }}>
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  Schedule date & time
                </Typography>

                <Grid container spacing={2}>
                  <Grid item lg={6} xs={6}>
                    <TextField
                      fullWidth
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      InputProps={{
                        style: {
                          marginTop: "10px",
                          // marginBottom: "20px",
                          fontSize: "13px",
                          borderRadius: "8px",
                          offset: " 1px solid #f2f2f2",
                          // Replace with your desired font family
                        },
                      }}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Basic date picker" />
              </DemoContainer>
    </LocalizationProvider> */}
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <TextField
                      fullWidth
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      InputProps={{
                        style: {
                          marginTop: "10px",
                          // marginBottom: "20px",
                          fontSize: "13px",
                          borderRadius: "8px",
                          offset: " 1px solid #f2f2f2",
                          // Replace with your desired font family
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Button
                  fullWidth
                  onClick={handleBookOffer}
                  variant="contained"
                  sx={{ borderRadius: "100px" }}
                >
                  Send Offer
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {/* Book an offer modal */}
    </>
  );
};

export default Overview;
