import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuList,
  Select,
  TextField,
  Typography,
  MenuItem,
  Divider,
  InputLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  CHANGE_PHONE,
  EDIT_PHONE_OTP,
  PROFILE_EDIT,
} from "../../Utils/Mutations";
import LoadButton from "../../Components/Common/LoadButton";
import { GET_COUNTRIES, GET_PROFILE } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 450, md: 450, sm: 350, xs: 350 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

const Profile = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const handleOpen2 = () => {
    setOpen2(true);
    setisOtp(false);
    setOtp("");
  };
  const handleClose2 = () => setOpen2(false);

  const [value, setValue] = useState("");

  const [value2, setValue2] = useState("");

  const [editFirstName, setEditFirstName] = useState("");

  const [editLastName, setEditLastName] = useState("");

  const [editPhone, setEditPhone] = useState("");

  const [isOtp, setisOtp] = useState(false);

  const [otp, setOtp] = useState(null);

  const [country, setCountryId] = useState("");

  const locationState = location.state;



  // QUERIES - PROFILE, COUNTRIES ========================================================================================================

  const [
    getProfile,
    { loading: profileLoading, data: profileData },
  ] = useLazyQuery(GET_PROFILE);
  const [getCountries, { loading: countryLoader, data }] = useLazyQuery(
    GET_COUNTRIES
  );


  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };


  // HANDLE GET PROFILE AND UPDATE PROFILE STATES =======================================================================================================

  const handleGetProfile = () => {
    getProfile()
      .then((response) => {
        setEditFirstName(response?.data?.getProfile?.profile?.firstName);
        setEditLastName(response?.data?.getProfile?.profile?.lastName);
        setValue(response?.data?.getProfile?.profile?.sex);
        setValue2(response?.data?.getProfile?.profile?.country?.name);
        setCountryId(response?.data?.getProfile?.profile?.country?._id);
      })
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  };

  useEffect(() => {
    getCountries();
    handleGetProfile();
  }, []);

  useEffect(() => {
    setEditPhone(phone);
  }, [profileData]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // HANDLE ALERT SNACKBAR===============================================================================================
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };



 


  // HANDLE PHONE NUMBER CONFIRM OTP FUNCTION ==========================================================================================================

   const [confirmOtp, { loading: otpLoading }] = useMutation(EDIT_PHONE_OTP);

  const handleConfirmOtp = () => {
    setOtp("");
    confirmOtp({
      variables: {
        otp,
      },
    })
      .then((res) => {
        setOpen2(false);
        handleAlert(
          `${res?.data?.handlePhoneNumberChangeRequest?.message}`,
          "success"
        );
      })
      .catch((err) => {

        handleAlert(`${error.message}`, "error");
      });
  };

  // EDIT PHONE NUMBER FUNC ====================================================================================================================

    const [editProfile, { loading, error }] = useMutation(PROFILE_EDIT);
    
    const [editPhoneNumber, { loading: phoneLoading }] = useMutation(
    CHANGE_PHONE
  );

  const handleEditPhoneNumber = () => {
    editPhoneNumber({
      variables: {
        country: country,
        phone: editPhone,
      },
    })
      .then((res) => {
      
        setisOtp(true);
        setOtp(res?.data?.requestToChangePhoneNumber?.otp);
        handleAlert(
          `${res?.data?.requestToChangePhoneNumber?.message}`,
          "success"
        );
      })
      .catch((err) => {
  
        handleAlert(`${error.message}`, "error");
      });
  };


  // EDIT PROFILE FUNC===========================================================================================================

  const handleEditProfile = () => {
    editProfile({
      variables: {
        firstName: editFirstName,
        lastName: editLastName,
        sex: value,
        country: country,
      },
    })
      .then((response) => {
        setEditFirstName(response?.data?.updateProfileInformation?.firstName);
        setEditLastName(response?.data?.updateProfileInformation?.lastName);

        handleAlert(`Profile edited successfully`, "success");
        navigate(from, { replace: true });
        if (locationState && locationState.redirectTo) {
          navigate(`${locationState?.redirectTo}`);
        }
        setOpen(false);
      })
      .catch((error) => {
        handleAlert(`${error.message}`, "error");
      });
  };

  const { firstName, lastName, email, phone, sex } =
    profileData?.getProfile?.profile ?? [];

  return (
    <>
      {countryLoader && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #F2F2F2",
            p: 1,
          }}
        >
          <Typography>Profile Details</Typography>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ borderRadius: "24px" }}
          >
            Edit
          </Button>
        </Box>
        <Box sx={{ mt: 2, p: 1 }}>
          <Grid container>
            <Grid item lg={6} xs={6}>
              <Box>
                <Typography sx={{ fontSize: "14px" }}>First Name</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {editFirstName}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography sx={{ fontSize: "14px" }}>Last Name</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {lastName ? lastName : "--"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "14px" }}>Email Adress</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {email ? email : "--"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: "14px" }}>
                    Phone Address
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                  >
                    {phone ? `+${phone}` : "--"}
                  </Typography>
                </Box>
                <Typography
                  onClick={handleOpen2}
                  sx={{
                    color: "#FF3347",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Change Phone Number
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "14px" }}>Gender</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {sex ? sex : "--"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Box align="center" sx={{ mt: 2, bgcolor: "#fff", p: 3 }}>
        <Button sx={{ color: "#FF3347", textDecoration: "underline" }}>
          Delete my account
        </Button>
      </Box> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 700, color: "#1a1a1a" }}>
              Edit Profile
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#1a1a1a" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 3 }}>
            <TextField
              fullWidth
              value={editFirstName}
              onChange={(e) => setEditFirstName(e.target.value)}
              InputProps={{
                style: {
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  fontWeight: 500,
                  // Replace with your desired font family
                },
              }}
            />
            <TextField
              fullWidth
              value={editLastName}
              onChange={(e) => setEditLastName(e.target.value)}
              InputProps={{
                style: {
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  fontWeight: 500,
                  // Replace with your desired font family
                },
              }}
            />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} xs={6}>
                <Box>
                  {/* <Typography sx={{ fontSize: "14px", ml: 2 }}>Sex</Typography> */}
                  <Select
                    value={value}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      fontSize: "13px",
                      // border: "0.5px solid #671E78",

                      borderRadius: "10px",
                      fontWeight: 500,
                    }}
                    inputProps={{
                      "aria-label": "With label",
                      style: {
                        color: "#2B2B2B",
                        fontSize: "13px",
                        fontWeight: 600,
                        mt: 1.5,
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="" sx={{ fontWeight: 500 }}>
                      Select Sex
                    </MenuItem>
                    <MenuItem value="MALE" sx={{ fontWeight: 500 }}>
                      Male
                    </MenuItem>
                    <MenuItem value="FEMALE" sx={{ fontWeight: 500 }}>
                      Female
                    </MenuItem>
                    <MenuItem value="NONE" sx={{ fontWeight: 500 }}>
                      Other
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xs={6}>
                <Box>
                  {/* <Typography sx={{ fontSize: "14px" }}>Country</Typography> */}
                  <Select
                    // size="small"
                    value={value2}
                    onChange={handleChange2}
                    fullWidth
                    sx={{
                      fontSize: "13px",
                      // border: "0.5px solid #671E78",

                      borderRadius: "10px",
                      fontWeight: 500,
                    }}
                    inputProps={{
                      "aria-label": "With label",
                      style: {
                        color: "#2B2B2B",
                        fontSize: "13px",
                        fontWeight: 500,
                        mt: 1.5,
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select Country
                    </MenuItem>
                    {data?.getAllCountries?.items.map(({ name, _id }) => (
                      <MenuItem
                        value={name}
                        sx={{ fontWeight: 500 }}
                        onClick={(e) => {
                          setCountryId(_id);
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <LoadButton
              text="save"
              text2="saving"
              action={handleEditProfile}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 700, color: "#1a1a1a" }}>
              Edit Phone Number
            </Typography>
            <IconButton onClick={handleClose2}>
              <CloseIcon sx={{ color: "#1a1a1a" }} />
            </IconButton>
          </Box>

          {isOtp ? (
            <>
              <Box sx={{ mt: 3 }}>
                <InputLabel sx={{ fontWeight: 500, fontSize: "12px" }}>
                  Enter Otp sent to your new number
                </InputLabel>
                <TextField
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  InputProps={{
                    style: {
                      marginTop: "10px",
                      marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      fontWeight: 500,
                    },
                  }}
                />

                <Divider sx={{ my: 2 }} />
                <LoadButton
                  text="Change Phone number"
                  text2="Chaging  phone number"
                  action={handleConfirmOtp}
                  isLoading={otpLoading}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ mt: 3 }}>
                <InputLabel sx={{ fontWeight: 500, fontSize: "12px" }}>
                  Phone Number
                </InputLabel>
                <TextField
                  fullWidth
                  value={editPhone}
                  onChange={(e) => setEditPhone(e.target.value)}
                  InputProps={{
                    style: {
                      marginTop: "10px",
                      marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      fontWeight: 500,
                      // Replace with your desired font family
                    },
                  }}
                />

                <Divider sx={{ my: 2 }} />
                <LoadButton
                  text="change"
                  text2="saving"
                  action={handleEditPhoneNumber}
                  isLoading={phoneLoading}
                />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Profile;
