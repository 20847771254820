import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  InputLabel,
  TextField,
  Chip,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Loader from "./Loader";

import { FloridaZipCodes } from "../../assets/Data/FloridaZipCodes";
import zIndex from "@mui/material/styles/zIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 350, xs: 290 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  zIndex:1000,
  borderRadius:'8px'
};
const API_KEY = process.env.REACT_APP_GOOGLE_KEY;
const PostalCode = () => {
  const userLocation = JSON.parse(localStorage.getItem("postalCode"));

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [postalCode, setPostalCode] = useState("");

  const [placeName, setPlaceName] = useState("");

  const [isInFlorida, setIsInFlorida] = useState(false);

  useEffect(() => {
    if (userLocation) {
      setOpen(false);
      setPlaceName(userLocation)
    } else {
      setOpen(true);
    }
  }, [userLocation]);

  const checkZipCode = () => {
    if (FloridaZipCodes.includes(postalCode)) {
      setIsInFlorida(true);
         localStorage.setItem("isInFlorida", JSON.stringify(true));
    } else {
      setIsInFlorida(false);
         localStorage.setItem("isInFlorida", JSON.stringify(false));
    }
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };


  const handlePostalCodeSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${API_KEY}`
      );

      if (response.ok) {
        setIsLoading(false);
        const data = await response.json();

        if (data.results.length > 0) {
          const { formatted_address } = data.results[0];
          const placeName = formatted_address;
          localStorage.setItem("postalCode", JSON.stringify(placeName));
          setPlaceName(placeName);
          checkZipCode();
          setOpen(false);
        } else {
          setIsLoading(false);
          setIsError(true);
          setPlaceName("Place not found");
        }
      } else {
        setIsLoading(false);
        setPlaceName("Error retrieving place");
      }
    } catch (error) {
      setIsLoading(false);
      setPlaceName("Error retrieving place");
    }
  };

  //       useEffect(()=>{
  //       if (userLocation === null){
  // setOpen(true)
  //       }
  //       else{
  //         setPlaceName(userLocation)
  //       }

  // setOpen(true)
  // },[])

  return (
    <>
      <Box
        onClick={()=> !userLocation &&  handleOpen()}
        sx={{ display: "flex", alignItems: "center", ml: 1 , cursor : userLocation ? "not-allowed" : "pointer",  width:'50%', }}
      >
        <LocationOnIcon sx={{ fontSize: "15px" }} />
        <Chip
          label={placeName }
          sx={{
  
            fontWeight: 500,
            fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "10px" },
            bgcolor: { sm: "transparent", xs: "transparent" },
            textOverflow:'ellipsis',
            overflow:'hidden',

          }}
        />
      </Box>

      <Modal
        open={open}
    
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: {lg:"20px", md:'20px', sm:'18px', xs:'16px'}, color: "#1a1a1a", fontWeight: 600 }}
          >
            Enter your location
          </Typography>
          <Typography sx={{ fontSize: {lg:"14px", md:'14px', sm:'14px', xs:'12px'}, color: "#666", fontWeight: 300 }}>
            Find local products near you. Enter your zipcode.
          </Typography>
          {isLoading && <Loader />}

          <Box sx={{ mt: 3 }}>
          
            <TextField
              placeholder="Enter Postal/Zip Code"
              size="large"
         
              fullWidth
              helperText={isError && "Place Not Found"}
              error={isError}
           type="tel" 
              value={postalCode}
              onChange={handlePostalCodeChange}
              InputProps={{
            inputMode:'numeric', pattern:'[0-9]*',
                style: {
          
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  fontWeight:500
            
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handlePostalCodeSubmit}
            sx={{ mt: 3, borderRadius: "100px" }}
          >
            Check
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PostalCode;
