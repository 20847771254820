import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import client from "./Utils/ApolloInstance";
import { ApolloProvider } from "@apollo/client";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <BrowserRouter>
          {" "}
          <App />{" "}
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
