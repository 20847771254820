import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Popover,
  Popper,
  List,
  ListItemButton,
  Drawer,
  InputLabel,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from "@mui/icons-material/Clear";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import CategoryMenu from "../Categories/CategoryMenu";
import PostalCode from "../Common/PostalCode";
import { useLazyQuery } from "@apollo/client";
import { GENERAL_SEARCH } from "../../Utils/Queries";
import { ArrowLeftOutlined } from "@mui/icons-material";
import { FaAngleLeft } from "react-icons/fa6";
import { WiDirectionUpRight } from "react-icons/wi";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { PiPackageLight } from "react-icons/pi";
import { useSelector } from "react-redux";

import searchIcon from '../../assets/Icons/search.svg'

const recentSearch = [
  {
    label: "Yam and Shrimps",
    type: "product",
  },
  {
    label: "Superman handyman",
    type: "service",
  },
  {
    label: "The silk road",
    type: "product",
  },
  {
    label: "South african sauce",
    type: "product",
  },
  {
    label: "Plumber",
    type: "service",
  },
  {
    label: "Carpenter",
    type: "service",
  },
  {
    label: "Iphone",
    type: "product",
  },
];

const FullSearchBar = () => {
  const theme = useTheme();
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setQuery(event.target.value);
    if (query && !isMobile) {
      setOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const [handleSearch, { loading, data, error }] = useLazyQuery(GENERAL_SEARCH);

  useEffect(() => {
    if (query.length < 1) {
      setAnchorEl(null);
      setOpen(false);
    }
    handleSearch({
      variables: {
        searchText: query,
      },
    });
  }, [query]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const isSeller = useSelector(
    (state) => state.user?.user?.profile?.hasPaidMembershipFee
  );


  return (
    <>
      <Box
        sx={{
          bgcolor: "#f2f2f2",
          pt: 2,
          pb: { xs: 0, sm: 0, md: 2, lg: 2 },
          display: "flex",
          alignItems: "center",
          height:'50px'
        }}
      >
        {isMobile ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", px: 2 }}>
                <Box
                  onClick={toggleDrawer(true)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    bgcolor: "#fff",
                    px: 2,
                    borderRadius: "50px",
                    border: "1px solid #d7d7d7",
                    height: "35px",
                  }}
                >
                  <InputLabel sx={{ fontSize: "12px" }}>
                    Search for a product or service
                  </InputLabel>
                  <SearchIcon />
                </Box>
                {/* <TextField
        
                
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={query}
                  onChange={handleChange}
                  placeholder="Search for a product or service"
                  InputProps={{
                    style: {
                
                      fontSize: "13px",
                      borderRadius: "50px",
                      background: "#fff",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <IconButton>
                        {query.length > 0 ? (
                          <ClearIcon onClick={() => setQuery("")} />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                /> */}
              </Box>
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PostalCode />
                {isSeller ? (
                  <Link to="/sell-on-mamaket">
                    <Typography
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Sell on Mamaket
                    </Typography>
                  </Link>
                ) : (
                  <Link to="/download">
                    <Button
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      Become A Seller
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              margin: "0 auto",
              width: {
                xl: "75%",
                lg: "85%",
                md: "85%",
                sm: "100%",
                xs: "100%",
              },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <CategoryMenu />
            </Box>
            <Box sx={{ width: "65%", display: "flex" }}>
              <Box aria-describedby={id} sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                
                  sx={{  "& .MuiOutlinedInput-root": {
                  "& fieldset": {
borderColor:'#fff',

                  }
                  },
                }}
                  value={query}
                  onChange={handleChange}
                  placeholder="Search for a product or service"
                  InputProps={{
                    style: {
                      fontSize: "13px",
                      borderRadius: "50px",
                      background: "#fff",
                      offset: " 0px solid #737373",
               height:'45px'
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <IconButton>
                        {query.length > 0 ? (
                          <ClearIcon onClick={() => setQuery("")} />
                        ) : (
                        <img src={searchIcon} />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>

              <PostalCode />
            </Box>
            <Box>
              {isSeller ? (
                <Link to="/sell-on-mamaket">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "40px",
                    }}
                  >
                    Sell on Mamaket
                  </Button>
                </Link>
              ) : (
                <Link to="/download">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "40px",
                      background: "#1a1a1a",
                      "&:hover": { background: "#1a1a1ab7" },
                    }}
                  >
                    Become A Seller
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Popper
        transition
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 160, left: 500 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                zIndex: 1000,
                p: 2,
                bgcolor: "#fff",
                mt: 2,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                maxHeight: "300px",
                overflow: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {(data && data?.searchGlobalInput?.length === 0) || !data ? (
                <>
                  <Typography sx={{ color: "#23263B", fontWeight: 600 }}>
                    Sorry, <br />
                    we didn’t find any matches for "{query}"
                  </Typography>
                  <Typography sx={{ mt: 1.5, color: "#333", fontWeight: 400 }}>
                    Try the following:
                  </Typography>
                  <ul className="no_search_list">
                    <li>Double check your spelling</li>
                    <li>Use fewer keywords</li>
                    <li>
                      Search fo an item that is less specific and refine results
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <List sx={{ width: "450px" }}>
                    {data?.searchGlobalInput.map((item, index) => (
                      <Link
                        to={
                          item.source == "product"
                            ? `/product-view/${item?.result?._id}`
                            : `/service-view/${item?.result?._id}`
                        }
                        key={index}
                      >
                        <ListItemButton>
                          <SearchSharpIcon sx={{ color: "#333" }} />
                          {item?.source == "product" ? (
                            <Typography
                              sx={{ ml: 2, color: "#333", fontSize: "13px" }}
                            >
                              {item?.result?.name}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ ml: 2, color: "#333", fontSize: "13px" }}
                            >
                              {item?.result?.title}
                            </Typography>
                          )}
                          <Typography
                            variant="label"
                            sx={{ fontSize: "11px", color: "#b3b3b3", ml: 1 }}
                          >{`(${item?.source})`}</Typography>
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </Fade>
        )}
      </Popper>

      <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
        <Box sx={{ width: "100vw", bgcolor: "#fff" }}>
          <Box
            sx={{ boxSizing: "border-box", p: 1.5, bgcolor: "#5C3069" }}
            aria-describedby={id}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                borderRadius: "8px",
                p: 1,
                display: "flex",
                columnGap: 2,
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleDrawer(false)}>
                <FaAngleLeft style={{ color: "#5c3069", fontSize: "15px" }} />
              </IconButton>
              <TextField
                fullWidth
                size="small"
                placeholder="I'm looking for..."
                InputProps={{
                  style: {
                    fontSize: "12px",
                    fontWeight: 400,
                    borderRadius: "8px",
                  },
                }}
                value={query}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <Box sx={{ maxHeight: "50vh", boxSizing: "border-box" }}>
            <Box
              sx={{
                zIndex: 1000,
                p: 2,
                bgcolor: "#fff",

                maxHeight: "300px",
                overflow: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {(data && data?.searchGlobalInput?.length === 0) || !data ? (
                <>
                  <Typography
                    sx={{ color: "#23263B", fontWeight: 600, fontSize: "12px" }}
                  >
                    Sorry, we didn’t find any matches for "{query}"
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#333",
                      fontWeight: 600,
                      fontSize: "10px",
                    }}
                  >
                    Try the following:
                  </Typography>
                  <Typography sx={{ fontSize: "10px", color: "#333", mt: -1 }}>
                    <ul className="no_search_list">
                      <li style={{ fontSize: "10px" }}>
                        Double check your spelling
                      </li>
                      <li style={{ fontSize: "10px" }}>Use fewer keywords</li>
                      <li style={{ fontSize: "10px" }}>
                        Search fo an item that is less specific and refine
                        results
                      </li>
                    </ul>
                  </Typography>
                </>
              ) : (
                <>
                  <List sx={{}}>
                    {data?.searchGlobalInput.map((item, index) => (
                      <Link
                        to={
                          item.source == "product"
                            ? `/product-view/${item?.result?._id}`
                            : `/service-view/${item?.result?._id}`
                        }
                        key={index}
                      >
                        <ListItemButton>
                          <WiDirectionUpRight style={{ color: "#333" }} />
                          {item?.source == "product" ? (
                            <Typography
                              sx={{ ml: 2, color: "#333", fontSize: "13px" }}
                            >
                              {item?.result?.name}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ ml: 2, color: "#333", fontSize: "13px" }}
                            >
                              {item?.result?.title}
                            </Typography>
                          )}
                          <Typography
                            variant="label"
                            sx={{ fontSize: "11px", color: "#b3b3b3", ml: 1 }}
                          >{`(${item?.source})`}</Typography>
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 3, px: 2 }}>
            <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
              EVERYONE IS SEARCHING
            </Typography>
            <Box sx={{ mt: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
              {recentSearch.map((item, index) => (
                <>
                  <Box
                    sx={{
                      border: "1px solid #d7d7d7",
                      borderRadius: "50px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      width: "fit-content",
                      pl: 0.3,
                      pr: 1,
                      bgcolor: item?.label === query && "#5C30691a",
                    }}
                    key={index}
                    onClick={() => setQuery(item?.label)}
                  >
                    <Box
                      sx={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        boxSizing: "border-box",
                        bgcolor: "#5C3069",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 900,
                        }}
                      >
                        {item?.type === "product" ? (
                          <PiPackageLight />
                        ) : (
                          <HiMiniWrenchScrewdriver />
                        )}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "10px", fontWeight: 400 }}>
                      {item.label}
                    </Typography>
                  </Box>
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default FullSearchBar;
