import { Box, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import { NavData } from "../../assets/Data/NavData";
import { Link, useLocation } from "react-router-dom";

const SideNav = () => {
  const router = useLocation();
  return (
    <>
      <Box>
        <List>
          {NavData.map((nav, id) => (
            <Link to={nav.link} key={id}>
              <ListItemButton
                selected={id === 0 ? router.pathname === nav.link : router.pathname.includes( nav.link) }
                sx={{
                  mr: 2,
                  pl: 5,
                       color: "#1515151",
                  " 	&.Mui-selected": {
                    bgcolor: "#D7B9DF",
                    borderLeft: "4px solid #2E1834",
                
                    "& > span ": {
                      color: "#1515151",
                    },
                  },
                }}
              >
                <ListItemText sx={{ color: "#666",     fontWeight:600, }}>
                  <Typography sx={{fontWeight:500, fontSize:'13px',      color: "#151515",}}>{nav.title}</Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    </>
  );
};

export default SideNav;
