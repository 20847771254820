import React, { useEffect } from "react";
import Navbar from "../../Components/Common/Navbar";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import thumbnail from "../../assets/Products/Thumbnail Images.png";
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../Components/Common/Footer";
import Newsletter from "../../Components/Common/Newsletter";
import { useSelector, useDispatch } from "react-redux";
import emptyFav from "../../assets/Icons/emptyFav.svg";
import { removeFav } from "../../store/favouriteSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_FAVOURITES } from "../../Utils/Queries";
import { TbShoppingCartPlus } from "react-icons/tb";
import { Link } from "react-router-dom";
import useAddToCarts from "../../Hooks/useAddToCarts";
import { gql } from "@apollo/client";
import useRemoveFav from "../../Hooks/useRemoveFav";
import useGetFav from "../../Hooks/useGetFav";
import Loader from "../../Components/Common/Loader";

const Wishlist = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation()
  const favProducts = useSelector((state) => state.favourite);

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const handleToLogin = () => {
    navigate("/login" ,{
                            state: { redirectTo: location.pathname },
                          });
  };



  const { handleGetFav } = useGetFav();

  useEffect(() => {
    handleGetFav();
  }, []);

  const GET_FAVOURITE_ITEMS = gql`
    query GetFavourite {
      favItemsVar @client
    }
  `;

  const { data, loading, refetch } = useQuery(GET_FAVOURITE_ITEMS);
  const { handleRemoveFav } = useRemoveFav();

  console.log(data);

  const { handleAddToCart, isLoading, isSuccess } = useAddToCarts();
  return (
    <>

    {
      isLoading && <Loader/>
    }
      <Navbar />
      <Box
        sx={{
          pt: { lg: 15, md: 15, sm: 10, xs: 9 },
          margin: "0 auto",
          width: { lg: "85%", md: "85%", sm: "90%", xs: "95%" },
          mb: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "36px", md: "35px", sm: "28px", xs: "25px" },
            fontWeight: 600,
            color: "#333",
          }}
        >
          Wishlist
        </Typography>
        <Box sx={{ bgcolor: "#fff", p: 3, my: 3 }}>
          {data?.favItemsVar?.length === 0 ? (
            <>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 4,
                  mt: 3,
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
              >
                <img src={emptyFav} />
              </Box>
            </>
          ) : (
            <>
              {isMobile ? (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "15px",
                          
                              fontWeight: 500,
                              color: "#333",
                            }}
                          >
                            Product & Services
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: "15px",
                          
                              fontWeight: 500,
                              color: "#333",
                            }}
                            align="right"
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      {data?.favItemsVar?.map((product) => (
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  variant="square"
                                  src={product?.productOrService?.images[0]}
                                  sx={{ width: "40px", height: "40px" }}
                                />
                                <Box>
                                  <Typography
                                    sx={{
                                      color: "#121212",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      maxWidth: "100px",

                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "noWrap",
                                    }}
                                  >
                                    {product?.productOrService?.name
                                      ? product?.productOrService?.name
                                      : product?.productOrService?.title}
                                  </Typography>
                                  <Button
                                    onClick={() => {
                                      handleRemoveFav(product?._id);
                                      refetch();
                                    }}
                                    sx={{
                                      color: "#FF4C5E",
                                      fontSize: "10px",
                                      pl: 0,
                                    }}
                                  >
                                    <img
                                      src={trash}
                                      alt="trash_icon"
                                      style={{ marginRight: "3px" }}
                                      width={10}
                                    />{" "}
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell align="right">
                              <Box>
                                {product?.isProduct ? (
                                  <IconButton
                                    onClick={
                                      isAuthenticated
                                        ? () =>
                                            handleAddToCart(
                                              product?.productOrService?._id,
                                              1
                                            )
                                        : handleToLogin()
                                    }
                                    sx={{
                                      color: "#333",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Add to Cart
                                    {/* <TbShoppingCartPlus style={{marginLeft:4, fontSize:'25px'}} /> */}
                                  </IconButton>
                                ) : (
                                  <Link
                                    to={`/service-view/${product?.productOrService?._id}`}
                                  >
                                    <Button
                                      sx={{
                                        color: "#333",
                                        fontSize: "10px",
                                        fontWeight: 600,
                                        whiteSpace: "noWrap",
                                      }}
                                    >
                                      Book Appointment
                                    </Button>
                                  </Link>
                                )}
                              </Box>
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                }}
                              >
                                $
                                {product?.productOrService?.price?.toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                        
                            fontWeight: 500,
                            color: "#333",
                          }}
                        >
                          Product
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "15px",
                        
                            fontWeight: 500,
                            color: "#333",
                          }}
                          align="right"
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                        
                            fontWeight: 500,
                            color: "#333",
                          }}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    {data?.favItemsVar?.map((product) => (
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Avatar
                                variant="square"
                                src={product?.productOrService?.images[0]}
                                sx={{ width: "70px", height: "70px" }}
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#121212",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    maxWidth: "300px",

                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "noWrap",
                                  }}
                                >
                                  {product?.productOrService?.name
                                    ? product?.productOrService?.name
                                    : product?.productOrService?.title}
                                </Typography>
                                <Button
                                  onClick={() => {
                                    handleRemoveFav(product?._id);
                                    refetch();
                                  }}
                                  sx={{ color: "#FF4C5E", fontSize: "10px" }}
                                >
                                  <img
                                    src={trash}
                                    alt="trash_icon"
                                    style={{ marginRight: "3px" }}
                                  />{" "}
                                  Remove
                                </Button>
                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell align="right">
                            <Typography
                              sx={{
                                color: "#333",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              $
                              {product?.productOrService?.price?.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {product?.isProduct ? (
                              <Button
                                onClick={
                                  isAuthenticated
                                    ? () =>
                                        handleAddToCart(
                                          product?.productOrService?._id,
                                          1
                                        )
                                    : handleToLogin
                                }
                                sx={{
                                  color: "#333",
                                  fontSize: "13px",
                                  fontWeight: 600,
                                }}
                              >
                                Add to Cart
                                <TbShoppingCartPlus style={{ marginLeft: 4 }} />
                              </Button>
                            ) : (
                              <Link
                                to={`/service-view/${product?.productOrService?._id}`}
                              >
                                <Button
                                  sx={{
                                    color: "#333",
                                    fontSize: "13px",
                                    fontWeight: 600,
                                  }}
                                >
                                  Book Appointment
                                </Button>
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Wishlist;
