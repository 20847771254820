import {
  Box,
  IconButton,
  Typography,
  Chip,
  Avatar,
  Grid,
  Modal,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import timeIcon from "../../../assets/Icons/time.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SINGLE_ORDER,
  SINGLE_SERVICE_APPOINTMENT,
} from "../../../Utils/Queries";
import Loader from "../../../Components/Common/Loader";
import { CANCEL_ORDER } from "../../../Utils/Mutations";
import declinedImg from "../../../assets/Icons/declined 2.svg";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useSnackbar } from "notistack";
import ReleaseFunds from "./ReleaseFunds";
import ServiceReleaseFunds from "./ServiceReleaseFunds";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const themeStepper = createTheme({
  palette: {
    primary: {
      main: "#5C3069",
    },
  },
});

const ServiceOrderDetails = () => {
  const { id } = useParams();
  const [rejectReasons, setRejectReasons] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [activeStep, setActiveStep] = React.useState(4);
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const handleChange = (event) => {
    setRejectReasons(event.target.value);
  };

  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "iiii, do 'of' MMMM yyyy, h:mm a");
    return formattedDate;
  };
  function formatDate2(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

    function formatDate3(dateString) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/\//g, " - ");
    const formattedTime = date.toLocaleTimeString("en-US", options);
    return `${formattedTime}`;
  }

  const [getSingleOrder, { loading, data }] = useLazyQuery(
    SINGLE_SERVICE_APPOINTMENT
  );

  useEffect(() => {
    getSingleOrder({
      variables: {
        reference: id,
      },
    });
  }, [id]);

  const {
    offerAmount,
    createdAt,
    quantity,
    service,
    status,
    statusHistory,
    _id,
    scheduledDate,
    scheduledTime,
    updatedAt
  } = data?.fetchBuyerAppointments?.items[0] || [];



  useEffect(() => {
    if (status) {

      if (status === "STARTED") {
        setActiveStep(1);
      } 
      else if (status === "PAID"){
setActiveStep(0)
      }
      
      else if (status === "COMPLETED") {
        setActiveStep(2);

      } 

      else if (status === "CANCELLED") {
        setIsCancelled(true);
      }
      else if(status === "RELEASE FUNDS"){
        setActiveStep(3)
      }
    }
  }, [status]);

  const [cancelOrder, { loading: cancelLoad, data: cancelData }] = useMutation(
    CANCEL_ORDER
  );

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleCancelOrder = () => {
    setSuccess(false);
    setIsLoading(true);
    cancelOrder({
      variables: {
        itemId: _id,
        reason: rejectReasons,
        description,
      },
    })
      .then((res) => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
                    handleAlert(`${err.message}`, "error")
      });
  };
  return (
    <>
      {cancelLoad || (isLoading && <Loader />)}

      {loading && <Loader />}
      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/account/orders">
            <ArrowBackIosIcon
              sx={{ color: "#1a1a1a", fontSize: "15px", mb: -0.2 }}
            />
          </Link>
          <Typography sx={{ fontSize: "12px", ml: 2 }}>
            Order ID:
            <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
              {" " + id}
            </span>
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "12px", color: "#666" }}>
          Purchase date:{" "}
          <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
            {createdAt && formatDate(createdAt)}
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
            width: "56%",
          }}
        >
          <Avatar
            variant="square"
            src={service && service.images[0]}
            sx={{ width: "80px", height: "60px" }}
          />
          <Box sx={{ width: "90%" }}>
            <Typography
              sx={{
                color: "#1a1a1a",
                fontSize: "14px",
                fontWeight: 600,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width:'90%'
              }}
            >
              {service && service.title}
            </Typography>
            <Box sx={{ display: "flex", columnGap:3 }}>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Offered Amount
              </Typography>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 700,
                  ml: 5,
                }}
              >
                {" "}
                ${offerAmount && offerAmount}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          {activeStep === 0 && (
            <Chip label="Booked" sx={{ fontSize: "10px" }} />
          )}
          {activeStep === 1 && (
            <Chip
              label="Started"
              sx={{
                color: "#FFC533",
                bgcolor: "#FFC5331a",

                fontSize: "10px",
              }}
            />
          )}
          {activeStep === 2 && (
            <Chip
            color="warning"
              label="Release Funds"
              sx={{
          

                fontSize: "10px",
              }}
            />
          )}
          {activeStep === 3 && (
            <Chip
              label="Completed"
              sx={{
                color: "#5C3069",
                bgcolor: "#5C30691a",

                fontSize: "10px",
              }}
            />
          )}
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Typography>Status</Typography>
              <Box sx={{ maxWidth: 450 }}>
        <ThemeProvider theme={themeStepper}>
                <Timeline sx={{ ml: -20 }}>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{ width: "4px", height: "4px", p: 0 ,  bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                         }} />
                      <TimelineConnector   sx={{
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }} />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ fontSize: "12px" , fontWeight:500}}
                    >
                    Payment Confirmed
                         <Typography sx={{ fontSize: "9px" ,}}>
                        {activeStep === 0 &&
                          formatDate3(updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{ width: "4px", height: "4px", p: 0 ,  bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                         }} />
                      <TimelineConnector   sx={{
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }} />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ fontSize: "12px" , fontWeight:500}}
                    >
                    Appointment Booked
                         <Typography sx={{ fontSize: "9px" , }}>
                        {activeStep === 0 &&
                          formatDate3(updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem activeStep>
                    <TimelineSeparator>
                      <TimelineDot sx={{ width: "4px", height: "4px", p: 0,     bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null, }} />
                      <TimelineConnector    sx={{
                          bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null,
                        }}/>
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px", fontWeight:500 }}
                    >
               Service Started
                              <Typography sx={{ fontSize: "8px" ,}}>
                    Service now active! Provider has initiated service.
                      </Typography>
                              <Typography sx={{ fontSize: "9px" ,}}>
                        {activeStep >= 1 &&
                          formatDate3(updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{ width: "4px", height: "4px", p: 0,   bgcolor:
                            activeStep >= 2 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 2
                              ? "#2E1834"
                              : null, }} />
                   
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" , fontWeight:500}}
                    >
            Completed
                           
                    </TimelineContent>
                  </TimelineItem>
            
                </Timeline>
                </ThemeProvider>
                {
                  activeStep === 2 && (
                    <>
                    <Box sx={{border:'1px solid #f2f2f2', mt:-7, p:2, boxSizing:'border-box', borderRadius:'10px'}}>
<Typography sx={{color:'#333', fontWeight:600, fontSize:'12px', lineHeight:'20px'}}>Are you satisfied with the service delivered?</Typography>
<Box sx={{mt:1}}>
  <ServiceReleaseFunds id={_id}/>
</Box>
                    </Box>
                    </>
                  )
                }
              </Box>
            </Box>
          </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          {activeStep === 5 && (
            <>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  Are you satisfied with the product delivered?
                </Typography>
                <Button sx={{ border: "1px solid #E6E6E6", color: "#333" }}>
                  No, Dispute
                </Button>
                <Button sx={{ bgcolor: "#5C3069" }} variant="contained">
                  Yes, Release Fund
                </Button>
              </Box>
            </>
          )}
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
              More Info
            </Typography>
            {/* <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                Delivery Type:
              </Typography>
              <Typography
                sx={{ fontWeight: 700, fontSize: "12px", color: "#333" }}
              >
                Pick Up
              </Typography>
            </Box> */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              <Typography
                sx={{ width: "fit-content", fontSize: "12px", fontWeight: 500 }}
              >
                Scheduled Date & Time
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: "#333",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              >
                {formatDate2(scheduledDate)} - {scheduledTime}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
              Payment
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography       sx={{ width: "fit-content", fontSize: "12px", fontWeight: 500 }}>Amount:</Typography>
              <Typography sx={{ fontWeight: 700, color: "#333", fontSize:'12px' }}>
                ${offerAmount && offerAmount.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography      sx={{ width: "fit-content", fontSize: "12px", fontWeight: 500, width: "fit-content"  }}>
                Payment Method:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: "#333",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              >
                Mamapurse
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#fff",
              p: 2,
              mt: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            {activeStep === 0 ? (
              <>
              </>
              // <Button
              //   onClick={handleOpen}
              //   color="error"
              //   sx={{ textDecoration: "underline", fontWeight: 600 }}
              // >
              //   CANCEL ORDER
              // </Button>
            ) : (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={timeIcon} />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#333",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    Need help?
                    <Link to="/contact" style={{ color: "#2E1834", fontWeight: 500 }}>
                      {" "}
                      Contact Support{" "}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box align="center" sx={{ p: 5 }}>
                <img src={declinedImg} />
                <Typography sx={{ color: "#333", fontWeight: 500 }}>
                  Order Cancelled
                </Typography>
                <Typography sx={{ color: "#333", mt: 2 }}>
                  A refund will be issued and credited to your MamaPurse wallet.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ mt: 3 }}
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: 400 }}>
                  Cancel Order
                </Typography>
                <IconButton onClick={handleClose}>
                  {" "}
                  <ClearIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Typography sx={{ mt: 2, color: "#333", lineHeight: "145%" }}>
                By clicking "cancel order," you confirm the cancellation of your
                purchase. A refund will be issued and credited to your MamaPurse
                wallet.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Select
                  displayEmpty
                  fullWidth
                  sx={{
                    borderRadius: "10px",
                    background: "#fff",
                    offset: " 0px solid #737373",
                    border: "0px solid red",
                  }}
                  InputProps={{
                    style: {
      
                      fontSize: "13px",

                      // Replace with your desired font family
                    },
                  }}
                  value={rejectReasons}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    Reason for cancellation
                  </MenuItem>
                  <MenuItem value="Better Alternative ">
                    Better Alternative{" "}
                  </MenuItem>
                  <MenuItem value="High Cost ">High Cost </MenuItem>
                  <MenuItem value="Dissatisfaction with quality">
                    {" "}
                    Dissatisfaction with quality{" "}
                  </MenuItem>
                  <MenuItem value="Others "> Others </MenuItem>
                </Select>

                <TextField
                  fullWidth
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={6}
                  margin="normal"
                  placeholder="more description"
                  InputProps={{
                    style: {
      
                      fontSize: "13px",
                      borderRadius: "10px",
                      background: "#fff",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 3,
                }}
              >
                <Button
                  fullWidth
                  sx={{ border: "1px solid #E6E6E6", color: "#333" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  fullWidth
                  color="error"
                  onClick={handleCancelOrder}
                  variant="contained"
                  disabled={rejectReasons === "" || !description}
                >
                  Yes, Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ServiceOrderDetails;
