import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const CardInput = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
    const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          alert("we did it")
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
              handleAlert(`Your payment was not successful, please try again.`, "error")
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, response } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://mamaket.com/account/mamapurse/confirmation",
      },
    });


    if (error.type === "card_error" || error.type === "validation_error") {
          handleAlert(`${error.message}`, "error")
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!stripe && !elements && (
        <Box align="center">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
            width={150}
          />
        </Box>
      )}
      {elements && (
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      )}

      <Box sx={{ mt: 3 }}>
        <Button
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
          variant="contained"
          fullWidth
        >{`Pay Now $${amount}`}</Button>
      </Box>

      {/* Show any error or success messages */}
      {message && <Typography id="payment-message" >{message}</Typography>}
    </form>
  );
};

export default CardInput;
