import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { RegSchema } from "../../Utils/Schema";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import Loader from "../../Components/Common/Loader";
import { SIGNUP_MUTATION } from "../../Utils/Mutations";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { setUserId, setUserEmail } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { GET_COUNTRIES } from "../../Utils/Queries";
import countryFlag from '../../assets/Icons/united-states 1.svg'

const Register = () => {
  const [value, setValue] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [response, setResponse] = useState("");
  const [country, setCountryId] = useState("");
  const [flagUrl, setFlagUrl] = useState("");
  const [dailCode, setDailCode] = useState(1);
  const [lat, setLatitude] = useState(null);
  const [long, setLongitude] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange2 = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    getLocation();
    getCountries();
  }, []);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const [getCountries, { loading, error, data }] = useLazyQuery(GET_COUNTRIES);
  const [signup, { loading: signUpLoad, error: signUpError }] = useMutation(
    SIGNUP_MUTATION
  );

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      password: "",
    },
    validationSchema: RegSchema,
    onSubmit: () => {
      const { firstName, lastName, email, password } = values;
      const phone = `+${dailCode}${values.phone}`;

      const favouriteCategories = [];
      signup({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          password: password,
          lat: lat ? lat : 0,
          long: long ? long : 0,
          country: country,
          favouriteCategories: favouriteCategories,
        },
      })
        .then((response) => {
          // Handle successful signup

          dispatch(setUserId(response?.data?.signUp?.uuid));
          dispatch(setUserEmail(email));
          navigate("/verify");
        })
        .catch((err) => {
          // Handle signup error
          
        });
    },
  });
  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Create Account
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 400, fontSize: "14px" }}>
            Please enter your detail
          </Typography>

          <Box sx={{ mt: 3 }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
              
              }}
            >
              First name
            </InputLabel>
            <TextField
              error={errors.firstName}
              helperText={errors.firstName}
              id="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              //   placeholder="Email Address"
              size="large"
              fullWidth
              InputProps={{
                style: {
        
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mt: 1.5,
              
              }}
            >
              Last name
            </InputLabel>
            <TextField
              error={errors.lastName}
              helperText={errors.lastName}
              id="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              //   placeholder="Enter Password"
              size="large"
              fullWidth
              InputProps={{
                style: {
        
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mt: 1.5,
              
              }}
            >
              Email address
            </InputLabel>
            <TextField
              error={errors.email}
              helperText={errors.email}
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              //   placeholder="Enter Password"
              size="large"
              fullWidth
              InputProps={{
                style: {
        
                  marginTop: "10px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mt: 1.5,
              
              }}
            >
              Phone Number
            </InputLabel>
            <TextField
              error={errors.phone}
              helperText={errors.phone}
              id="phone"
              type="number"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              //   placeholder="Enter Password"
              size="large"
              fullWidth
              InputProps={{
                   inputMode:'numeric', pattern:'[0-9]*',
                startAdornment: (
                  <InputAdornment position="start"><img src={countryFlag} style={{marginRight:'10px'}}/>{`+ ${dailCode}`}</InputAdornment>
                ),
                style: {
        
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mb: 1.5,
              
              }}
            >
              Country
            </InputLabel>
            <Select
              fullWidth
              value={value}
              onChange={handleChange2}
              sx={{
                fontSize: "13px",
                // border: "0.5px solid #671E78",
            
                borderRadius: "10px",
              }}
              inputProps={{
                "aria-label": "Without label",
                style: {
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontWeight: 600,
                  mt: 1.5,
                },
              }}
            >
              {data?.getAllCountries?.items.map(
                ({ name, _id, flagUrl, dialingCode }) => (
                  <MenuItem
                    value={name}
                    sx={{ p: 1, display: "flex", alignItems: "center" }}
                    onClick={(e) => {
                      setDailCode(dialingCode);
                      setCountryId(_id);
                    }}
                  >
                    <Typography sx={{ ml: 2, fontSize: "12px" }}>
                      {name}
                    </Typography>
                  </MenuItem>
                )
              )}
            </Select>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                mt: 3,
              
              }}
            >
              Password
            </InputLabel>
            <TextField
              error={errors.password}
              helperText={errors.password}
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              size="large"
              fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: {
        
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Link to="/verify"> */}
            {signUpError && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,
              
                  fontSize: "12px",
                }}
              >
                {signUpError.message}
              </Typography>
            )}
            {!signUpLoad && (
              <Button
                onClick={handleSubmit}
                variant="contained"
                fullWidth
                sx={{ borderRadius: "20px", py: 1, mt: 3 }}
              >
                Sign Up
              </Button>
            )}

            {/* </Link> */}
            {signUpLoad && (
              <>
                <LoadingButton
                  fullWidth
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled
                  sx={{ borderRadius: "20px", py: 1, mt: 3 }}
                >
                  Signing Up
                </LoadingButton>
              </>
            )}
            <Typography
              sx={{
                textAlign: "center",
                mt: 1,
                fontSize: "14px",
                color: "#666",
              }}
            >
              Already on Mamaket?
              <Link
                to="/"
                style={{
                  textDecoration: "none",
              
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#CC9C00",
                  marginLeft: "5px",
                }}
              >
                Sign in
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Register;
