import React, { useEffect, useState } from "react";
// Mui Components
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Child Component
import Navbar from "../../Components/Common/Navbar";
import FullSearchBar from "../../Components/Search/FullSearchBar";
import Carousel from "../../Components/Common/Carousel";
import Footer from "../../Components/Common/Footer";
import CategoriesBanner from "../../Components/Common/CategoriesBanner";
import LatestProducts from "../../Components/Products/LatestProducts";
import ServiceListings from "../../Components/Services/ServiceListings";
import TopSeller from "../../Components/Products/TopSeller";
import Newsletter from "../../Components/Common/Newsletter";
import SellsBanner from "../../Components/Common/SellsBanner";
import MamaketOffer from "../../Components/Common/MamaketOffer";
import NewsBlog from "../../Components/Blog/NewsBlog";
import googlestore from "../../assets/googlestore.svg";
import appstore from "../../assets/appstore.svg";

// Actions
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/productSlice";
import StatusCode from "../../Utils/StatusCode";
import { Navigate } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { logoutUser } from "../../store/userSlice";

const Home = () => {
  const { status } = useSelector((state) => state.products);
  const isAuthenticated = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [open2, setOpen2] = useState(false);
  const theme = useTheme()
const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpen2(true);
    }, 6000);
  }, []);

  useEffect(()=>{
    if(isAuthenticated && status === StatusCode.ERROR){
      dispatch(logoutUser())
    }
  }, [status])
  return (
    <>
 
      <Navbar />
      <Box sx={{ mt: { lg: 13, md: 12, sm: 8, xs: 8 },  }}>
        <FullSearchBar />
      </Box>
      <Carousel />
      <Box id="latestProducts">
        <CategoriesBanner />
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: {xl:'75%', lg: "85%", md: "87%", sm: "100%", xs: "95%" },
          mt: 3,
        }}
      >
        <LatestProducts />
        <ServiceListings />
        <Box id="topProducts">
          <SellsBanner />
        </Box>
        <Box id="ourvalue">
          <TopSeller />
        </Box>
        <Box id="news">
          <MamaketOffer />
        </Box>
        <NewsBlog />
      </Box>
      <Newsletter />
      <Footer />

{
  isMobile && (

      <Drawer open={open2} anchor="top" onClose={() => setOpen2(false)} sx={{zIndex:5000}}>
        <Box sx={{ height: "100px", bgcolor: "#7B408C" }}>
          <Box align="right">
            <IconButton onClick={() => setOpen2(false)}>
              <CloseOutlined sx={{ color: "#fff", fontWeight: 700 }} />
            </IconButton>
          </Box>
          <Typography
            sx={{
              px: 2,
              fontSize: "12px",
              fontWeight: 900,
              mt: -3,
              color: "#fff",
            }}
          >
            Download the mobile app
          </Typography>
          <Box
            sx={{
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button>
              <img src={googlestore} />
            </Button>
            <Button>
              <img src={appstore} />
            </Button>
          </Box>
        </Box>
      </Drawer>
  )
}
    </>
  );
};

export default Home;
