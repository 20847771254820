import React, { useState } from "react";

// Mui Components
import { Box } from "@mui/material";

// Router
import { Link, useParams } from "react-router-dom";

// Assets
import "../../STYLES/main.css";

// Child Component
import Navbar from "../../Components/Common/Navbar";
import Overview from "../../Components/Products/ProductsView/Overview";
import Description from "../../Components/Products/ProductsView/Description";
import SimilarProducts from "../../Components/Products/ProductsView/SimilarProducts";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";


const ProductView = () => {
  const { id } = useParams();

  return (
    <>
      <Navbar />
      <Box>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
            mt: { lg: 15, md: 15, sm: 8, xs: 9 },
          }}
        >
          <Overview product_id={id} />
          <Description productID={id} />
          {/* <SimilarProducts /> */}
        </Box>
      </Box>

      <Newsletter />
      <Footer />
    </>
  );
};

export default ProductView;
