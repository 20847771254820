import {
  Box,
  Tabs,
  Tab,
  Typography,
  Chip,
  Avatar,
  MenuItem,
  createTheme,
  ThemeProvider,
  Grid,
  Select,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import emptyCart from "../../../assets/Icons/empty_cart.svg";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { SERVICE_APPOINTMENT } from "../../../Utils/Queries";
import { useSnackbar } from "notistack";
import Loader from "../../../Components/Common/Loader";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

function formatDate(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1.5, bgcolor: "#F8F7F7" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ServiceOrder = () => {
  const [value, setValue] = React.useState(0);

  const [getAppointments, { data, loading }] = useLazyQuery(
    SERVICE_APPOINTMENT
  );

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    getAppointments()
      .then((res) => {})
      .catch((err) => {
        handleAlert(`${err.message}`, "error");
      });
  }, []);

  const appointment = data?.fetchBuyerAppointments?.items;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {loading && <Loader />}
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: "#fff", p: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="All"
                sx={{
              
                     fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Booked"
                {...a11yProps(1)}
                sx={{
              
                     fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
              />
              <Tab
                label="Started"
                {...a11yProps(2)}
                sx={{
              
                     fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
              />
              <Tab
                label="Release Funds"
                {...a11yProps(3)}
                sx={{
              
                     fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
              />
              <Tab
                label="Completed"
                {...a11yProps(4)}
                sx={{
              
                     fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
              />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <All appointment={appointment} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Booked appointment={appointment} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Started appointment={appointment} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ReleaseFunds appointment={appointment} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Completed appointment={appointment} />
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
};

export default ServiceOrder;

const All = ({ appointment }) => {

  const [empty, setEmpty] = useState(null);
    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, appointment?.length);
  const rowsText = `${startRow}-${endRow} of ${appointment?.length}`;
  return (
    <>
      {!appointment || appointment?.length  ===  0 && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {appointment &&
        appointment?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )?.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/service/${item.reference}`
            }
          >
            <OrderBox item={item} />
          </Link>
        ))}

         <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
       
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
         
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
         
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
           
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{  color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === appointment?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};



const Started = ({ appointment }) => {
  const [empty, setEmpty] = useState(null);
    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const Started = appointment?.filter((item) => item?.status === "STARTED");
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, Started?.length);
  const rowsText = `${startRow}-${endRow} of ${Started?.length}`;
  return (
    <>
      {!Started || Started?.length  ===  0 && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {Started &&
        Started?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )?.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/service/${item.reference}`
            }
          >
            <OrderBox item={item} />
          </Link>
        ))}

         <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
       
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
         
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
         
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
           
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{  color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === Started?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Completed = ({ appointment }) => {

  const [empty, setEmpty] = useState(null);
    const completed = appointment?.filter((item) => item?.status === "RELEASE_FUND");
    const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, completed?.length);
  const rowsText = `${startRow}-${endRow} of ${completed?.length}`;
  return (
    <>
      {!completed || completed?.length  ===  0 && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {completed &&
        completed?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )?.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/service/${item.reference}`
            }
          >
            <OrderBox item={item} />
          </Link>
        ))}

         <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
       
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
         
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
         
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
           
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{  color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === completed?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Booked = ({ appointment }) => {
  const [empty, setEmpty] = useState(null);
    const [page, setPage] = React.useState(0);
      const booked = appointment?.filter((item) => item?.status === "PAID");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, booked?.length);
  const rowsText = `${startRow}-${endRow} of ${booked?.length}`;
  return (
    <>
      {!booked || booked?.length  ===  0 && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {booked &&
        booked?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )?.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/service/${item.reference}`
            }
          >
            <OrderBox item={item} />
          </Link>
        ))}

         <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
       
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{  color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === booked?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const ReleaseFunds = ({ appointment }) => {
  const [empty, setEmpty] = useState(null);
    const [page, setPage] = React.useState(0);
      const booked = appointment?.filter((item) => item?.status === "COMPLETED");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, booked?.length);
  const rowsText = `${startRow}-${endRow} of ${booked?.length}`;
  return (
    <>
      {!booked || booked?.length  ===  0 && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {booked &&
        booked?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )?.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/service/${item.reference}`
            }
          >
            <OrderBox item={item} />
          </Link>
        ))}

         <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{  color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{  color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === booked?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const OrderBox = ({ item }) => {
  return (
    <>
      <MenuItem
        disabled={item.status === "Canceled"}
        key={item?._id}
        sx={{ bgcolor: "#fff", p: 1, mt: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              width: "fit-content",
              width: "60%",
            }}
          >
            <Avatar
              src={item?.service?.images[0]}
              variant="square"
              sx={{ width: "80px", height: "100%" }}
            />
            <Box sx={{ width: "60%" }}>
              <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                {item?.reference}
              </Typography>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "14px",
                  fontWeight: 600,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.service?.title}
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
              >
                <Typography
                  sx={{
                    color: "#1a1a1a",
                    fontSize: "12px",
                    fontWeight: 400,
                    mr: 2,
                  }}
                >
                  ${item?.offerAmount.toLocaleString()}
                </Typography>
                <Typography
                  sx={{
                    color: "#1a1a1a",
                    fontSize: "12px",
                    fontWeight: 500,
                    borderLeft: "1px solid",
                    paddingLeft: 2,
                  }}
                >
                  {formatDate(item?.scheduledDate)} /{item?.scheduledTime}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            {
              item?.status === "STARTED" ? (
      <Chip label="Started" sx={{fontSize:'10px', fontWeight:500, color:'#FFC533', bgcolor:'#FFF8E5'}} />
              ):
              item?.status === "RELEASE_FUND" ? (
                      <Chip color="success" label="Completed" sx={{fontSize:'10px', fontWeight:500}}  />
              ):
              item.status  === "COMPLETED" ? (
                      <Chip color ="warning"  label="Release Funds" sx={{fontSize:'10px', fontWeight:500, p:0.2}}  />
              ):
              item.status === "PAID" ? (
                      <Chip label="Booked" sx={{fontSize:'10px', fontWeight:500}}  />
              ):(
                      <Chip  label="Booked"  sx={{fontSize:'10px', fontWeight:500, p:0.2}} />
              )
            }
      
          </Box>
        </Box>
      </MenuItem>
    </>
  );
};
