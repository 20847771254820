import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import Newsletter from "../../Components/Common/Newsletter";
import { Box, Divider, Typography } from "@mui/material";
import clock from "../../assets/Icons/clock.svg";
import img1 from "../../assets/newton.jpeg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { newsData } from "../../Components/Blog/NewsBlog";
import ReactMarkdown from 'react-markdown';

const SingleBlog = () => {
  const [filteredBlog, setFilterBlog] = useState(null);
  const navigate = useNavigate();

  const { name } = useParams();

  useEffect(() => {
    const filtered = newsData.filter((item) => item.title === name);
    setFilterBlog(filtered[0]);
    console.log(filtered);
  }, [name]);
  return (
    <>
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          mt: { lg: 15, md: 12, sm: 8, xs: 10 },
          mb: 4,
          bgcolor: "#fff",
          p: 4,
          px: { md: 4, xs: 2 },
          width: { md: "85%", xs: "95%" },
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            justifyContent: "center",
          }}
        >



          <Typography sx={{ color: "#000" }}>{filteredBlog?.date}</Typography>
        </Box>
        <Typography
          sx={{
            color: "#151515",
            fontWeight: 700,
            fontSize: { md: "30px", xs: "30px" },
            mt: 3,
            textAlign: "center",
          }}
        >
          {filteredBlog?.title}
        </Typography>

        <Box
          sx={{
            mt: 4,
            height: { md: "400px", xs: "300px" },
            background: `url('${filteredBlog?.img}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "12px",
          }}
        ></Box>
        <Divider sx={{ width: "100%", my: 2 }} />

        <Box sx={{ mt: 3 }}>
                    <Typography>
            Written By:{" "}
            <span style={{ color: "#000" }}>{filteredBlog?.author}</span>
          </Typography>
          {
            filteredBlog && (
              <Blog fileName={`${filteredBlog?.markdown}.md`} />
            )
          }
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default SingleBlog;

const Blog = ({ fileName }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
if(fileName){
      import(`../../BlogContent/${fileName}`)
      .then((res) => fetch(res.default))
      .then((response) => response.text())
      .then((text) => setContent(text));
}
  }, [fileName]);

  return <ReactMarkdown>{content}</ReactMarkdown>;
};
