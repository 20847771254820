import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
  TableContainer,
} from "@mui/material";
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useRemoveCarts from "../../Hooks/useRemoveCart";
import useGetCart from "../../Hooks/useGetCart";
import useGetServiceCart from "../../Hooks/useGetServiceCart";
import Loader from "./Loader";
import useIncreaseCarts from "../../Hooks/useIncrease";

const CustomMobileCart = ({ renderedCart, loading }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const [itemId, setItemId] = useState("");
  const { handleRemoveCart, isLoading } = useRemoveCarts();
  const { handleGetCart, refetch } = useGetCart();
    const { handleGetCart:handleServiceCart, refetch:refecthCart } = useGetServiceCart();
  const { handleIncreaseCart } = useIncreaseCarts();

    const totalSubtotal = renderedCart.reduce(
      (total, item) => total + item.totalAmount,
      0
    );
  useEffect(() => {
    setItemId(itemId);
  }, [itemId]);
  useEffect(() => {
    handleGetCart();
    handleServiceCart();
  }, []);
  const removeCart = (item_id) => {
    setItemId(item_id);
    handleRemoveCart(item_id);
    handleGetCart();
  };

  const handleIncreaseQuantity = async (id, quantity) => {
    const newQuantity = quantity + 1;
    handleIncreaseCart(id, newQuantity);
    handleGetCart();
  };
  const handleDecreaseQuantity = async (id, quantity) => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      handleIncreaseCart(id, newQuantity);
      handleGetCart();
    }
  };
  return (
    <>
    {
                        loading && <Loader/>
    }
      <Box>
        {renderedCart &&
          renderedCart.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 2,
                borderBottom: "0.5px solid #e6e6e6",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", columnGap: 2 }}>
                <Avatar
                  variant="square"
                  src={item?.product?.images[0]}
                  sx={{ width: "50px", height: "50px" , borderRadius:'8px'}}
                />
                <Box>
                  <Typography
                    sx={{
                      color: "#121212",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    {item?.product?.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    &#36;
                    {(item?.totalAmount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        border: "0.5px solid #ccc",
                        borderRadius: "4px",
                        display: "flex",
                        width: "80px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 1,
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          handleDecreaseQuantity(
                            item.product._id,
                            item.quantity
                          )
                        }
                      >
                        <RemoveIcon sx={{ fontSize: "10px" , }} />
                      </IconButton>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          color: "#121212",
                          fontSize: "12px",
                        }}
                      >
                        {item?.quantity}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          handleIncreaseQuantity(
                            item.product._id,
                            item.quantity
                          )
                        }
                      >
                        <AddIcon sx={{ fontSize: "10px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <IconButton
                sx={{
                  color: "#FF4C5E",
                  height: "50px",
                  width: "50px",
                }}
                onClick={() => removeCart(item._id)}
              >
                <img
                  src={trash}
                  alt="trash_icon"
                  style={{ marginRight: "3px" }}
                />{" "}
              </IconButton>
            </Box>
          ))}
        <Box>
          <Box sx={{ width: "100%", bgcolor: "#fff", mt: 3 }}>
            <Table>
              <TableHead>
                <TableCell align="right"></TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Subtotal</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "#1a1a1a",
                      fontWeight: 500,
           
                    }}
                  >
                    $
                    {totalSubtotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {isAuthenticated ? (
              <>
                <Link to="/checkout">
                  <Button
                    fullWidth
                    sx={{ mt: 3, borderRadius: "100px" }}
                    variant="contained"
                  >
                    Proceed To CheckOut
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{ mt: 3, borderRadius: "100px" }}
                  >
                    Sign in to proceed
                  </Button>
                </Link>
                <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                  New to Mamaket? <Link to="/register">Sign up </Link>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomMobileCart;
