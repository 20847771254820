import React from "react";
import Navbar from "../Components/Common/Navbar";
import { Box, Typography } from "@mui/material";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";

const Mamapurse = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },

          width: "85%",
          margin: "0 auto",
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 700, color: "#333" }}>
          Mamapurse
        </Typography>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 700, color: "#333", mt: 3 }}
        >
          A Digital Wallet for Smart Shopping
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 1 , textAlign:'justify'}}
        >
          The world of commerce is evolving. At Mamaket, we are at the forefront
          of this transformation. And that is why we are excited to include
          Mamapurse - an innovative digital wallet system - to revolutionizes
          the way our customers shop in our marketplace. With Mamapurse, we
          offer you a secure, convenient, and rewarding way to manage your
          purchases while safeguarding your financial assets.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
            Key Features of Mamapurse:
          </Typography>
          <ol>
            <li>
              Secure and Convenient Transactions: Our customers can now pay for
              goods and services through their mamapurse account in three easy
              steps. transferring the burden of their financial exposure from
              their bank cards to this innovative digital wallet system and
              making buying and selling more convenient and secure for all our
              customers. With Mamapurse, you no longer need to worry about the
              safety of your ATM cards or the hassle of carrying them around.
              Our cutting-edge wallet system ensures your financial security and
              allows you to shop with confidence.
            </li>
            <li>
              Budget-Friendly Shopping: Take control of your spending with
              Mamapurse. Set your budget and spend according to your financial
              goals. Avoid overspending and enjoy a stress-free shopping
              experience.
            </li>
            <li>
              Rewarding Loyalty Program: We value our users and appreciate your
              loyalty. That&#39;s why we offer Mamapurse points, giving you the
              opportunity to purchase items without dipping into your wallet.
              Shop smart and earn rewards while you do it.
            </li>
            <li>
              Encouraging Responsible Spending: Mamapurse encourages wise
              financial decisions. Budget your money, manage your expenses, and
              spend within your means. It&#39;s the perfect tool for those
              looking to achieve their financial goals.
            </li>
            <li>
              User-Friendly Interface: Making transactions and adding funds to
              your Mamapurse account is as easy as 1-2-3. Here&#39;s how:
              <br />
              <ul>
                <li>Log in to your Mamapurse account.</li>
                <li>Create a unique four-digit PIN for added security.</li>
                <li>
                  Easily add funds by linking your card to your Mamapurse
                  account.
                </li>
              </ul>
            </li>
            <li>
              Real-time Balance: Shop with confidence knowing your Mamapurse
              balance keeps you informed. Simply add funds whenever you like,
              even during your purchase. Please note that you must maintain a
              balance equal to or exceeding the total cost of items in your cart
              to complete your transaction successfully.
            </li>
          </ol>
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
            Mamapurse - A Mamaket Exclusive:
          </Typography>
          Mamapurse is more than just a digital wallet; it&#39;s one of
          Mamaket&#39;s most valuable innovations. With Mamapurse, we&#39;re
          redefining the way you shop and experience our platform. This
          exclusive digital wallet system is a testament to our commitment to
          providing the best possible experience for our community.
          <br />
          <br />
          Join us on this exciting journey of innovation and responsible
          shopping with Mamapurse. It&#39;s not just a wallet; it&#39;s your key
          to a smarter, more secure, and more rewarding shopping experience on
          Mamaket.
          <br />
          <br />
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Mamapurse;
