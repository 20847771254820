import React, { useState } from "react";
import {
  Box,
  Modal,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_MY_BANKS } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import bankImg from "../../assets/Icons/bank.svg";
import AddAccount from "./AddAccount";
import successIcon from "../../assets/Icons/successIcon.svg";
import { WITHDRAWAL_REQUEST } from "../../Utils/Mutations";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {md:400, sm:400, xs:350},
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxSizing: "border-box",
  boxShadow: 24,
  p: 4,
};

const GetPaid = ({ storeBalance }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActiveStep(1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [activeStep, setActiveStep] = useState(1);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const { loading, data, error } = useQuery(GET_MY_BANKS);

  const allBanks = data?.allMyBanks || [];

  const [amount, setAmount] = useState(null);

  const [
    withdrawRequest,
    { loading: withdraw_loading, error: widthdraw_error },
  ] = useMutation(WITHDRAWAL_REQUEST);

  const handleWithdrawal = async () => {
    withdrawRequest({
      variables: {
        bankId: selectedAccount?._id,
        amount: parseFloat(amount),
      },
    })
      .then((res) => {
        setActiveStep(3);
      })
      .catch((err) => {
        handleAlert(`${error?.message}`, "error");
      });
  };

  if (error) {
    handleAlert(`${error?.message}`, "error");
  }
  if (widthdraw_error) {
    handleAlert(`${widthdraw_error?.message}`, "error");
  }

  return (
    <>
      {loading || (withdraw_loading && <Loader />)}
      <Button
        onClick={handleOpen}
        sx={{
          bgcolor: "#D7B9DF",
          width: "150px",
          borderRadius: "50px",
          height: "44px",
          color: "#151515",
        }}
      >
        Get Paid
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ...(activeStep === 3 && {
                display: "none",
              }),
            }}
          >
            <Box>
              <Typography variant="subtitle1">
                {activeStep === 2 ? "Summary" : "Get Paid"}
              </Typography>
              <Typography>
                Balance: <b> ${parseFloat(storeBalance).toLocaleString()}</b>
              </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <IoClose />
            </IconButton>
          </Box>

          {activeStep === 3 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={successIcon} width={50} />
                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                  Withdrawal Successful
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Money will be available immediately or within 30 minutes.
                </Typography>
                <Button
                  onClick={() => handleClose()}
                  variant="contained"
                  sx={{ borderRadius: "50px", mt: 3, py: 1.5, px: 3 }}
                >
                  Continue
                </Button>
              </Box>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Box sx={{ mt: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", color: "#151515" }}>
                    Payment bank ending with
                  </Typography>
                  <Typography variant="body2">
                    ****{selectedAccount?.accountNumber?.slice(-4)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ fontSize: "12px", color: "#151515" }}>
                    Amount
                  </Typography>
                  <Typography variant="body2">
                    ${parseFloat(amount).toLocaleString()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography variant="subtitle1">Total</Typography>
                  <Typography variant="body2">
                    ${parseFloat(amount).toLocaleString()}
                  </Typography>
                </Box>

                <Box
                  sx={{ bgcolor: "#E9FCF9", mt: 3, p: 1, borderRadius: "5px" }}
                >
                  <Typography
                    sx={{
                      color: "#148B77",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    Deposit will reflect on you card immediately or within 30
                    minutes.
                  </Typography>
                </Box>

                <Button
                  onClick={() => handleWithdrawal()}
                  variant="contained"
                  fullWidth
                  disabled={!amount || !selectedAccount}
                  sx={{ borderRadius: "50px", mt: 2, py: 1.5 }}
                >
                  Cash Out
                </Button>
              </Box>
            </>
          )}

          {activeStep === 1 && (
            <Box sx={{ mt: 3 }}>
              <TextField
                placeholder="Amount"
                fullWidth
                type={"number"}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Settlement Account</Typography>
                {!allBanks || allBanks?.length === 0 ? (
                  <>
                    <AddAccount />
                  </>
                ) : (
                  <>
                    {allBanks?.map((account, index) => (
                      <Box
                        key={index}
                        sx={{
                          mt: 1,
                          borderRadius: "8px",
                          border: "1px solid #f2f2f2",
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          cursor: "pointer",
                          transition: "0.2s all linear",
                          ...(selectedAccount === account && {
                            borderColor: "#151515",
                            bgcolor: "#f2f2f2",
                          }),
                        }}
                        onClick={() => setSelectedAccount(account)}
                      >
                        <img src={bankImg} width={30} />
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontSize: "12px" }}
                          >
                            {account?.bankName}
                          </Typography>
                          <Typography>
                            ****{account?.accountNumber.slice(-4)}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </>
                )}

                <Box
                  sx={{ bgcolor: "#E9FCF9", mt: 2, p: 1, borderRadius: "5px" }}
                >
                  <Typography
                    sx={{
                      color: "#148B77",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    Service fee will be charge for cashout after last funding is
                    more than 7 days.
                  </Typography>
                </Box>
                <Button
                  onClick={() => setActiveStep(2)}
                  variant="contained"
                  fullWidth
                  disabled={!amount || !selectedAccount}
                  sx={{ borderRadius: "50px", mt: 2, py: 1.5 }}
                >
                  Proceed
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default GetPaid;
