import { useState } from "react";
import { cartItemsVar } from "../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { ADD_CARTS } from "../Utils/Mutations";
import { useMutation } from "@apollo/client";

const useIncreaseCarts = ( ) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const cartItems = useReactiveVar(cartItemsVar);

  const [addCart, { data }] = useMutation(ADD_CARTS);

//   let isInCart = cartItems?.some((item) => item?.product?._id === product_id);
  const handleIncreaseCart = async (product_id, productQuantity) => {
    const payload = { product: product_id, quantity: productQuantity };

    setIsLoading(true);
    await addCart({
      variables: payload,
    })
      .then((res) => {

        setIsLoading(false);
        setSuccess(true);
           const updatedCartItem = res?.data?.addToCart;
                  cartItemsVar(
       cartItems.map((item) =>
          item._id === updatedCartItem._id ? updatedCartItem : item
    
      ))
                  }).catch((err) => {
        console.error("Error Newe Cart", err);
        setIsLoading(false);
      });
  };

  return { handleIncreaseCart, isLoading, isSuccess };
};

export default useIncreaseCarts;
