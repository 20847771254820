import React from "react";
import FooterNavBar from "../Components/Common/FooterNavBar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Typography } from "@mui/material";
import Navbar from "../Components/Common/Navbar";

const TermsConditions = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },
          mb: 3,
        }}
      >
        {/* <Typography sx={{ fontSize: "24px", fontWeight: 700, color: "#333" }}>
          Terms & Conditions
        </Typography>
        <Typography sx={{ fontWeight: 600, mt: 2, fontSize: "12px" }}>
          Last updated April 27, 2024
        </Typography>

        <Box sx={{mt:4}}>
<Typography sx={{fontWeight:600,  color:'#000'}}>AGREEMENT TO OUR LEGAL TERMS</Typography>

<Typography sx={{fontSize:'13px', color:'#000'}}>We are Mamaket, Inc, doing business as Mamaket ("Company," "we," "us," "our"), a company registered in Florida, United States at 848 Brickell Avenue, PH 5, Miami, FL 33131.

  <br/>We operate the website <a href="http://www.mamaket.com">http://www.mamaket.com</a> (the "Site"), the mobile application Mamaket (the "App"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").
</Typography>


        </Box>
        <Typography
          sx={{
            fontSize: { lg: "14px", md: "14px", xs: "12px" },
            fontWeight: 400,
            color: "#333",
            mt: 2,
            textAlign: "justify",
          }}
        >
          <br />
          <br />
        </Typography> */}

        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="title"
            style={{ lineHeight: "1.5" }}
          >
            <strong>TERMS AND CONDITIONS</strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ lineHeight: "1.5" }}
          >
            <strong>Last updated</strong> <strong>April 27, 2024</strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <strong>AGREEMENT TO OUR LEGAL TERMS</strong>
          </div>
          <div align="center" style={{ textAlign: "left" }}>
            <div
              className="MsoNormal"
              id="agreement"
              style={{ lineHeight: "1.5" }}
            >
              <a name="_6aa3gkhykvst"></a>
            </div>
          </div>
          <div align="center" style={{ lineHeight: "1" }}>
            <br />
          </div>
        </div>

        <Typography sx={{ color: "#000", fontWeight: 400, lineHeight:'22px' }}>
          <div align="center" style={{ textAlign: "left" }}>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              We are{" "}
              <span className="block-container question question-in-editor">
                Mamaket, Inc
              </span>
              , doing business as <span className="question">Mamaket</span> ("
              <strong>Company</strong>," "<strong>we</strong>," "
              <strong>us</strong>," "<strong>our</strong>")<span>.</span>
              <span className="question">, a company </span>registered in{" "}
              <span className="question">Florida</span>,{" "}
              <span className="question">United States</span> at{" "}
              <span className="question">848 Brickell Avenue</span>,{" "}
              <span className="question">PH 5</span>,{" "}
              <span className="question">Miami</span>,{" "}
              <span className="question">FL</span>{" "}
              <span className="question">33131</span>.
            </div>
          </div>
          <div align="center" style={{ lineHeight: "1" }}>
            <br />
          </div>
          <div align="center" style={{ textAlign: "left" }}>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              We operate the website{" "}
              <a
                href="http://www.mamaket.com"
                target="_blank"
                rel="noreferrer"
                className="question"
              >
                http://www.mamaket.com
              </a>{" "}
              (the "<strong>Site</strong>"), the mobile application{" "}
              <span className="question">Mamaket</span> (the "
              <strong>App</strong>"), as well as any other related products and
              services that refer or link to these legal terms (the "
              <strong>Legal Terms</strong>") (collectively, the "
              <strong>Services</strong>").
            </div>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            We provide visibility for products and services of international
            stores and small businesses of immigrant cultures in the United
            States.
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ }}
          >
            You can contact us by email at{" "}
            <span className="question">care@mamaket.com</span> or by mail to{" "}
            <span className="question">66 W Flagler Street</span>,{" "}
            <span className="question">Suite 900</span>,{" "}
            <span className="question">Miami</span>,{" "}
            <span className="question">FL</span>{" "}
            <span className="question">33130</span>,{" "}
            <span className="question">United States</span>.
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("
            <strong>you</strong>"), and{" "}
            <span className="question">Mamaket, Inc</span>, doing business as{" "}
            <span className="question">Mamaket</span> ("<strong>Company</strong>
            ," "<strong>we</strong>," "<strong>us</strong>," "
            <strong>our</strong>"), concerning your access to and use of the{" "}
            <span className="question">http://www.mamaket.com</span> website as
            well as any other media form, media channel, mobile website, or
            mobile application related, linked, or otherwise connected thereto
            (collectively, the "<strong>Site</strong>"). You agree that by
            accessing the Site, you have read, understood, and agree to be bound
            by all of these Legal Terms.IF YOU DO NOT AGREE WITH ALL OF THESE
            LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
            SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </div>
          <br />
          We will provide you with prior notice of any scheduled changes to the
          Services you are using. The modified Legal Terms will become effective
          upon posting or notifying you by care@mamaket.com, as stated in the
          email message. By continuing to use the Services after the effective
          date of any changes, you agree to be bound by the modified terms.
          <br />
          <br />
          The Services are intended for users who are at least 18 years old.
          Persons under the age of 18 are not permitted to use or register for
          the Services.
          <br />
          <br />
          We recommend that you print a copy of these Legal Terms for your
          records.
          <br />
          <br />
          <b>TABLE OF CONTENTS</b>
          <br />
          <ol>
            <li>
              <a
                href="#1"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                OUR SERVICES
              </a>
            </li>
            <li>
              <a
                href="#2"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                INTELLECTUAL PROPERTY RIGHTS
              </a>
            </li>
            <li>
              <a
                href="#3"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                USER REPRESENTATIONS
              </a>
            </li>
            <li>
              <a
                href="#4"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                USER REGISTRATION
              </a>
            </li>
            <li>
              <a
                href="#5"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                PRODUCTS
              </a>
            </li>
            <li>
              <a
                href="#6"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                PURCHASES AND PAYMENT
              </a>
            </li>
            <li>
              <a
                href="#7"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                SUBSCRIPTIONS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                RETURN/REFUNDS POLICY
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                {" "}
                SOFTWARE
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                PROHIBITED ACTIVITIES
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                USER GENERATED CONTRIBUTIONS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                CONTRIBUTION LICENSE
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                GUIDELINES FOR REVIEWS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                MOBILE APPLICATION LICENSE
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
     SOCIAL MEDIA
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
              THIRD-PARTY WEBSITES AND CONTENT
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
              ADVERTISERS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
            SERVICES MANAGEMENT
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
             PRIVACY POLICY
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
            COPYRIGHT INFRINGEMENTS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
 TERM AND TERMINATION
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
    MODIFICATIONS AND INTERRUPTIONS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
 GOVERNING LAW
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
DISPUTE RESOLUTION
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
   CORRECTIONS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
DISCLAIMER
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
LIMITATIONS OF LIABILITY
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
INDEMNIFICATION
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
   USER DATA
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
SMS TEXT MESSAGING
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
CALIFORNIA USERS AND RESIDENTS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
MISCELLANEOUS
              </a>
            </li>
            <li>
              <a
                href="#8"
                style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
CONTACT US
              </a>
            </li>
          </ol>

          <br/><br/>
          <ol>
            <li id="1">
              <b>OUR SERVICES</b>
              <br/>
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            </li>
            <br/>
            <li id="2">
              <b> INTELLECTUAL PROPERTY RIGHTS</b>
              <br/>
              <b>Our intellectual property</b><br/><br/>
              We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").<br/><br/>Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.<br/><br/>The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or internal business purpose only.
              <br/><br/><b>Your use of our Services</b><br/><br/>
              Subject to your compliance with these Legal Terms, including the <a href="#10"    style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }} >"PROHIBITED ACTIVITIES"</a> section below, we grant you a non-exclusive, non-transferable, revocable license to:<br/><br/>

                <ul>
                  <li>access the Services; and</li>
                  <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
                </ul><br/>
   
                solely for your personal, non-commercial use or internal business purpose.
             <br/><br/>
                Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.<br/><br/>
                If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: care@mamaket.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                <br/><br/>
                We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                <br/><br/>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.

                <br/><br/>
                <b>Your submissions and contributions</b>
              <br/>
              Please review this section and the <a href="#10"    style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }} >"PROHIBITED ACTIVITIES"</a>section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
                <br/><br/>
                <b>Submissions:</b> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                <br/><br/>
                <b>Contributions: </b> The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material ("Contributions"). Any Submission that is publicly posted shall also be treated as a Contribution.
                <br/><br/>
                You understand that Contributions may be viewable by other users of the Services and possibly through third-party websites.
                <br/><br/>
                <b>When you post Contributions, you grant us a license (including use of your name, trademarks, and logos):</b>Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may occur in any media formats and through any media channels.
                <br/><br/>
                This license includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
                <br/><br/>
                <b>You are responsible for what you post or upload:</b> By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services by linking your account through the Services to any of your social networking accounts, you:
                <br/><br/>
                <ul>
                  <li>confirm that you have read and agree with our <a href="#10"    style={{
                  color: "#000",
                  textDecoration: "underline",
                  fontWeight: 500,
                }} >"PROHIBITED ACTIVITIES"</a>  and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
                <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;</li>
                <li>warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and</li>
                <li>warrant and represent that your Submissions and/or Contributions do not constitute confidential information.</li>
                </ul>
                <br/><br/>
                You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
                <br/><br/>
                <b>We may remove or edit your Content:</b> Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.

                <br/><br/>
                <b>Copyright infringement</b><br/><br/>
                We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the "COPYRIGHT INFRINGEMENTS" section below.
            </li><br/><br/>
            <li id="3">
              <b>USER REPRESENTATIONS</b>
              <br/><br/>
              By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.<br/><br/>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
            </li>
            <br/><br/>
            <li id="4">
              <b>USER REGISTRATION</b>
              <br/><br/>
              You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
            </li>
            <br/><br/>
            <li id="5">
              <b>PRODUCTS</b>
              <br/><br/>
          We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the Services. However, we do not guarantee that the colors, features, specifications, and details of the products will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products. All products are subject to availability, and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change.
            </li>
          </ol>
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default TermsConditions;
