import { useState } from "react";
import { cartItemsVar } from "../Utils/cache";
import { useReactiveVar } from "@apollo/client";
import { ADD_CARTS } from "../Utils/Mutations";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

const useAddToCarts = ( ) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();



   const handleAlert = (variant, message) => {
     // variant could be success, error, warning, info, or default
     enqueueSnackbar(message, { variant });
   };

  const cartItems = useReactiveVar(cartItemsVar);

  const [addCart, { data }] = useMutation(ADD_CARTS, );

  const handleAddToCart = async ( product_id, productQuantity) => {
    
    let isInCart = cartItems?.some((item) => item?.product?._id === product_id);

    const payload = { product: product_id, quantity: productQuantity };

    if(!isInCart){
                            setIsLoading(true);
   await addCart({
     variables: payload,
   })
     .then((res) => {

       setIsLoading(false);
       setSuccess(true);
       cartItemsVar([...cartItems, res?.data?.addToCart]);

     })
     .catch((err) => {
       console.error("Error Newe Cart", err);
       setIsLoading(false);
     });
    }else{
                        handleAlert("warning", `Item is already in your cart.`);
                 
    }
 
  };

  return { handleAddToCart, isLoading, isSuccess };
};

export default useAddToCarts;
