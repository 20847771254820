import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';

const SellProtectedRoute = ({ children }) => {
      const isAuthenticated = useSelector(
        (state) => state.user?.isAuthenticated
      );
      const userIsSeller = useSelector(
        (state) => state.user?.user?.profile?.hasPaidMembershipFee
      );
    

  if (userIsSeller === false &&  isAuthenticated) {
    return <Navigate to="/download" />;
  }else if( !isAuthenticated){
                 return <Navigate to="/login" />;           
  }
  return children;
};

export default SellProtectedRoute;