import React, { act, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImImages } from "react-icons/im";
import { HiViewfinderCircle } from "react-icons/hi2";
import { RiDeleteBin2Line } from "react-icons/ri";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import ProductsCard from "../../Components/Cards/ProductsCard";
import { CloseOutlined } from "@mui/icons-material";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  // bgcolor: 'background.paper',
boxSizing:'border-box',
  // boxShadow: 24,
  p: 0,

  borderRadius:'5px'
};

const UploadComponent = ({ updateImages, initialImages }) => {

    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [images, setImages] = useState(initialImages || []);

    const generateId = () => {
    return Date.now() + Math.random().toString(36).substr(2, 9);
  };

const onDrop = (acceptedFiles) => {
  setImages((prevImages) => {
    // If the number of previously uploaded images + new files exceeds 4, limit the upload
    const totalImages = prevImages.length + acceptedFiles.length;
    if (totalImages > 1) {
      const remainingSlots = 1 - prevImages.length;
      const limitedFiles = acceptedFiles.slice(0, remainingSlots);

      const imageFiles = limitedFiles.map((file) =>
        Object.assign(file, {
          id: generateId(), // Assign a unique ID to each file
          preview: URL.createObjectURL(file), // Generate a preview URL for the image
        })
      );
      return [...prevImages, ...imageFiles];
    }

    // If the total is less than or equal to 4, allow all the files
    const imageFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        id: generateId(), // Assign a unique ID to each file
        preview: URL.createObjectURL(file), // Generate a preview URL for the image
      })
    );
    return [...prevImages, ...imageFiles];
  });
};

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: {
    'image/png': [],
    'image/jpeg': [], // Accept .jpg and .jpeg
    'image/jpg': [],
    'image/svg+xml': [], // Accept .svg files
  },
});

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    updateImages(images);
  }, [images]);


  const deleteImage = (idToDelete) => {
    setImages((prevImages) => prevImages.filter((image) => image.id !== idToDelete));
  };



  return (
    <>
      {!images || images.length === 0 ? (
        <Box
          sx={{
            bgcolor: "#fff",
            height: "120px",
            border: "1px dashed #EBDCEF",
            borderRadius: "10px",
            mt: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography
              sx={{ color: "#151515", fontSize: "12px", fontWeight: 500 }}
            >
              Drop the images here...
            </Typography>
          ) : (
            <>
              <Typography sx={{ color: "#151515" }}>
                Drop your files here or{" "}
                <b style={{ color: "#7B408C" }}>click to upload</b>
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Maximum size: 5 MB
              </Typography>
            </>
          )}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              bgcolor: "#fff",
              height: "150px",

              borderRadius: "10px",
              mt: 1,
              display: "flex",
              alignItems: "center",
              overflowX: "scroll",
              overflowY: "hidden",

              boxSizing: "border-box",
              columnGap: 2,

              "&::-webkit-scrollbar": {
                borderRadius: "50px",
                height: "5px",
                // Set width of the scrollbar
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#EBDCEF", // Style the thumb (scrollbar handle)
                borderRadius: "50px",
                width: "5px",
                // Round the corners of the thumb
              },
            }}
          >
            <Box
              sx={{
                border: "0.5px dashed #C296CF",
                boxSizing: "border-box",
                width: "110px",
                p: 1,
                borderRadius: "8px",
                height: "130px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                flexShrink: 0,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <ImImages style={{ color: "#AE73BF" }} />
              <Typography sx={{ fontSize: "8px", mt: 0.5 }}>
                Add More Images..
              </Typography>
            </Box>
            {images?.map((image, index) => (
              <Box
                key={index}
                onClick={() => setSelectedImage(image)}
                sx={{
                  border: "1px solid #EBEAED",
                  boxSizing: "border-box",
                  width: "110px",
                  p: 1,
                  borderRadius: "8px",
                  height: "130px",
                  display: "grid",
                  placeContent: "center",
                  transition: "0.1s all linear",
                  cursor:'pointer',
                  ...(selectedImage === image && {
                    border: "1.5px solid #7B408C",
                  }),
                }}
              >
                <Box
                  sx={{
                    background: `url('${image?.preview}')`,
                    width: "100px",
                    height: "120px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              border: "1px solid #EBEAED",
              mt: 2,
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              //               p:1,
              borderRadius: "5px",
              boxSizing: "border-box",
            }}
          >
            <IconButton disabled={selectedImage === null} onClick={()=>{handleOpen()}}>
              <HiViewfinderCircle style={{ fontSize: "16px" }} />
            </IconButton>{" "}
            |
            <IconButton disabled={selectedImage === null} onClick={()=>deleteImage(selectedImage?.id)}>
              <RiDeleteBin2Line
                style={{ fontSize: "16px", color: "#FF3347" }}
              />
            </IconButton>
          </Box>

           <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Box sx={{display:'flex', alignItems:'center', columnGap:2}}>
          <img src={selectedImage?.preview}  width={"60%"}/>
            <Box sx={{width:'40%', flex:1}}>
              <Box align="center">
                <IconButton onClick={handleClose} sx={{bgcolor:'#fff', mb:2, width:'70px', height:'70px'}}>

                <CloseOutlined/>
              </IconButton>
              </Box>
           <ProductsCard image={selectedImage?.preview} price={"00.00"}/>
            </Box>
         </Box>
        </Box>
      </Modal>
        </>
      )}
    </>
  );
};

export default UploadComponent;
