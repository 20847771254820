import {
  Box,
  IconButton,
  Typography,
  Chip,
  Avatar,
  Grid,
  Modal,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import timeIcon from "../../../assets/Icons/time.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SINGLE_ORDER } from "../../../Utils/Queries";
import Loader from "../../../Components/Common/Loader";
import { CANCEL_ORDER } from "../../../Utils/Mutations";
import declinedImg from "../../../assets/Icons/declined 2.svg";
import Lottie from 'react-lottie';

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ReleaseFunds from "./ReleaseFunds";
import { useSnackbar } from "notistack";

import successAnime from '../../../assets/anime/HxXzwE6KaZ.json'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  boxSizing: "border-box",
  p: 4,
};

const themeStepper = createTheme({
  palette: {
    primary: {
      main: "#349852",
    },
  },
});

const OrderDetails = () => {
  const { id } = useParams();
  const [success, setSuccess] = useState(false);
  const [rejectReasons, setRejectReasons] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };

    const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const handleClose = () => setOpen(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setRejectReasons(event.target.value);
  };

  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "iiii, do 'of' MMMM yyyy, h:mm a");
    return formattedDate;
  };

  function formatDate3(dateString) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(/\//g, " - ");
    const formattedTime = date.toLocaleTimeString("en-US", options);
    return `${formattedTime}`;
  }

  const [getSingleOrder, { loading, data }] = useLazyQuery(GET_SINGLE_ORDER);
  useEffect(() => {
    getSingleOrder({
      variables: {
        reference: id,
      },
    }).then((res)=>{

    }).catch((err)=>{
          handleAlert(`${err.message}`, "error")
    });
  }, [id]);



  const { amountPaid, createdAt, quantity, product, statusHistory, _id } =
    data?.getMyOrders?.items[0] || [];

  useEffect(() => {
    if (statusHistory) {
      const lastIndex = statusHistory.length - 1;

      if (statusHistory.length === 1) {
        setActiveStep(0);
      } else if (statusHistory[lastIndex].status === "Processing") {
        setActiveStep(1);
      } else if (statusHistory[lastIndex].status === "Shipped") {
        setActiveStep(2);
      } else if (statusHistory[lastIndex].status === "Delivered") {
        setActiveStep(3);
      } else if (statusHistory[lastIndex].status === "Review & Release Fund") {
        setActiveStep(4);
      } else if (statusHistory[lastIndex].status === "Canceled") {
        setIsCancelled(true);
        setActiveStep(lastIndex - 1);
      }

    }
  }, [statusHistory]);

  const [cancelOrder, { loading: cancelLoad, data: cancelData }] = useMutation(
    CANCEL_ORDER
  );

  const handleCancelOrder = () => {
    setSuccess(true);
    setIsLoading(true);
    cancelOrder({
      variables: {
        itemId: _id,
        reason: rejectReasons,
        description,
      },
    })
      .then((res) => {

        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {

        setIsLoading(false);
              handleAlert(`${err.message}`, "error")

      });
  };

  const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnime,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
  return (
    <>
      {cancelLoad && <Loader />}
      {isLoading && <Loader />}

      {loading && <Loader />}
      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/account/orders">
            <ArrowBackIosIcon
              sx={{ color: "#1a1a1a", fontSize: "15px", mb: -0.2 }}
            />
          </Link>
          <Typography sx={{ fontSize: "12px", ml: 2 }}>
            Order ID:
            <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
              {" " + id}
            </span>
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "12px", color: "#666" }}>
          Purchase date:{" "}
          <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
            {createdAt && formatDate(createdAt)}
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
            width: "70%",
          }}
        >
          <Avatar
            variant="square"
            src={product && product.images[0]}
            sx={{ width: "80px", height: "60px" }}
          />
          <Box sx={{width:'58%', }}>
            <Typography
              sx={{
                color: "#1a1a1a",
                fontSize: "14px",
                fontWeight: 600,
                overflow:'hidden',
                textOverflow:'ellipsis',
                whiteSpace:'nowrap',
                width:'100%'
              }}
            >
              {product && product.name}
            </Typography>
            <Box sx={{ display: "flex", columnGap:3}}>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                QTY: {data && quantity}
              </Typography>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 700,
                  ml: 5,
                }}
              >
                ${product?.price && product?.price}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          {activeStep === 0 && !isCancelled && (
            <Chip
              label="paid"
              sx={{ px: 2,  fontSize: "12px" }}
            />
          )}
          {activeStep === 1 && !isCancelled && (
            <Chip
              label="processing"
              sx={{ px: 2,  fontSize: "12px" }}
            />
          )}
          {activeStep === 2 && !isCancelled && (
            <Chip
              label="Ready to pickup"
              sx={{
                color: "#FFC533",
                bgcolor: "#FFC5331a",
                px: 2,
                
                fontSize: "12px",
              }}
            />
          )}
          {activeStep === 3 && !isCancelled && (
            <Chip
              label="Picked up"
              sx={{
                color: "#5C3069",
                bgcolor: "#5C30691a",
                px: 2,
        
                fontSize: "12px",
              }}
            />
          )}
          {activeStep === 4 && (
            <Chip
              label="Completed"
              sx={{
                color: "#fff",
                bgcolor: "#3FB763",
                px: 2,
        
                fontSize: "10px",
              }}
            />
          )}
          {isCancelled && (
            <Chip
              label="Cancelled"
              sx={{
                color: "#FF4C5E",
                bgcolor: "#FFE5E8",
                px: 2,
        
                fontSize: "12px",
              }}
            />
          )}
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 0 }}>
        <ThemeProvider theme={themeStepper}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Typography>Status</Typography>
              <Box sx={{ maxWidth: 400 }}>
                <Timeline sx={{ ml: -20 }}>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 0 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 0
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" }}
                    >
                      Paid
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          formatDate3(statusHistory[0]?.updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem activeStep>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 1 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 1
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" }}
                    >
                      Processing
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          statusHistory[1] &&
                          formatDate3(statusHistory[1]?.updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 2 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 2
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 2 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 2
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" }}
                    >
                      Ready for Pickup
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          statusHistory[2] &&
                          formatDate3(statusHistory[2]?.updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 3 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 3
                              ? "#2E1834"
                              : null,
                        }}
                      />
                      <TimelineConnector
                        sx={{
                          bgcolor:
                            activeStep >= 3 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 3
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" }}
                    >
                      Picked up
                      <Typography sx={{ fontSize: "9px" }}>
                        {statusHistory &&
                          statusHistory[3] &&
                          formatDate3(statusHistory[3]?.updatedAt)}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "4px",
                          height: "4px",
                          p: 0,
                          bgcolor:
                            activeStep >= 4 && isCancelled
                              ? "#FF4C5E"
                              : activeStep >= 4
                              ? "#2E1834"
                              : null,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{  fontSize: "12px" }}
                    >
                      Review & Release Fund
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Box>
          </Grid>
        </ThemeProvider>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          {activeStep === 3 && (
            <>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: { md: "row", xs: "column" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    width: { md: "59%", xs: "100%" },
                    textAlign: "center",
                  }}
                >
                  Are you satisfied with the product delivered?
                </Typography>
                <Box sx={{ width: { md: "38%", xs: "100%" } }}>
                  {/* <Button
                    fullWidth
                    sx={{
                      border: "1px solid #E6E6E6",
                      color: "#333",
                      borderRadius: "100px",
                      fontSize: "12px",
                    }}
                  >
                    No, Dispute
                  </Button> */}
                  <ReleaseFunds id={_id} />
                </Box>
              </Box>
            </>
          )}
    {
      activeStep > 2 ? (
        <>
    <Lottie options={defaultOptions}  width={150} height={150}/>
    <Box>
{
  activeStep === 3 && (
          <Typography sx={{textAlign:'center', fontWeight:700, color:'#2E1833'}}>Picked up successfully</Typography>
  )
}
{
  activeStep === 4 && (
          <Typography sx={{textAlign:'center', fontWeight:700, color:'#2E1833'}}>Order Completed</Typography>
  )
}
    </Box>
        </>
      ):(
        <>
              <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
            Delivery
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{fontWeight:700, fontSize:'12px'}}> Delivery Type:</Typography>
              <Typography sx={{ fontWeight: 500, color: "#333", fontSize:'12px' }}>
           Pick up
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt:2 }}>
              <Typography sx={{ width: "fit-content", fontWeight:700,  fontSize:'12px' }}>
               Pickup Address:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#333",
                  fontSize: "12px",
                  textAlign: "right",
                }}
              >
                {
                  activeStep === 2 ? product?.merchant?.addresses[0]?.addressOne : " Pickup address will be available once order is ready for pickup"
                }
 
              </Typography>
            </Box>
          </Box>
        </>
      )
    }
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
              Payment
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Amount:</Typography>
              <Typography sx={{ fontWeight: 400, color: "#333" }}>
                ${amountPaid && amountPaid.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ width: "fit-content" }}>
                Payment Method:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: "#333",
                  fontSize: "14px",
                  textAlign: "right",
                }}
              >
                Mamapurse
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#fff",
              p: 2,
              mt: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            {activeStep === 0 ? (
              <Button
                onClick={handleOpen}
                color="error"
                sx={{ textDecoration: "underline", fontWeight: 600 }}
              >
                CANCEL ORDER
              </Button>
            ) : (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={timeIcon} />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#333",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    Need help?
                    <Link to="/contact" style={{ color: "#2E1834", fontWeight: 500 }}>
                      {" "}
                      Contact Support{" "}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box align="center" sx={{ p: 3 }}>
                <img src={declinedImg} />
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                  Order Cancelled
                </Typography>
                <Typography
                  sx={{
                    color: "#333",
                    mt: 2,
                    fontWeight: 500,
                    lineHeight: "126%",
                  }}
                >
                  A refund will be issued and credited to your MamaPurse wallet.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ mt: 3, borderRadius: "100px", px: 5 }}
                >
                  Done
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: 700 }}>
                  Cancel Order
                </Typography>
                <IconButton onClick={handleClose}>
                  {" "}
                  <ClearIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  mt: 2,
                  color: "#333",
                  lineHeight: "145%",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                By clicking "cancel order," you confirm the cancellation of your
                purchase. A refund will be issued and credited to your MamaPurse
                wallet.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Select
                  displayEmpty
                  fullWidth
                  sx={{
                    borderRadius: "10px",
                    background: "#fff",
                    offset: " 0px solid #737373",
                    border: "0px solid red",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#333",
                  }}
                  InputProps={{
                    style: {
              
                      fontSize: "12px",

                      // Replace with your desired font family
                    },
                  }}
                  value={rejectReasons}
                  onChange={handleChange}
                >
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value=""
                    disabled
                  >
                    Reason for cancellation
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Better Alternative "
                  >
                    Better Alternative{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="High Cost "
                  >
                    High Cost{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Dissatisfaction with quality"
                  >
                    {" "}
                    Dissatisfaction with quality{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 500, fontSize: "12px", color: "#333" }}
                    value="Others "
                  >
                    {" "}
                    Others{" "}
                  </MenuItem>
                </Select>

                <TextField
                  fullWidth
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={6}
                  margin="normal"
                  placeholder="More description"
                  InputProps={{
                    style: {
              

                      borderRadius: "10px",
                      background: "#fff",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#333",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 3,
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    border: "1px solid #E6E6E6",
                    color: "#333",
                    borderRadius: "100px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  sx={{ borderRadius: "100px" }}
                  fullWidth
                  color="error"
                  onClick={handleCancelOrder}
                  variant="contained"
                  disabled={rejectReasons === "" || !description}
                >
                  Yes, Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default OrderDetails;
