import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Skeleton,
  Typography,
  Modal,
  TextField,
} from "@mui/material";
import walletIcon from "../../assets/Icons/Wallet.svg";
import AddCash from "../../Account-Profile/Components/Mamapurse/AddCash";
import { useSelector } from "react-redux";
import { GET_ALL_PUSRSES } from "../../Utils/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../../Utils/Mutations";
import Loader from "../Common/Loader";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 400, xs: 320 },
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: { md: 5, xs: 4 },
  boxSizing: "border-box",
};

const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;

const Payment = ({ routeConfirmed }) => {
  const [selected, setSelected] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [insufficient, setInsufficient] = useState(false);
  const [transactionPin, setTransactionPin] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (totalSubtotal > spendingBalance || spendingBalance === 0) {
      setInsufficient(true);
    } else {
      setOpen(true);
      setInsufficient(false);
    }
  };

  const handleChange = (newValue) => {
    setError(false);
    setTransactionPin(newValue);
  };
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleSelect = () => {
    setSelected(!selected);
    setSelected2(false);
  };
  const handleSelect2 = () => {
    setSelected2(!selected2);
    setSelected(false);
  };
  const [getWallets, { data: wallets, loading }] = useLazyQuery(
    GET_ALL_PUSRSES
  );

  useEffect(() => {
    getWallets();
  }, []);
    const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    if (transactionPin.length === 4) {
      handleCreateOrder();
    }
  }, [transactionPin]);

  const spendingBalance = wallets?.getWallets[0]?.spendingBalance;

  const { loading: cartLoad, data: cart } = useQuery(GET_CART_ITEMS);
  const cartProducts = cart?.cartItems;

  const totalSubtotal = cartProducts.reduce(
    (total, item) => total + item.pricePerUnit * item.quantity,
    0
  );
  useEffect(() => {
    if (spendingBalance) {
      if (totalSubtotal > spendingBalance || spendingBalance === 0) {
        setInsufficient(true);
      } else {
        setInsufficient(false);
      }
    }
  }, [wallets]);

  const [createOrder, { data }] = useMutation(CREATE_ORDER);

  const handleCreateOrder = () => {
    // setOpen(false)
    setError(null);

    setIsLoading(true);
    createOrder({
      variables: {
        deliveryOption: "PICKUP",
        transactionPin: transactionPin,
      },
    })
      .then((res) => {
        setIsLoading(false);
        routeConfirmed();
      })
      .catch((err) => {
   
        setIsLoading(false);
        setError("Invalid Transaction Pin");
        handleAlert(`${err.message}`, "error");
      });
  };

  return (
    <>
      <Box sx={{ mt: 3, pb: 3 }}>
        {/* <Typography sx={{ color: "#333", fontSize: "16px", fontWeight: 500 }}>
          Select shipping option
        </Typography> */}
        <Box sx={{ width: "100%", mt: 1 }}>
          {/* <Box
            onClick={handleSelect}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: selected ? "#EBDCEF" : "#f8f7f7",
              py: 1,
              px: 2,
              border: selected && "1px solid #2E1834",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  width: selected ? "8px" : "20px",
                  height: selected ? "8px" : "20px",
                  border: selected ? "6px solid #2E1834" : "1px solid #ccc",
                }}
              ></Box>
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Door Dash
              </Typography>
            </Box>
            <Typography>$10.50</Typography>
          </Box>
          <Box
            onClick={handleSelect2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: selected2 ? "#EBDCEF" : "#f8f7f7",
              py: 1,
              px: 2,
              mt: 2,
              border: selected2 && "1px solid #2E1834",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", columnGap: 2 }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  width: selected2 ? "8px" : "20px",
                  height: selected2 ? "8px" : "20px",
                  border: selected2 ? "6px solid #2E1834" : "1px solid #ccc",
                }}
              ></Box>
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Uber Eat
              </Typography>
            </Box>
            <Typography>$12.50</Typography>
          </Box> */}

          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ color: "#333", fontSize: "14px", fontWeight: 500 }}
            >
              Summary
            </Typography>
            <Box sx={{ bgcolor: "#f8f7f7", mt: 2, p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                {/* <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Items Price (X10)
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $160.00
                </Typography> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Shipping Fee
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $00.00
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Tax
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $0.00
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333333",
                  py: 0.5,
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "13px", fontWeight: 500 }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{ color: "#333", fontSize: "13px", fontWeight: 500 }}
                >
                  {totalSubtotal ? "$" + totalSubtotal.toLocaleString() : "--"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ color: "#333", fontSize: "14px", fontWeight: 500 }}
            >
              Pay with
            </Typography>
            <Box sx={{ bgcolor: "#f8f7f7", mt: 2, p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                >
                  <img src={walletIcon} />
                  <Box>
                    <Typography sx={{ color: "#333", fontWeight: 500 }}>
                      Mamapurse
                    </Typography>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography sx={{ color: "#333", fontWeight: 500 }}>
                        {`$${
                          spendingBalance
                            ? spendingBalance.toLocaleString()
                            : "0"
                        }`}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <AddCash
                  text="Add Cash"
                  color="#5C3069"
                  variant="standard"
                  bgcolor="none"
                />
                {/* <Button sx={{ color: "#5C3069" }}>Top-up account</Button> */}
              </Box>
            </Box>
          </Box>

          {insufficient && (
            <Box
              sx={{
                padding: "12px 16px",
                border: "1px dashed #FF6675",
                bgcolor: "#FFE5E8",
                mt: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{ color: "#FF3347", fontSize: "11px", lineHeight: "125%" }}
              >
                <span> Important Notice:</span> Your Mamapurse balance is
                insufficient to cover your check total. To add funds to your
                Mamapurse account, please click on "Add Cash" Thank you.
              </Typography>
            </Box>
          )}
        </Box>
        <Button
          onClick={handleOpen}
          variant="contained"
          // disabled={insufficient}
          sx={{
            color: "#fff",
            mt: 4,
            borderRadius: "100px",
            padding: "13px 24px",
          }}
          fullWidth
        >
          Check Out
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: 700 }}
          >
            Enter Transaction PIN
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ fontSize: "14px", fontWeight: 600 }}
          >
            Enter your 4 digit pin to authorize this transactions.
          </Typography>
          <Box sx={{ mt: 3 }}>
            {/* <TextField
              type="password"
              fullWidth
              error={error}
              value={transactionPin}
              helperText={error}
              onChange={(e) => setTransactionPin(e.target.value)}
              placeholder="Enter Pin"
              InputProps={{
                style: {
         
                  mt: 1.5,
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            /> */}
            <Box sx={{ display: "grid", placeItems: "center" }}>
              <MuiOtpInput
                style={{ borderRadius: "10px" }}
                error={error}
                value={transactionPin}
                // helperText={error}
                onChange={handleChange}
                length={4}
                TextFieldsProps={{
                  type: "password",
                  inputProps: {
                    style: {
                      border: error
                        ? `2px solid #EA8072`
                        : `2px solid  #2e1834`,
                      borderRadius: "20px",

                      // width:'30px',
                      height: "38px",
                      mt: 1.5,
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                    },
                  },

                  size: "small",
                  border: "1px solid #fff",
                }}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, py: 2, fontWeight: 700 }}
            onClick={handleCreateOrder}
            disabled={transactionPin.length < 4}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Payment;
