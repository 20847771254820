import React, { useEffect } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import sucessIcon from "../../../assets/Icons/Icon Box.svg";
import { Link } from "react-router-dom";

const style = {
  //   width: "100%",
  border: "none",
  bgcolor: "#ECF8F0",
  p: 1,
  px: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "2px solid #5C3069",
};

const CartAlert = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [open]);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
            <img src={sucessIcon} />
            <Typography
              sx={{
                color: "#1a1a1a",
                fontWeight: 400,
                fontSize: { lg: "13px", md: "13px", sm: "10px", xs: "10px" },
              }}
            >
              Item was successfully added to your cart.
            </Typography>
          </Box>
          <Link to="/cart">
            <Button
              sx={{
                textDecoration: "underline",
                fontSize: { lg: "13px", md: "13px", sm: "10px", xs: "10px" },
              }}
            >
              View cart
            </Button>
          </Link>
        </Box>
      </Modal>
    </>
  );
};

export default CartAlert;
