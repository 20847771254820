import React from 'react'
import Navbar from '../Components/Common/Navbar'
import ChatOutlet from './ChatOutlet'
import Newsletter from '../Components/Common/Newsletter'
import Footer from '../Components/Common/Footer'
import { Box, Button } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom'

const ChatMobile = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ mt: 7 }}>
        <Box sx={{ py: 2, px: 2 }}>
          <Link to="/chat">
            <Button startIcon={<ArrowBackIosIcon />}>Back</Button>
          </Link>
        </Box>
        <ChatOutlet />
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
}

export default ChatMobile