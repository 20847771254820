import {
  Box,
  Tabs,
  Tab,
  Typography,
  Chip,
  Avatar,
  MenuItem,
  createTheme,
  ThemeProvider,
  Grid,
  IconButton,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import emptyCart from "../../../assets/Icons/empty_cart.svg";
import { Link } from "react-router-dom";
import { GET_BUYER_ORDERS } from "../../../Utils/Queries";
import Loader from "../../../Components/Common/Loader";
import { LuPackageCheck } from "react-icons/lu";
import { TbPackageOff } from "react-icons/tb";
import TablePagination from "@mui/material/TablePagination";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1.5, bgcolor: "#F8F7F7" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ProductOrder = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [getAddress, { loading, data: dats }] = useLazyQuery(GET_BUYER_ORDERS);
  useEffect(() => {
    getAddress();
  }, []);


  return (
    <>
      {loading && <Loader />}
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: "#fff", p: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="All"
                sx={{
                  fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Paid"
                sx={{
                      fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(1)}
              />
              <Tab
                label="Ready for 
              Pickup"
                sx={{
                      fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(2)}
              />
              <Tab
                label="Picked up"
                sx={{
                      fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(3)}
              />
              <Tab
                label="Completed"
                sx={{
                      fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(4)}
              />
              <Tab
                label="Cancelled"
                sx={{
                      fontSize: {lg:"14px",md:'14px', sm:'12px', xs:'12px'},
                  fontWeight:500,
                  textTransform: "initial",
                }}
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <All data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Confirmed data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Shipped data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Delivered data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Completed data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Cancelled data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
};

export default ProductOrder;

const All = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  return (
    <>
      {(!data || data.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item) => (
            <Link
              to={
                item.status === "Canceled"
                  ? " "
                  : `/account/orders/order-details/product/${item.reference}`
              }
            >
              <MenuItem
                disabled={item.status === "Canceled"}
                key={item._id}
                sx={{ bgcolor: "#fff", p: 1, mt: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 2,
                      width:'60%'
                    }}
                  >
                    <Avatar
                      variant="square"
                      src={item?.product.images[0]}
                      sx={{ width: "80px", height: "70px" }}
                    />
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                        {item.reference}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "14px",
                          fontWeight: 600,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.product?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          QTY: {item?.quantity}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                            ml: 3,
                          }}
                        >
                          ${item?.amountPaid.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {item?.status === "Paid" && (
                      <Chip
                        label="Paid"
                        sx={{ fontSize: "10px",  }}
                      />
                    )}

                    {item?.status == "Canceled" && (
                      <TbPackageOff style={{ color: "#FF4C5E" }} />
                    )}
                    {item?.status == "Review & Release Fund" && (
                      <LuPackageCheck style={{ color: "#3FB763" }} />
                    )}
                    {item?.status == "Shipped" && (
                      <Chip
                        label="Ready for pickup"
                        sx={{
                          fontSize: "10px",
      
                          color: "#FFC533",
                          bgcolor: "#FFF8E5",
                        }}
                      />
                    )}
                    {item?.status == "Delivered" && (
                      <Chip
                        label="Picked up"
                        sx={{
                          color: "#5c3069",
                          bgcolor: "#efe9ef",
                          fontSize: "10px",
      
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </MenuItem>
            </Link>
          ))}

      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
        
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
          
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
          
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
            
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Confirmed = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const PaidData = data?.filter((item) => item?.status === "Paid");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, PaidData?.length);
  const rowsText = `${startRow}-${endRow} of ${PaidData?.length}`;
  return (
    <>
      {(!data || PaidData.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        PaidData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).map((item) => (
          <Link to={`/account/orders/order-details/product/${item.reference}`}>
            <MenuItem
              key={item.id}
              sx={{ bgcolor: "#fff", p: 1, mt: 1, overflow: "hidden" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                  
                    width: "70%",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box sx={{ width: "70%" }}>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.statusHistory[0].status === "Paid" && (
                    <Chip
                      label="Paid"
                      sx={{ fontSize: "12px" }}
                    />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
   
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
     
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
     
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
       
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === PaidData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Shipped = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const ShippedData = data?.filter((item) => item?.status === "Shipped");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, ShippedData?.length);
  const rowsText = `${startRow}-${endRow} of ${ShippedData?.length}`;
  return (
    <>
      {(!ShippedData || ShippedData.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        ShippedData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).map((item) => (
          <Link to={`/account/orders/order-details/product/${item.reference}`}>
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box sx={{ width: "50%" }}>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.statusHistory[0].status === "Paid" && (
                    <Chip
                      label="Ready for pickup"
                      sx={{
                        color: "#FFC533",
                        bgcolor: "#FFF8E5",
    
                        fontSize: "10px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
   
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
     
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
     
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
       
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === ShippedData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Delivered = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const DeliveredData = data?.filter((item) => item?.status === "Delivered");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, DeliveredData?.length);
  const rowsText = `${startRow}-${endRow} of ${DeliveredData?.length}`;
  return (
    <>
      {(!DeliveredData || data.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        DeliveredData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ).map((item) => (
          <Link to={`/account/orders/order-details/product/${item.reference}`}>
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box sx={{ width: "50%" }}>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Chip
                    label="Picked up"
                    sx={{
                      color: "#5c3069",
                      bgcolor: "#efe9ef",
                      fontSize: "12px",
                    }}
                  />
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}

      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
   
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
     
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
     
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
       
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === DeliveredData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Cancelled = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const canceledData = data?.filter((item) => item?.status === "Canceled");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, canceledData?.length);
  const rowsText = `${startRow}-${endRow} of ${canceledData?.length}`;
  return (
    <>
      {(!data || canceledData.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        canceledData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item) => (
            <Link to="">
              <MenuItem
                disabled
                key={item.id}
                sx={{ bgcolor: "#fff", p: 1, mt: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 2,
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      variant="square"
                      src={item?.product.images[0]}
                      sx={{ width: "80px", height: "70px" }}
                    />
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                        {item.reference}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "14px",
                          fontWeight: 600,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.product?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          QTY: {item?.quantity}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                            ml: 3,
                          }}
                        >
                          ${item?.amountPaid.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Chip
                      label="canceled"
                      sx={{ bgcolor: "#FFE5E8b7", color: "#FF4C5E" }}
                    />
                  </Box>
                </Box>
              </MenuItem>
            </Link>
          ))}
      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
   
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
     
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
     
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
       
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === canceledData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
const Completed = ({ data }) => {
  const [empty, setEmpty] = useState(null);
  const completedData = data?.filter(
    (item) => item?.status === "Review & Release Fund"
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, completedData?.length);
  const rowsText = `${startRow}-${endRow} of ${completedData?.length}`;
  return (
    <>
      {(!data || completedData.length === 0) && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        completedData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item) => (
            <Link
              to={`/account/orders/order-details/product/${item.reference}`}
            >
              <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 2,
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      variant="square"
                      src={item?.product.images[0]}
                      sx={{ width: "80px", height: "70px" }}
                    />
                    <Box sx={{ width: "50%" }}>
                      <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                        {item.reference}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "14px",
                          fontWeight: 600,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.product?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          QTY: {item?.quantity}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                            ml: 3,
                          }}
                        >
                          ${item?.amountPaid.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Chip
                      label="Completed"
                      sx={{
                        color: "#fff",
                        bgcolor: "#3FB763",
                        fontSize: "10px",
    
                      }}
                    />
                  </Box>
                </Box>
              </MenuItem>
            </Link>
          ))}
      <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
        
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
          
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
          
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
            
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === completedData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

// const data = [1, 2, 3, 4];
