import {
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Popover,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import emptyAddress from "../../assets/no-address.svg";
import AddAddress from "../Components/Address/AddAddress";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_ADDRESS } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";
import EditAddress from "../Components/Address/EditAddress";
import { useLocation, useNavigate } from "react-router-dom";

const Address = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

    const location = useLocation();

  const navigate = useNavigate();

  const locationState = location.state;

    const from = location.state?.from?.pathname || "/";

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [selectedAddress, setSelectedAddress] = useState(null)

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedAddress(item)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [getAddress, { loading, data, error , refetch}] = useLazyQuery(GET_ALL_ADDRESS);
  useEffect(() => {
    getAddress()
      .then((res) => {})
      .catch((err) => {
                   handleAlert(`${err.message}`, "error")
      });
  }, []);

  const handleReroute = () =>{
         navigate(from, { replace: true });
      if (locationState && locationState.redirectTo) {
          navigate(`${locationState?.redirectTo}`);
        } else {
          refetch();
        }
  }

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#333", fontWeight: 500, fontSize: "16px" }}>
            Address
          </Typography>
          <AddAddress action={handleReroute} />
        </Box>
        <Divider sx={{ my: 2 }} />
        {data?.fetchAllAddresses.map((item, index) => (
          <Box
            key={index}
            sx={{
              my: 2,
              px: 2,
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 60px 0px rgba(4, 6, 15, 0.05)",
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#333" }}
              >
                {`${item?.firstName} ${item?.lastName}`}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#333" }}
              >
                {item?.addressOne}
              </Typography>
              <Typography
                sx={{ fontWeight: 300, fontSize: "12px", color: "#333" }}
              >
                {item?.phone}
              </Typography>
            </Box>
            <Box>
              {item?.isDefault && (
                <Typography sx={{ color: "#5C3069", fontSize: "12px" }}>
                  (Default)
                </Typography>
              )}

              <IconButton onClick={(e)=>handleClick(e, item)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!data?.fetchAllAddresses ||
          (data?.fetchAllAddresses.length === 0 && (
            <Box
              sx={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={emptyAddress} alt="no_address" />
            </Box>
          ))}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
<EditAddress selectedAddress={selectedAddress} action={refetch}/>
      </Popover>
    </>
  );
};

export default Address;
