import React from "react";
import FooterNavBar from "../Components/Common/FooterNavBar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Typography } from "@mui/material";

const ProductDoc = () => {
  return (
    <>
      <FooterNavBar />
     <Box
        sx={{
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },

          width: "85%",
          margin: "0 auto",
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 700, color: "#333" }}>
     Product Guidelines
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 2, textAlign:'justify' }}
        >
      Mamaket is a vibrant marketplace dedicated to the goods and services of immigrant cultures, celebrating the rich diversity within the United States. To maintain the integrity and quality of our marketplace, we recommend that products and services advertised on our platform meet certain standard. 
          <br /><br/>
          <b>Types of Products</b>
          <br /> <b>Cultural Artifacts and Crafts:</b> Handcrafted items, traditional art, and cultural artifacts that reflect the heritage and craftsmanship of different immigrant communities.
          of unity and understanding.
  <br/>
  <b>Ethnic Clothing and Accessories: </b>Traditional and contemporary clothing, jewelry, and accessories unique to various cultures.
  <br/>
  <b>Culinary Delights: </b>Specialty food items, spices, and beverages that are staples in immigrant cuisines.<br/>
  <b>Books and Media:</b> Literature, music, and films that represent and preserve cultural stories and traditions.<br/>
  <b>Home Décor: </b>Decorative items, textiles, and furnishings that showcase cultural aesthetics and craftsmanship
  <br/>
  <b>Health and Beauty Products:</b>  Natural and traditional beauty products and remedies passed down through generations.
   
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
    Seller Verification:
          </Typography>{" "}
To upload products on Mamaket, you must be a verified seller. Please check our Verification page to understand the process and requirements for profile verification.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
    Product Quality and Condition:
          </Typography>{" "}
All products must be in good condition. Images uploaded should accurately represent the item being sold. For used items, it's recommended to capture all sides and highlight any possible defects to provide buyers with a fair view. Products that do not meet these standards will be returned, incurring a 30% charge of the product price, along with a full refund to the buyer.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
   Compliance and Safety:
          </Typography>
All items must comply with U.S. health and safety laws. Prohibited items include, but are not limited to, guns, cocaine, and marijuana. Ensure that products are safe and legal to sell within the United States.
          <br />
          <br />
          <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#333" }}>
            {" "}
Image Requirements:
          </Typography>
  Sellers can upload multiple images of the same product, with a maximum file size of 4MB per image.
          <br />
          <br />
   
      By adhering to these guidelines, we can ensure that Mamaket remains a trusted and vibrant marketplace that celebrates and supports immigrant cultures and communities.
        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default ProductDoc;
