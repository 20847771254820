import React from "react";
// Mui Component
import { Box, IconButton } from "@mui/material";

// Assets
import whatsapp from "../../assets/Icons/whatsapp 1.svg";
import twitter from "../../assets/Icons/twitter 1 (1).svg";
import facebook from "../../assets/Icons/facebook 1 (1).svg";


const SocialShare = ({mode, _id, name}) => {

  const productLink =
    mode === "product"
      ? `sandbox.mamaket.com/product-view/${_id}`
      : `sandbox.mamaket.com/service-view/${_id}`;

  const shareOnTwitter = () => {
    const tweetText = encodeURIComponent(
      `Check out this awesome product: ${name} ${productLink}`
    );
    const url = `https://twitter.com/intent/tweet?text=${tweetText}`;
    window.open(url, "_blank");
  };

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      productLink
    )}`;
    window.open(url, "_blank");
  };

  const shareOnWhatsApp = () => {
    const message = `Check out this awesome product: ${name} ${productLink}`;
    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;
    window.open(url);

  };


  return (
    <>
      <Box>
        <IconButton onClick={shareOnFacebook}>
          <img src={facebook} alt="facebook_icon" />
        </IconButton>
        <IconButton onClick={shareOnTwitter}>
          <img src={twitter} alt="twitter_icon" />
        </IconButton>
        <IconButton onClick={shareOnWhatsApp}>
          <img src={whatsapp} alt="whatsapp_icon" />
        </IconButton>
      </Box>
    </>
  );
};

export default SocialShare;
