import React from "react";
import FooterNavBar from "../Components/Common/FooterNavBar";
import Newsletter from "../Components/Common/Newsletter";
import Footer from "../Components/Common/Footer";
import { Box, Typography } from "@mui/material";

const Verification = () => {
  return (
    <>
      <FooterNavBar />
      <Box
        sx={{
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 500, color: "#333" }}>
Seller’s Guide and Verification: Generating Sales on Mamaket
        </Typography>
        <Typography
          sx={{ fontSize: {lg:"14px", md:'14px', xs:'12px'}, fontWeight: 400, color: "#333", mt: 2,  textAlign:'justify' }}
        >
  Welcome to Mamaket! We’re excited to have you join our marketplace, dedicated to showcasing products and services that reflect the diverse immigrant cultures in the United States. This guide will help you get started, optimize your listings, and effectively engage with buyers.
  <ol>
    <li><b> Getting Verified</b><br/>
    Before you can start selling on Mamaket, you need to complete the verification process:<br/><br/>
    <b>Residency Proof:</b> You must be a resident or legally staying in the United States, including U.S. citizens and legal immigrants.<br/>
    <b>Documentation:</b> Provide valid documents proving your status, such as a Green Card, visa, or other relevant paperwork.<br/><br/>
    <b>Verification Steps:</b><br/>
    <ol>
      <li><b>Submit Documents:</b> Upload clear copies of your residency or stay documents through the verification portal on Mamaket.</li>
      <li><b>Verification Review:</b>  Our team will review your documents and may request additional information.</li>
      <li><b>  Approval Notification: </b>Once approved, you’ll receive a confirmation email, and you can proceed with listing your products or services.</li>
    </ol>
    </li><br/>
    <li><b>Onboarding Process</b>
    <br/>
    <ol style={{listStyleType:'upper-alpha'}}>
      <li><b>Onboarding Fee:</b><br/>
      Deposit: A $28 non-refundable deposit is required to complete the onboarding process. This payment must be made through your Mamapurse account.<br/>
      <b>Purpose:</b> The deposit covers the initial setup and verification costs.
      </li><br/>
    <li><b>Product Guidelines Review:</b><br/>
    Review Guidelines: Ensure you review the Mamaket Product Guidelines to understand acceptable products and services. Not all products or services may be permitted on the platform.<br/>
    <b>Compliance:</b> Adhere to these guidelines to avoid listing rejections or other issues.
    </li><br/>
    <li><b>Video Guide:</b><br/>
    Orientation:  We provide a comprehensive video guide to help you navigate the Mamaket platform. This guide covers listing creation, order management, and more.
    
    </li>
    </ol>
    </li>
    <br/>
    <li><b>Creating Your Listings</b><br/><br/>
     <ol style={{listStyleType:'upper-alpha'}}>
      <li><b>Product Listings:</b><br/>
      1. <b>Title & Description:</b>  Write a clear and engaging title and description highlighting the unique aspects of your product.<br/>
2. <b>Images:</b> Upload high-quality images that accurately represent your product.<br/>
3. <b>Pricing:</b> Set a competitive price based on your costs and market research.<br/>
4. <b>Categories & Tags:</b> Choose appropriate categories and add relevant tags to help buyers find your products.<br/>

      
      </li><br/>
      <li><b>Service Listings:</b><br></br>
      1. <b>Title & Description:</b> Describe your service, emphasizing its relevance to immigrant communities.<br/>
2. <b>Booking Details: </b>Provide information on how customers can book or request your service.<br/>
3. <b>Pricing:</b> Set a fair price and consider offering package deals or discounts.<br/>
4. <b>Customer Reviews:</b> Encourage positive reviews to build credibility.

      </li>
      
      </ol>
    </li><br/>
    <li><b>Optimizing Your Listings</b><br/>
    <ol>
      <li>Keywords: Use relevant keywords in titles and descriptions to improve search visibility.

</li>
<li> Promotions: Utilize Mamaket’s promotional tools to highlight your listings.
</li>
<li>SEO Best Practices: Optimize descriptions and use keywords to enhance searchability.</li>
    </ol>
    </li><br/>
    <li><b>Managing Orders and Customer Service</b><br/>
    <ol>
      <li>Order Fulfillment:  Process orders promptly and keep buyers informed.

</li>
<li> Customer Communication: Respond to inquiries and messages quickly and professionally.
</li>
<li>Reviews & Feedback:  Encourage feedback to build trust and improve your offerings.</li>
    </ol>
    </li><br/>
    <li><b>Using the Chat System</b><br/>
    <ol>
      <li>Communication: Mamaket includes a chat system that allows buyers to communicate directly with sellers.
</li>
<li> Engagement: Engage with buyers responsibly and professionally. Do not exchange personal information via chat.
</li>
<li>Transactions: Complete all transactions and communications through Mamaket’s platform. We are not responsible for transactions or communications made outside the platform.</li>
<li>Negotiations: Use the chat system to approve negotiable offers, confirm service availability, and address other inquiries. </li>
    </ol>
    </li><br/>
    <li><b>Marketing and Growing Your Business</b><br/>
    <ol>
      <li>Social Media: Promote your Mamaket store on social media to reach a wider audience.
</li>
<li> Community Engagement: Connect with immigrant communities through events, festivals, and online forums.
</li>
<li>Collaborations: Partner with other sellers or influencers to cross-promote your products or services.</li>

    </ol>
    </li><br/>
    <li><b>Compliance and Best Practices</b><br/>
    <ol>
      <li>Legal Requirements: Ensure your products and services comply with U.S. regulations and Mamaket’s policies.
</li>
<li> Cultural Sensitivity: Be mindful of cultural sensitivities in your offerings and marketing.
</li>
    </ol>
    </li><br/>
    <li><b>Troubleshooting and Support</b><br/>
    <ol>
      <li>Help Center:  Access Mamaket’s Help Center for answers to common questions.
</li>
<li>Customer Support: Contact our support team for assistance with your account.
</li>
    </ol>
    </li>
  </ol>

  <br/>
  By following these guidelines and utilizing the resources provided, you’ll be well-equipped to succeed on Mamaket. We look forward to supporting you in celebrating and promoting diverse immigrant cultures through our platform.<br/><br/>
  Good luck with your selling journey!

<br/>


        </Typography>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Verification;
