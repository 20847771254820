import { io } from "socket.io-client";

// Ensure the URL is set in the environment
const URL =process.env.REACT_APP_SOCKET_URL

// Socket connection function
export const initializeSocket = () => {
  // Fetch user token securely inside a function
  const tokenPin = localStorage.getItem("mamaketUserToken");

  // Socket options
  const socketOptions = {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${tokenPin}`, // Token from local storage
        },
      },
    },
    reconnection: true, // Allow reconnection for a more resilient experience
  };

  // Initialize the socket
  const socket = io(URL, socketOptions);

  // Handle successful connection
  socket.on("connect", () => {

  });

  // Handle exceptions, specifically for invalid token
  socket.on("exception", (err) => {
    if (err.message === "Invalid Bearer token.") {
      alert("Session expired. Please log in again."); // Better UX than a silent reload
      window.location.reload(); // Refresh the page to force re-authentication
    }
  });

  // Handle connection errors
  socket.on("connect_error", (error) => {

  });

  return socket;
};

// Initialize and export the socket
const socket = initializeSocket();
export default socket;
